import { FC, ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  emplFormDataType,
  formDataKeyType,
  formDataType,
} from "../../../modules/Auth/types";
import { FieldErrors, UseFormSetValue } from "react-hook-form";
import EyeIcon from "../../constant/icons/EyeIcon";
import EyeSlashIcon from "../../constant/icons/EyeSlashIcon";
import CancelIcon from "components/constant/icons/CancelIcon";

interface TextFieldProps {
  type?: string;
  value: any;
  onChangeValue?: (value: string) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (key: string) => void;
  placeholder?: string;
  imgLeft?: ReactNode | null;
  imgRight?: ReactNode | null;
  className?: string;
  label?: string | React.ReactNode;
  id?: string;
  maxLength?: number;
  minLength?: number;
  disabled?: boolean;
  required?: boolean;
  setFocused?: (is: boolean) => void;
  buttomLabel?: string;
  countryCode?: string;
  name?: string;
  errors?: FieldErrors<emplFormDataType>;
  classNameInput?: string;
  setValues?: UseFormSetValue<emplFormDataType | formDataKeyType>;
}

const TextField: FC<TextFieldProps> = ({
  type = "text",
  value,
  onChangeValue,
  placeholder = "",
  imgLeft,
  imgRight,
  className = "",
  label,
  id = `text-field-${Math.random()}`,
  maxLength,
  minLength,
  disabled,
  buttomLabel,
  onKeyDown,
  onChange,
  setFocused,
  countryCode,
  name,
  errors,
  setValues,
  classNameInput = "font-normal",
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [valueLocal, setValue] = useState<string>(value);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onChangeLocalValue = (value: string) => {
    setValue(value);

    if (onChangeValue) onChangeValue(value);
  };

  useEffect(() => {
    if (value !== valueLocal) setValue(value);
  }, [value]);

  useEffect(() => {
    setFocused && setFocused(isFocused);
  }, [isFocused]);

  function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (onKeyDown) onKeyDown(e.key);
  }

  return (
    <div className={`form-group ${className}`}>
      <div className="form-block flex flex-col gap-[6px]">
        {label && (
          <label
            htmlFor={id}
            className={`form-label text-[14px]  font-medium leading-[16.41px] text-accent-default ${
              isFocused || String(valueLocal).length ? " focus" : ""
            }
                           ${imgLeft ? " left" : ""}${
              imgRight ? " right" : ""
            }`}
          >
            {label}
          </label>
        )}
        <div className="relative">
          {imgLeft && (
            <div
              className={`form-icon-left absolute top-0 bottom-0 flex items-center justify-center left-[16px] ${
                countryCode && "flex gap-[10px] items-center  justify-center"
              }`}
            >
              {imgLeft}
              {countryCode && (
                <span className="text-[14px] mt-[1px] leading-[16.41px] font-medium ">
                  {countryCode}
                </span>
              )}
            </div>
          )}
          <input
            id={id}
            type={type === "password" && showPassword ? "text" : type}
            placeholder={placeholder}
            className={`form-control px-[16px] py-[12px] placeholder:text-accent-disabled 
                    w-full border-[1px] focus:border-boder-focus border-boder-default outline-none placeholder:font-normal text-[14px] rounded-[4px] ${
                      label ? " label" : ""
                    }${imgLeft ? " left pl-[50px]" : ""}${
              imgRight ? " right pr-[55px]" : ""
            } ${countryCode ? "pl-[71px]" : ""} ${classNameInput}`}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            maxLength={maxLength}
            name={name}
            minLength={minLength}
            autoComplete="off"
            disabled={disabled}
            value={value || ""}
            onChange={(e) => {
              onChangeLocalValue(e.target.value);
              onChange?.(e);
            }}
            onKeyDown={handleKeyPress}
            onWheel={(e) => (e.target as any).blur()}
          />
          {imgRight && (
            <div className="form-icon-right absolute flex items-center justify-center cursor-pointer right-[18px] top-0 h-full  bottom-0 ">
              {type === "password" ? (
                <div className="flex gap-3 items-center">
                  {errors?.password?.message && value.length ? (
                    <button
                      onClick={() => setValues?.("password", "")}
                      className="outline-none"
                    >
                      <CancelIcon color="#fff" />
                    </button>
                  ) : null}
                  <div onClick={togglePasswordVisibility}>
                    {showPassword ? (
                      <EyeSlashIcon
                        color={errors?.password?.message && "#FFFFFF"}
                      />
                    ) : (
                      <EyeIcon color={errors?.password?.message && "#FFFFFF"} />
                    )}
                  </div>
                </div>
              ) : (
                imgRight
              )}
            </div>
          )}
        </div>
        {(errors?.[name as keyof emplFormDataType] || buttomLabel) && (
          <div className="text-[12px] leading-[14.06px] flex flex-col">
            {errors?.[name as keyof emplFormDataType] && (
              <p className="text-red-default mb-[5px]">
                {errors?.[name as keyof emplFormDataType]?.message}
              </p>
            )}

            {buttomLabel && (
              <p
                className={`${
                  errors?.[name as keyof formDataType] ? "" : "ml-auto"
                } cursor-pointer text-blue-default hover:text-blue-focus`}
              >
                <Link to="/forgot-password" className="ml-auto">
                  {buttomLabel}
                </Link>
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextField;
