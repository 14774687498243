import React from "react";
import EyeIcon from "../constant/icons/EyeIcon";
import LikeIcon from "../constant/icons/LikeIcon";

interface CardProps {
  imageSrc: string;
  title: string;
  hashtag?: string[];
  likes?: number;
  watched?: number;
  date?: string;
  onClick?: () => void;
}

const HomePageCard = ({
  imageSrc,
  title,
  hashtag,
  likes,
  watched,
  date,
  onClick,
}: CardProps) => {
  return (
    <div
      className=" flex-1 bg-white-default rounded-lg overflow-hidden"
      onClick={onClick}
    >
      <div className="w-full h-[157px]">
        <img
          src={imageSrc}
          alt={title}
          crossOrigin="anonymous"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="h-[140px] flex flex-col justify-between p-5">
        <div>
					{hashtag && (
						<div className="flex gap-[10px] text-[12px] leading-[16px] font-medium opacity-50">
							{hashtag.map((item, i) => (
								<div
									key={i}
									className=""
								>
									{item}
								</div>
							))}
						</div>
					)}

          <h3 className="text-[16px] leading-[18.75px] text-accent-default my-[5px] font-medium">
            {title}
          </h3>

        </div>
        <div className="flex items-center justify-between text-[12px] text-accent-disabled font-medium leading-[16px]">
					{date && <span className="border rounded-[27px] px-[14px] py-2">{date}</span>}

          <div className={"flex gap-[15px]"}>
						{(watched || watched === 0) && (
							<div className="flex items-center justify-center gap-[8px] cursor-pointer">
								<EyeIcon color="#8E95A1" width="18" />
								<span>{watched}</span>
							</div>
						)}
						{(likes || likes === 0) && (
							<div className="flex items-center justify-center gap-[8px] cursor-pointer">
								<LikeIcon />
								<span>{likes}</span>
							</div>
						)}
					</div>
        </div>
      </div>
    </div>
  );
};

export default HomePageCard;
