import React, { FC, ReactElement, useEffect, useState } from "react";
import TextField from "../TextField/TextField";
import ButtonForm from "../../Button/ButtonForm";
import Close from "../../constant/icons/Close";
import SearchIcon from "../../constant/icons/SearchIcon";
import { withDebounce } from "../../../hooks/useDebounce";
import { useSearchClientsListMutation } from "../../../data/api/base-clients";
import { ClientSearch } from "../../../data/api/base-clients/type";
import { formatPhoneNumber } from "hooks/useFormatInput";
import { ResponseStatusType } from "data/api/exception";
import { images } from "utils/resource";

interface Search {
  selectItem?: UserData | null;
  onChange: (item: any) => void;
  isShowBtnDelete?: boolean;
  className?: string;
  label?: string;
  placeholder?: string;
  btnListItem?: ReactElement;
  id?: string;
}

export interface UserData {
  id: number;
  name: string;
  image?: string;
  phone: string;
}

export const SearchField: FC<Search> = ({
  selectItem,
  onChange,
  isShowBtnDelete = false,
  className = "",
  label = "",
  placeholder = "ФИО или номер",
  btnListItem,
  id,
}) => {
  const [mutate] = useSearchClientsListMutation();

  const [isloading, setIsLoading] = useState(false);

  const [userValue, setUserValue] = useState("");

  const [listUsers, setListUsers] = useState<UserData[]>([]);
  
  const [selectItemLocal, setSelectItemLocal] = useState<UserData | null>(
    selectItem || null
  );

  const [isFocused, setIsFocused] = useState(false);

  const onChangeSearchList = (item: ClientSearch) => {
    return {
      id: item.id,
      name: item.fullname,
      phone: item.phone_number,
    };
  };

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const classEvent = (event.target as any).name;

      setIsFocused([`search-${id}`].includes(classEvent));
    };

    document.addEventListener("click", handleDocumentClick);

    return () => document.removeEventListener("click", handleDocumentClick);
  }, []);

  useEffect(() => {
    if (selectItemLocal?.name || selectItem?.name) {
      setUserValue(selectItemLocal?.name || selectItem?.name || "");
    }
  }, [selectItem]);

  useEffect(() => {
    if (!isFocused && !selectItemLocal) handleChange("");
  }, [isFocused]);

  async function onChangeValueName(value: string) {
    const query = `text=${value}`;
    setIsLoading(true);
    if (value) {
      const res = await mutate(query);

      if (res.data?.meta?.type === ResponseStatusType.SUCCESS) {
        setIsLoading(false);
      }
      if (res.error) {
        setIsLoading(false);
      }

      if (!res || !res.data) return;

      const updateList = res.data.data.map((item) => onChangeSearchList(item));

      setListUsers(updateList);
    }
  }

  function onChangeSelectItem(item: UserData) {
    onChange(item);

    setUserValue(item.name);
    setSelectItemLocal(item);

    setListUsers([]);
  }

  function clearItem() {
    setSelectItemLocal(null);
    setUserValue("");
    setListUsers([]);
  }

  function deleteItem() {
    clearItem();
    onChange(null);
  }
  const handleChange = (value: string) => {
    const filteredValue = value.replace(/[^a-zA-Z-а-я-А-Я-0-9\s]/g, "");
    setUserValue(filteredValue);
    onChange("");
    withDebounce(() => onChangeValueName(filteredValue));
  };

  const openMenu = Boolean(userValue.length && isFocused);

  return (
    <div className={"relative"}>
      <div className="relative md:pb-0">
        <TextField
          name={`search-${id}`}
          label={label}
          value={userValue}
          onChangeValue={handleChange}
          placeholder={isShowBtnDelete ? "ФИО или номер" : placeholder}
          imgLeft={<SearchIcon />}
          // onKeyDown={() => onChangeValueName(userValue)}
          className={`${className}`}
          classNameInput={` rounded-[30px!important] ${className}`}
        />

        {isShowBtnDelete && userValue.length ? (
          <div
            className={
              "search__block__clear absolute top-[50%] right-1 -translate-y-[50%]"
            }
          >
            <ButtonForm
              text={<Close color={"#FF0000"} opacity={"1"} />}
              onClick={deleteItem}
              className={"btn btn-icon bg-white-default"}
            />
          </div>
        ) : null}
      </div>

      {openMenu && (
        <div
          className={`md:absolute z-50 top-[calc(100%${
            label && "+22px"
          })] md:max-h-[300px] pb-0 pt-5 md:pt-0 overflow-auto left-0 w-full z-10 rounded-bl-lg rounded-br-lg`}
        >
          <div className="p-0 md:border border-t-0 border-solid border-bg-default bg-white-default">
            {!isloading ? (
              <>
                {btnListItem && btnListItem}

                {listUsers.length === 0 && (
                  <p className="text-center py-8">Пусто</p>
                )}
                {listUsers.map((item) => (
                  <div
                    className="px-[15px] py-[8px] cursor-pointer hover:bg-accent-default hover:text-white-default md:border-0 border-t-[1px] border-bg-default"
                    key={"search__block-item" + item.id}
                    onClick={() => onChangeSelectItem(item)}
                  >
                    <p className={"text-sm font-medium mb-[5px]"}>
                      {item.name}
                    </p>
                    <p className={"text-sm font-normal"}>
                      +7 {formatPhoneNumber(item.phone)}
                    </p>
                  </div>
                ))}
              </>
            ) : (
              <div className="flex py-4 justify-center">
                <img
                  src={images.loaderImg}
                  alt="loading img"
                  className="animate-spin-slow w-8"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
