import React from "react";
import { useNavigate } from "react-router";

import NewsPageCard from "components/Cards/NewsPageCard";
import { Event } from "data/api/event/type";
import moment from "moment";
import NotFound from "components/NotFound";

interface Props {
  data: Event[];
}

const EventsList = ({ data }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      {data?.length ? (
        <div
          className={
            "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-[15px] md:mt-[20px] mt-[15px] xl:mt-[30px]"
          }
        >
          {data.map((item) => (
            <NewsPageCard
              key={`news-block-item-${item.id}`}
              imageSrc={
                process.env.REACT_APP_FILES_URL + "/" + item?.photo || ""
              }
              title={item.title}
              watched={item.views_count}
              likes={item.likes_count}
              date={`${
                item.date ? moment(item.date).format("DD.MM.YYYY") : ""
              } ${item.start_time || ""}`}
              onClick={() => navigate(`view/${item.id}`)}
            />
          ))}
        </div>
      ) : (
        <NotFound title=" Мероприятия не найдены" />
      )}
    </>
  );
};

export default EventsList;
