import { useEffect, useState } from "react";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import PencilIcon from "../../components/constant/icons/PencilIcon";
import { useNavigate } from "react-router-dom";
import NewsBanner from "../../components/Carousel/NewsBanner";
import NewsPageCard from "../../components/Cards/NewsPageCard";
import {
  useGetNewsBannerQuery,
  useGetNewsCategoriesQuery,
  useGetNewsQuery,
} from "data/api/news";
import moment from "moment";
import { NewsCategories } from "data/api/news/type";
import { useGetCitiesQuery } from "data/api/city";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import { objectToQueryString } from "utils/objectToQuery";
import Loader from "components/Loader";
import { useRoles } from "hooks/useRoles";
import NotFound from "components/NotFound";
import { useSelector } from "react-redux";
import { RootState } from "store";

export interface NewsItemData {
  id: number;
  key: string;
  img?: string;
  images?: string[];
  title: string;
  text?: string;
  tags: string[];
  views: number;
  likes: number;
  date: string;
  city?: string;
  is_banner?: boolean;
  is_draft?: boolean;
  is_show?: boolean;
}

export interface cityItem {
  value: string;
  label: string;
}

const NewsPage = () => {
  const navigate = useNavigate();

  const selection = useSelector((state: RootState) => state);

  const [selectCity, setSelectCity] = useState<cityItem>();

  const [activeCategory, setActiveCategory] = useState(0);

  const roles = useRoles(null);

  const [query, setQuery] = useState<string>();

  const { data: news, isLoading } = useGetNewsQuery(query);

  const { data: newsCategories } = useGetNewsCategoriesQuery();

  const { data: bannerData } = useGetNewsBannerQuery(
    objectToQueryString({ city_id: selectCity?.value })
  );

  const { data: cities } = useGetCitiesQuery();

  const cityOptions = useFormatForDropDownOptions(cities?.data ?? []);

  useEffect(() => {
    const query = objectToQueryString({
      city_id: selectCity?.value,
      category_id: activeCategory,
    });
    setQuery(query);
  }, [activeCategory, selectCity, selection.header.city_id]);

  useEffect(() => {
    const city = cityOptions.find(
      (item) => item.value === selection.header.city_id
    );
    setSelectCity(city as cityItem);
  }, [selection.header.city_id]);

  function handleCategory(item: NewsCategories) {
    setActiveCategory(item?.id);
  }

  return (
    <>
      <div className={"bg-bg-default min-h-[50vh]"}>
        <div className="br-container py-[20px]">
          <h1
            className={
              "hidden md:flex text-[25px] font-dewi font-extrabold mb-[15px]"
            }
          >
            Новости
          </h1>

          <NewsBanner list={bannerData?.data || []} />

          <div className={"mt-[30px]"}>
            <div className={"flex justify-between items-center gap-[50px]"}>
              <div className={"hidden lg:flex"}>
                <Dropdown
                  placeholder={"Город"}
                  value={selectCity}
                  options={[{ value: "", label: "Все" }, ...cityOptions]}
                  className={" select-bg-transparent min-w-[100px]"}
                  onChange={setSelectCity}
                />
              </div>

              <div
                className={
                  "flex items-center gap-[10px] flex-nowrap whitespace-nowrap overflow-auto toolbar-scroll pb-3"
                }
              >
                {[{ name: "All", id: 0 }, ...(newsCategories?.data || [])]?.map(
                  (item, idx) => (
                    <div
                      key={`category-item-${idx}`}
                      onClick={() => handleCategory(item)}
                      className={`
										md:py-[11px] py-[7px] 
										md:px-[24px] px-[15px] 
										rounded-full text-sm font-normal 
										md:leading-[18px] leading-[16px] 
										cursor-pointer
										border md:border-white-default border-[#D7DCE4]
										${
                      activeCategory === item.id
                        ? "bg-accent-default text-white-default"
                        : "bg-white-default"
                    }
										`}
                    >
                      {item?.name}
                    </div>
                  )
                )}
              </div>
            </div>

            {isLoading && <Loader />}
            {news?.data?.length ? (
              <div
                className={
                  "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[15px] md:mt-[20px] mt-[15px] xl:mt-[30px]"
                }
              >
                {news.data.map((item) => (
                  <NewsPageCard
                    category="Категория"
                    key={`news-block-item-${item.id}`}
                    imageSrc={
                      process.env.REACT_APP_FILES_URL +
                        "/" +
                        item?.cover_image || ""
                    }
                    isLikeEnabled={item?.is_like_enabled}
                    title={item?.title || ""}
                    watched={item?.views_count}
                    likes={item?.likes_count}
                    date={`${
                      item?.published_at
                        ? moment(item.published_at).format("DD.MM.YYYY")
                        : ""
                    }`}
                    onClick={() => navigate(`view/${item.id}`)}
                  />
                ))}
              </div>
            ) : (
              !isLoading && <NotFound title="Новостей не найдено" />
            )}
          </div>
          {(roles.isAdmin || roles.isAffiliate_manager) && (
            <div className="hidden lg:fixed right-[30px]  bottom-[30px] lg:flex items-center justify-center">
              <div
                className={
                  "rounded-full bg-accent-default flex items-center shadow-md border border-bg-default justify-center p-3 cursor-pointer"
                }
                onClick={() => navigate("edit")}
              >
                <PencilIcon color={"#FFFFFF"} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NewsPage;
