import { apiService } from "..";
import { MetaData } from "../exception";
import {
  MainAnalyticsInfoType,
  MetricTypeMainInfo,
  PersonalAnalyticsInfo,
  StatusLeads,
  TopNews,
} from "./type";

export const addTagTypes = ["main", "leads_count"] as const;

export const analyticsApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getMainAnalyticsInfo: build.query<
        MetaData<MainAnalyticsInfoType | PersonalAnalyticsInfo>,
        void | string
      >({
        query: (query) => ({
          path: `/analytics/main?${query}`,
        }),
        providesTags: ["main"],
      }),
      getLeadsCountAnalytics: build.query<
        MetaData<MetricTypeMainInfo>,
        void | string
      >({
        query: (query) => ({
          path: `/analytics/leads/count?${query}`,
        }),
        providesTags: ["leads_count"],
      }),
      getLeadsByStatusAnalytics: build.query<
        MetaData<StatusLeads[]>,
        void | string
      >({
        query: (query) => ({
          path: `/analytics/leads/by-status?${query}`,
        }),
        providesTags: ["leads_count"],
      }),
      getTopNewsAnalytics: build.mutation<MetaData<TopNews[]>, void | string>({
        query: (query) => ({
          path: `/analytics/top-news?${query}`,
        }),
      }),
      getTopEventsAnalytics: build.mutation<MetaData<TopNews[]>, void | string>(
        {
          query: (query) => ({
            path: `/analytics/top-news?${query}`,
          }),
        }
      ),
      getTopTrainingsAnalytics: build.mutation<
        MetaData<TopNews[]>,
        void | string
      >({
        query: (query) => ({
          path: `/analytics/top-news?${query}`,
        }),
      }),
      getTopManagersAnalytics: build.mutation<
        MetaData<TopNews[]>,
        void | string
      >({
        query: (query) => ({
          path: `/analytics/manager/count?${query}`,
        }),
      }),
      getDealsPriceAnalytics: build.mutation<
        MetaData<TopNews[]>,
        void | string
      >({
        query: (query) => ({
          path: `/analytics/manager/price?${query}`,
        }),
      }),
      getDealsExpireTimeAnalytics: build.mutation<
        MetaData<TopNews[]>,
        void | string
      >({
        query: (query) => ({
          path: `/analytics/manager/time?${query}`,
        }),
      }),
    }),
  });
export const {
  useGetMainAnalyticsInfoQuery,
  useGetLeadsCountAnalyticsQuery,
  useGetLeadsByStatusAnalyticsQuery,
  useGetTopNewsAnalyticsMutation,
  useGetTopEventsAnalyticsMutation,
  useGetTopTrainingsAnalyticsMutation,
  useGetTopManagersAnalyticsMutation,
  useGetDealsPriceAnalyticsMutation,
  useGetDealsExpireTimeAnalyticsMutation,
} = analyticsApiHooks;
