import React, { useEffect, useState } from "react";
import { useRoles } from "hooks/useRoles";
import EventsAnaliticsCart from "./Card/EventsAnaliticsCart";
import { TopNews } from "data/api/analytics/type";
import {
  useGetDealsExpireTimeAnalyticsMutation,
  useGetDealsPriceAnalyticsMutation,
  useGetTopEventsAnalyticsMutation,
  useGetTopManagersAnalyticsMutation,
  useGetTopNewsAnalyticsMutation,
  useGetTopTrainingsAnalyticsMutation,
} from "data/api/analytics";
import { objectToQueryString } from "utils/objectToQuery";
import { IFiltersAnalytics } from ".";

interface IProps {
  filter: IFiltersAnalytics;
}

const NewsEventsTrainingComponent = ({ filter }: IProps) => {
  const roles = useRoles(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [topNews, setTopNews] = useState<TopNews[] | null>(null);

  const [topEvents, setTopEvents] = useState<TopNews[] | null>(null);

  const [topTraining, setTopTraining] = useState<TopNews[] | null>(null);

  const [topManangers, setTopManagers] = useState<TopNews[] | null>(null);

  const [dealsPrices, setDealsPrice] = useState<TopNews[] | null>(null);

  const [dealsTimeExpire, setDealsTimeExpire] = useState<TopNews[] | null>(
    null
  );

  const [pagination, setPagination] = useState({
    news: {
      page: 1,
      limit: 5,
    },
    trains: {
      page: 1,
      limit: 5,
    },
    events: {
      page: 1,
      limit: 5,
    },
  });

  const [getTopNewsMutation] = useGetTopNewsAnalyticsMutation();

  const [getTopEventsMutation] = useGetTopEventsAnalyticsMutation();

  const [getTopTrainingMutation] = useGetTopTrainingsAnalyticsMutation();

  const [getTopManagerMutation] = useGetTopManagersAnalyticsMutation();

  const [getDealsPriceMutation] = useGetDealsPriceAnalyticsMutation();

  const [getDealsTimeExpireMutation] = useGetDealsExpireTimeAnalyticsMutation();

  async function fetchTopNews() {
    setIsLoading(true);
    try {
      const { data, error } = await getTopNewsMutation(
        objectToQueryString({ ...pagination.news, ...filter })
      );
      if (error) {
        console.error("API Error (News):", error);
      } else if (data) {
        setTopNews(data.data);
        // setTotalPages(data.totalPages);
      }
    } catch (err) {
      console.error("Unexpected Error (News):", err);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchTopEvents() {
    setIsLoading(true);
    try {
      const { data, error } = await getTopEventsMutation(
        objectToQueryString({ ...pagination.events, ...filter })
      );
      if (error) {
        console.error("API Error (Events):", error);
      } else if (data) {
        setTopEvents(data.data);
        // setTotalPages(data.totalPages);
      }
    } catch (err) {
      console.error("Unexpected Error (Events):", err);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchTopTraining() {
    setIsLoading(true);
    try {
      const { data, error } = await getTopTrainingMutation(
        objectToQueryString({ ...pagination.trains, ...filter })
      );
      if (error) {
        console.error("API Error (Training):", error);
      } else if (data) {
        setTopTraining(data.data);
        // setTotalPages(data.totalPages);
      }
    } catch (err) {
      console.error("Unexpected Error (Training):", err);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchTopManagers() {
    setIsLoading(true);
    try {
      const { data, error } = await getTopManagerMutation(
        objectToQueryString({ ...pagination.news, ...filter })
      );
      if (error) {
        console.error("API Error (Managers):", error);
      } else if (data) {
        setTopManagers(data.data);
        // setTotalPages(data.totalPages);
      }
    } catch (err) {
      console.error("Unexpected Error (Managers):", err);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchDealsPrice() {
    setIsLoading(true);
    try {
      const { data, error } = await getDealsPriceMutation(
        objectToQueryString({ ...pagination.events, ...filter })
      );
      if (error) {
        console.error("API Error (Deals Price):", error);
      } else if (data) {
        setDealsPrice(data.data);
        // setTotalPages(data.totalPages);
      }
    } catch (err) {
      console.error("Unexpected Error (Deals Price):", err);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchDealsExpireTime() {
    setIsLoading(true);
    try {
      const { data, error } = await getDealsTimeExpireMutation(
        objectToQueryString({ ...pagination.trains, ...filter })
      );
      if (error) {
        console.error("API Error (Deals Expire):", error);
      } else if (data) {
        setDealsTimeExpire(data.data);
        // setTotalPages(data.totalPages);
      }
    } catch (err) {
      console.error("Unexpected Error (Deals Expire):", err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (roles.isAdmin) {
      fetchTopNews();
      fetchTopEvents();
      fetchTopTraining();
    } else {
      fetchTopManagers();
      fetchDealsPrice();
      fetchDealsExpireTime();
    }
  }, [filter]);

  return (
    <>
      {roles.isAdmin ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[10px] order-4">
          <EventsAnaliticsCart title="Топ новостей" data={topNews} />
          <EventsAnaliticsCart title="Топ обучения" data={topTraining} />
          <EventsAnaliticsCart title="Топ мероприятий" data={topEvents} />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[10px] order-4">
          <EventsAnaliticsCart title="Топ менеджеров" data={topManangers} />
          <EventsAnaliticsCart title="Сумма сделок" data={dealsPrices} />
          <EventsAnaliticsCart title="Скорость сделок" data={dealsTimeExpire} />
        </div>
      )}
    </>
  );
};

export default NewsEventsTrainingComponent;
