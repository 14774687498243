import React, { useEffect, useMemo, useState } from "react";
import EventsList from "./EventsList";
import { useNavigate } from "react-router";
import PencilIcon from "components/constant/icons/PencilIcon";
import CalendarList from "./EventCalendarList";
import { useGetEventsBannerQuery, useGetEventsQuery } from "data/api/event";
import Loader from "components/Loader";
import NewsBanner from "components/Carousel/NewsBanner";
import { useRoles } from "hooks/useRoles";
import { EventsCategory } from "enums/Events";
import EventsCategories from "./EventsCategory";
import { useWindowSize } from "hooks/useWindowSize";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "store";

const Events = () => {
  const [category, setCategory] = useState<EventsCategory>(EventsCategory.ALL);

  const [fullDate, setFullDate] = useState<string | null>(
    moment().format("YYYY-MM-DD")
  );
  const selection = useSelector((state: RootState) => state.header);

  const navigate = useNavigate();

  const size = useWindowSize();

  const roles = useRoles(null);

  const { data, isLoading } = useGetEventsQuery(
    category === EventsCategory.ALL
      ? `city_id=${selection.city_id}&date=${fullDate}`
      : `city_id=${selection.city_id}&type=${category}&date=${fullDate}`
  );

  const { data: bannerData } = useGetEventsBannerQuery(
    `city_id=${selection.city_id}`
  );

  function init() {
    const date = moment().format("YYYY-MM-DD");
    setCategory(EventsCategory.ALL);
    setFullDate(date);
  }

  useEffect(() => init(), []);

  return (
    <section className={"bg-bg-default relative min-h-[50vh]"}>
      {isLoading && <Loader />}
      <div className="br-container py-[20px]">
        <div className="flex items-center justify-between pb-3">
          <h1
            className={
              "text-[25px] font-dewi hidden sm:block font-extrabold mb-[15px] text-[#3B4658]"
            }
          >
            Мероприятия
          </h1>
          {size.isLg && (
            <EventsCategories setCategory={setCategory} category={category} />
          )}
        </div>

        {bannerData?.data.length ? (
          <NewsBanner list={bannerData?.data} />
        ) : null}
        {!isLoading && (
          <CalendarList fullDate={fullDate} setFullDate={setFullDate} />
        )}
        {!size.isLg && (
          <div className="flex flex-col gap-4 pt-5">
            <EventsCategories setCategory={setCategory} category={category} />
            <h1
              className={"text-[18px] sm:text-[25px] font-dewi font-extrabold"}
            >
              События Брусники
            </h1>
          </div>
        )}
        {data?.data ? <EventsList data={data.data} /> : null}
      </div>
      {(roles.isAdmin || roles.isAffiliate_manager) && (
        <div className=" hidden lg:fixed right-[30px]  bottom-[30px] lg:flex items-center justify-center">
          <div
            className={
              "rounded-full bg-accent-default flex items-center shadow-md border border-bg-default justify-center p-3 cursor-pointer"
            }
            onClick={() => navigate("edit")}
          >
            <PencilIcon color={"#FFFFFF"} />
          </div>
        </div>
      )}
    </section>
  );
};

export default Events;
