import React, { useMemo, useState } from "react";

import { MyApplication, LeadOp } from "./type";
import { useWindowSize } from "hooks/useWindowSize";
import { formatPhoneNumber } from "hooks/useFormatInput";
import { Primise } from "enums/primisesType";
import { DealStatus, LeadOpStatus } from "enums/deals";

interface DealCardProps {
  data: MyApplication;
}
const stepColors: Record<LeadOpStatus, string> = {
  [LeadOpStatus.WIN]: "bg-[#4BCC36]",
  [LeadOpStatus.LOST]: "bg-custom-green-red",
  [LeadOpStatus.PAUSE]: "bg-custom-green-yellow",
  [LeadOpStatus.ACTIVE]: "bg-[#4BCC36]",
  [LeadOpStatus.OTHER]: "bg-grey-tertiary opacity-30",
};

const statusColorForCircle: Record<LeadOpStatus, string> = {
  [LeadOpStatus.ACTIVE]: "bg-[#4BCC36]",
  [LeadOpStatus.LOST]: "bg-[#EF3B24]",
  [LeadOpStatus.PAUSE]: "bg-[#F9D448]",
  [LeadOpStatus.WIN]: "bg-[#4BCC36]",
  [LeadOpStatus.OTHER]: "bg-grey-tertiary opacity-30",
};

export const primisesType: Record<Primise, string> = {
  [Primise.APARTMENT]: "Квартира",
  [Primise.PARKING]: "Стоянка",
  [Primise.STOREROOM]: "Кладовая",
  [Primise.COMMERCIAL]: "Commercial",
};

const MyApplicationCard: React.FC<DealCardProps> = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const size = useWindowSize();

  const modifiedData = useMemo(() => {
    const leadOps = [...data?.lead_ops];
    return data.state === DealStatus.ACTIVE
      ? new Array<LeadOp>(7)
          .fill({ status: LeadOpStatus.OTHER, id: 0 })
          .map((e, i) => {
            e = leadOps[i] || { ...e, id: i + "idx" };
            return e;
          })
      : leadOps;
  }, [data?.lead_ops]);

  const dealTrackWidth = useMemo(() => {
    const width =
      modifiedData.length <= 7 ? 100 / 7 : 100 / modifiedData.length;
    return { width: `${width}%` };
  }, [modifiedData]);

  function toUpperCasefunc(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
      <div
        className={`cursor-pointer  ${
          data?.state === DealStatus.LOST
            ? "bg-[#FFECEA]"
            : data?.state === DealStatus.WIN
            ? "bg-light-green-10"
            : "bg-white-default"
        }  ${
          size.isMd ? "p-5 rounded-[20px]" : " rounded-lg py-[15px] "
        }  lg:w-full`}
        onClick={() => setIsModalOpen(true)}
      >
        {size.isMd ? (
          <MyApplicationCardMobile data={data} />
        ) : (
          <>
						<div className="flex gap-[30px] justify-between pl-[30px] pr-[100px] xl:pr-[150px] ">
							<div className="mb-2 text-[14px] leading-[24px] font-semibold text-accent-default ">
								Помещение
								<span className="block text-[12px] font-medium">
                  {primisesType[data?.premise.type as Primise]}
                </span>
							</div>
							<div className="mb-2 text-[14px] leading-[24px] font-semibold text-accent-default">
								Адрес
								<span className="block text-[12px] font-medium">
                  Г. Москва, Химки, д...
                </span>
							</div>
							<div className="mb-2 text-[14px] leading-[24px] font-semibold text-accent-default">
								ФИО
								<span className="block text-[12px] font-medium">
                  {data?.client.fullname}
                </span>
							</div>
							<div className="mb-2 text-[14px] leading-[24px] font-semibold text-accent-default">
								Номер
								<span className="block text-[12px] font-medium">
                  +7 {formatPhoneNumber(data?.client?.phone_number.slice(2))}
                </span>
							</div>
							<div className="mb-2 text-[14px] leading-[24px] font-semibold text-accent-default">
								Номер заявки
								<span className="block text-[12px] font-medium">
                  {data?.lead_number}
                </span>
							</div>
							<div className="mb-2 text-[14px] leading-[24px] font-semibold text-accent-default">
								Этап сделки
								<span className="block text-[12px] font-medium">
                  {toUpperCasefunc(data?.current_status)}
                </span>
							</div>
							<div className=" mb-2 text-[14px] leading-[24px] font-semibold text-accent-default">
								NPS
								<span className="block text-[12px] font-medium">
                  {data?.state}
                </span>
							</div>
						</div>
						<hr className=" text-boder-default mt-[3px] "/>
						<div className="flex items-center  h-[68px] px-[80px] relative bottom-2">
							{modifiedData.map((step, index) => {
								const nextIndexdata = modifiedData[index + 1];
								return (
									<React.Fragment key={step.id}>
                    <div
                      className={`h-[14px] w-[14px] rounded-full ${
                        step.status === LeadOpStatus.OTHER ||
                        step.status === LeadOpStatus.PAUSE ||
                        step.status === LeadOpStatus.LOST ||
                        step.status === LeadOpStatus.WIN
                          ? statusColorForCircle[step.status]
                          : statusColorForCircle[LeadOpStatus.ACTIVE]
                      }  relative`}
                    >
                      {step.status !== LeadOpStatus.OTHER && (
                        <p className="absolute top-[30px] font-semibold w-[145px] -translate-x-[50%]  left-[50%] text-center list-none text-[12px] text-accent-default">
                          {toUpperCasefunc(step?.status)}
                        </p>
                      )}
                    </div>
                    {index < modifiedData.length - 1 && (
                      <div
                        style={dealTrackWidth}
                        className={`h-[3px] ${
                          nextIndexdata.status === LeadOpStatus.OTHER ||
                          nextIndexdata.status === LeadOpStatus.PAUSE ||
                          nextIndexdata.status === LeadOpStatus.LOST
                            ? stepColors[nextIndexdata?.status]
                            : stepColors[LeadOpStatus.ACTIVE]
                        }  `}
                      ></div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MyApplicationCard;

const MyApplicationCardMobile: React.FC<DealCardProps> = ({ data }) => {
  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <div className="text-accent-default ">
          <p className=" text-sm font-semibold">Номер сделки</p>
          <p className="font-medium text-[12px]"> {data?.lead_number}</p>
        </div>
        <div
          className={` text-green-700 px-[20px] h-[26px] rounded-lg text-[12px] flex items-center justify-center font-medium ${
            data?.lead_ops[0].status === LeadOpStatus.PAUSE ||
            data?.lead_ops[0].status === LeadOpStatus.LOST
              ? statusColorForCircle[data?.lead_ops[0].status]
              : statusColorForCircle[LeadOpStatus.ACTIVE]
          }`}
        >
          Открыта
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 text-accent-default">
        <div>
          <p className=" text-sm font-semibold">Клиент</p>
          <p className="font-medium text-[12px]">{data?.client?.fullname}</p>
        </div>
        <div>
          <p className=" text-sm font-semibold">Телефон</p>
          <p className="font-medium text-[12px]">
            +7 {formatPhoneNumber(data?.client?.phone_number.slice(2))}
          </p>
        </div>
        <div>
          <p className=" text-sm font-semibold">Проект</p>
          <p className="font-medium text-[12px]">{data?.project.name}</p>
        </div>
        <div>
          <p className=" text-sm font-semibold">Помещение</p>
          <p className="font-medium text-[12px]">
            {primisesType[data?.premise.type as Primise]}
          </p>
        </div>
      </div>
    </>
  );
};
