import { useEffect, useState } from "react";
import { Project } from "modules/Deal/type";
import { useGetProjectsByCityIdMutation } from "data/api/project";

interface UseGetProjectByCityIdProps {
  cityId?: number;
}

const useGetProjectByCityId = ({ cityId }: UseGetProjectByCityIdProps) => {
  const [projects, setProjects] = useState<Project[] | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [getProjectByCityId] = useGetProjectsByCityIdMutation();

  useEffect(() => {
    if (cityId) {
      const fetchProject = async () => {
        setIsLoading(true);
        try {
          const { data: res } = await getProjectByCityId(cityId).unwrap();

          console.log("res", res);
          setProjects(res);
        } catch (err) {
          console.log("Failed to fetch project data.");
        } finally {
          setIsLoading(false);
        }
      };

      fetchProject();
    }
  }, [cityId, getProjectByCityId]);

  return { projects, isLoading };
};

export default useGetProjectByCityId;
