import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ButtonForm from "components/Button/ButtonForm";
import CloseWithBorderIcon from "components/constant/icons/CloseWithBorderIcon";
import Modal from "components/Modal";
import { Controller, useForm } from "react-hook-form";
import { TrainingType } from "data/api/training/type";
import TextField from "components/Form/TextField/TextField";
import Label from "components/Form/Label";
import { Editor } from "components/Form/Editor";
import ToggleSwitcher from "components/ToggleSwitcher";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCreateTrainings,
  hanldeUpdateTraining,
} from "store/TrainingReducer";
import ArrowDropdown from "components/constant/icons/ArrowDropdown";
import { User } from "data/api/profile/type";
import { useGetAllUsersQuery } from "data/api/profile";
import { images } from "utils/resource";
import { formatPhoneNumber } from "hooks/useFormatInput";
import CancelIcon from "components/constant/icons/CancelIcon";

interface Props {
  isCreateQuestionModalOpen: boolean;
  setIsCreateQuestionModalOpen: Dispatch<SetStateAction<boolean>>;
  updateTraining?: TrainingType;
  categoryId?: {
    ref_id: string | undefined;
    id: number;
  };
  setUpdateTraining: Dispatch<SetStateAction<TrainingType | undefined>>;
}

const CreateQuestionModal = ({
  isCreateQuestionModalOpen,
  setIsCreateQuestionModalOpen,
  updateTraining,
  categoryId,
  setUpdateTraining,
}: Props) => {
  const [searchField, setSeachField] = useState<string | undefined>();
  const [user, setUser] = useState<User | null>();
  const [isResultsVisible, setIsResultsVisible] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState<
    string | undefined | null
  >("all");

  const { data: usersData } = useGetAllUsersQuery(`fullname=${searchField}`);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  } = useForm<TrainingType>({
    defaultValues: {
      title: "",
      content: "",
      is_show: false,
      is_copy_enabled: false,
      is_active: false,
      cover_image: "hello world",
    },
  });

  useEffect(() => {
    setSelectedSetting(updateTraining?.access);
  }, [updateTraining]);

  useEffect(() => {
    if (updateTraining) {
      reset(updateTraining);
    } else {
      resetForm();
    }
  }, [updateTraining, reset]);

  const onSubmit = (data: TrainingType) => {
    if (updateTraining) {
      dispatch(
        hanldeUpdateTraining({
          ...data,
          access: !user ? selectedSetting : undefined,
          access_user_id: user?.id,
        })
      );
    } else {
      dispatch(
        handleCreateTrainings({
          ...data,
          access: !user ? selectedSetting : undefined,
          access_user_id: user?.id,
          category_id: categoryId?.id || 0,
          category_ref_id: categoryId?.ref_id,
          id: Date.now(),
        })
      );
    }
    setUpdateTraining(undefined);
    setIsCreateQuestionModalOpen(false);
    resetForm();
    setUser(null);
    setSeachField("");
    setSelectedSetting("");
  };

  function resetForm() {
    reset({
      title: "",
      content: "",
      is_show: false,
      is_copy_enabled: false,
      is_active: false,
      cover_image: "hello world",
    });
  }

  const handleCloseModal = () => {
    resetForm();
    setUpdateTraining(undefined);
    setIsCreateQuestionModalOpen(false);
    setSelectedSetting("");
  };

  const handleSearchUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSeachField(e.target.value);
  };

  const handleUserSelect = (user: User) => {
    setIsResultsVisible(false);
    setUser(user);
  };

  const settingAccess = [
    { id: 1, value: "all", label: "Все" },
    { id: 2, value: "agent", label: "Агенты" },
    { id: 3, value: "new_user", label: "Новые пользователи" },
    { id: 4, value: "choose", label: "Выборочно" },
  ];

  return (
    <>
      <Modal
        isOpen={isCreateQuestionModalOpen}
        className="mx-auto w-full  sm:w-[672px]  rounded-t-[30px] rounded-b-0 sm:rounded-lg relative"
      >
        <div className={"flex justify-between items-start  mb-5"}>
          <h1 className={"font-extrabold text-[20px] font-dewi"}>
            Создание вопроса
          </h1>

          <ButtonForm
            text={<CloseWithBorderIcon />}
            onClick={handleCloseModal}
          />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-5 max-h-[70vh] overflow-auto toolbar-scroll pr-3"
        >
          <Controller
            name="title"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value}
                onChange={onChange}
                placeholder="Введите вопрос"
                label="Вопрос"
              />
            )}
          />
          <Label label="Описание" className={"w-full"}>
            <Controller
              name="content"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <Editor value={value} onChangeValue={onChange} />
              )}
            />
          </Label>
          <Label label="Настройка доступа">
            <div className="flex mt-2 items-center gap-[10px] no-scrollbar overflow-x-auto pb-2 mb-3 lg:mb-1 ">
              {settingAccess.map((item, i) => (
                <div
                  key={item.id}
                  onClick={() => {
                    setSelectedSetting(item.value);
                    setUser(null);
                    setSeachField("");
                  }}
                  className={`py-[9px] px-[24px] lg:py-[11px] lg:px-6 flex text-center rounded-[32px] text-sm font-normal flex-shrink-0  cursor-pointer ${
                    selectedSetting === item.value
                      ? "bg-accent-default text-white-default"
                      : "bg-bg-default text-accent-default"
                  }`}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </Label>
          {selectedSetting === "choose" && (
            <div className="relative">
              {user ? (
                <div className="py-[12px] px-4 flex justify-between  border border-boder-default  items-center hover:bg-bg-default w-full bg-white-default rounded-[4px]">
                  <img
                    src={images.profileImg}
                    width={24}
                    height={24}
                    alt="img user"
                    className=" w-[24px] h-[24px] rounded-full"
                  />
                  <div className="w-full pl-3">
                    <p className="text-sm font-semibold">{user.fullName}</p>
                    <p className="text-[12px] font-normal">
                      {user?.agency?.legalName}
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      setUser(null);
                    }}
                  >
                    <CancelIcon />
                  </button>
                </div>
              ) : (
                <div className="relative">
                  <input
                    value={searchField}
                    placeholder="Поиск пользователя"
                    onFocus={() => setIsResultsVisible(true)}
                    className="flex-1 px-[16px] py-[12px] placeholder:text-accent-disabled  w-full border-[1px] focus:border-boder-focus border-boder-default outline-none placeholder:font-normal font-normal text-[14px] rounded-[4px]"
                    name="user"
                    onChange={handleSearchUser}
                  />
                  <div
                    onClick={() => setIsResultsVisible(!isResultsVisible)}
                    className={` absolute  cursor-pointer top-[50%] -translate-y-[50%] bg-white-default right-[2px] w-[30px] flex items-center justify-center  ${
                      isResultsVisible ? "rotate-180" : ""
                    }`}
                  >
                    <ArrowDropdown />
                  </div>
                </div>
              )}

              {isResultsVisible && (
                <div className=" absolute w-full flex flex-col bg-white-default max-h-[200px] overflow-auto toolbar-scroll z-20 border border-boder-default shadow-md rounded-sm">
                  {usersData?.data.length ? (
                    usersData.data.map((agent) => (
                      <div
                        key={agent.id}
                        onClick={() => handleUserSelect(agent)}
                        className="py-2 px-4 flex justify-between  border-b border-boder-default items-center hover:bg-bg-default w-full bg-white-default rounded-[4px] cursor-pointer"
                      >
                        <div className="w-full pl-3">
                          <p className="text-sm font-semibold">
                            {agent.fullName}
                          </p>
                          <p className="text-[12px] font-normal">
                            +7 {formatPhoneNumber(agent?.phone)}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      onClick={() => setIsResultsVisible(false)}
                      className="w-full hover:bg-bg-default cursor-pointer text-sm text-accent-default py-3 px-4"
                    >
                      Ничего не найдено
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          <Controller
            name="is_copy_enabled"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field: { onChange, value } }) => (
              <ToggleSwitcher
                isToggle={value}
                setIsToggle={onChange}
                label="Запретить копирование"
              />
            )}
          />
          <Controller
            name="is_show"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field: { onChange, value } }) => (
              <ToggleSwitcher
                isToggle={value}
                setIsToggle={onChange}
                label="Отображать на главной странице"
              />
            )}
          />

          <div className="flex flex-col sm:flex-row gap-[10px] mt-[10px]">
            <ButtonForm
              onClick={handleCloseModal}
              text="Отмена"
              type="button"
              className="py-3 px-6 bg-bg-default hover:bg-accent-default xl:flex-1 flex justify-center hover:text-white-default w-full"
            />
            <ButtonForm
              disabled={!isValid}
              type="submit"
              text="Готово"
              className="py-3 px-6 border bg-accent-default xl:flex-1 flex justify-center text-white-default w-full hover:bg-accent-disabled hover:text-bg-default"
            />
          </div>
        </form>
      </Modal>
      {/* <ChooseUsers
        isOpenChooseUser={selectedSetting === "choose"}
        setIsOpenChooseUser={setSelectedSetting}
      /> */}
    </>
  );
};

export default CreateQuestionModal;
