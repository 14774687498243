const useFormattedDate = () => {
  const formatDate = (isoString: string, timing: boolean): string => {
    const date = new Date(isoString);

    const dateOptions: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    const timeOptions: Intl.DateTimeFormatOptions = timing
      ? {
          hour: "2-digit",
          minute: "2-digit",
        }
      : {};

    const options = { ...dateOptions, ...timeOptions };

    const formatter = new Intl.DateTimeFormat("ru-RU", options);

    return formatter.format(date);
  };

  return formatDate;
};

export default useFormattedDate;
