import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  NavLink,
  Link,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import Logo from "../../constant/icons/Logo";
import ArrowDropdown from "../../constant/icons/ArrowDropdown";
import {
  headerIconLinks,
  iconConfig,
  navLinksNested,
  pageTitles,
} from "../../../utils/navUtils";
import { useWindowSize } from "hooks/useWindowSize";
import { images } from "utils/resource";
import BackIcon from "components/constant/icons/BackIcon";
import LocationIcon from "components/constant/icons/LocationIcon";
import { useGetCitiesQuery } from "data/api/city";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectionCity,
  handleSelectCity,
  toggleCheckingFasteningModal,
  toggleFasteningFormModal,
  toggleRecordForShowModal,
  toggleSearchModal,
} from "store/HeaderReducer";
import { dropDownOptionsType } from "modules/Apartment/Filter/type";
import RecordForShow from "components/BookingAndVisits/RecordForShowForm";
import { mamberLocalName, UserRole } from "enums/users";
import { BottomNavigationBar } from "./BottomNavbar";
import Tooltip from "components/Tooltip";
import { RootState, useAppSelector } from "store";
import Modal from "components/Modal";
import NotificationsModal from "components/Notifications/NotificationModal";
import { useGetAllNotificationsQuery } from "data/api/notification";
import { useGetOnePremiseQuery } from "data/api/premises";
import { matchPath } from "react-router";
import BaseClientFormNotification from "modules/BaseClients/BaseClientFormNotification";
import BaseClientsAnchoringModal from "modules/BaseClients/BaseClientsAnchoringModal";
import GlobalSearch from "../GlobalSearch";
import { selectUser } from "store/UserReducer/selectors";

export interface NavLinkType {
  label: string;
  path?: string;
  notAllowedRoles: UserRole[];
  nav?: NavLinkType[];
}

const Header = () => {
  const [hoveredIconId, setHoveredIconId] = useState<number | null>(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [dropdownCity, setDropdownCity] = useState(false);

  const [city, setcity] = useState<string>();

  const storeSelectCity = useSelector(getSelectionCity);

  const dropdownRef = useRef<HTMLLIElement>(null);

  const windowSize = useWindowSize();

  const userData = useAppSelector(selectUser);

  const { data: notifications } = useGetAllNotificationsQuery();

  const dispatch = useDispatch();

  const res = useGetCitiesQuery();

  const options = useFormatForDropDownOptions(res?.data?.data ?? []);

  const selection = useSelector((state: RootState) => state.header);

  const location = useLocation();

  const unreadedNotificationsCount = () => {
    let count = 0;
    notifications?.data?.map((not) => {
      if (!not.is_read) {
        count += 1;
      }
    });

    return count;
  };

  const profileData = userData?.data;

  useEffect(() => {
    setDropdownOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    if (!city && profileData?.city?.id) {
      const savedCity = localStorage.getItem("city_id") || profileData.city.id;
      setcity(profileData?.city?.name);
      dispatch(handleSelectCity(Number(savedCity)));
    }
  }, [profileData, city]);

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  useEffect(() => {
    if (storeSelectCity) {
      const findCity = options.find(
        (item) => item.value === storeSelectCity.city_id
      );

      findCity && setcity(findCity.label);
    }
  }, [storeSelectCity]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
  };

  const handleCityChange = (city: dropDownOptionsType) => {
    setcity(city.label);
    dispatch(handleSelectCity(Number(city.value)));
    localStorage.setItem("city_id", city.value + "");
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  function filterNavLinksByRole(
    role: UserRole,
    links: NavLinkType[]
  ): NavLinkType[] {
    return links
      .filter((link) => !(link.notAllowedRoles || []).includes(role))
      .map((link) => ({
        ...link,
        nav: link.nav ? filterNavLinksByRole(role, link.nav) : undefined,
      }));
  }
  const filteredNavLinks = useMemo(
    () =>
      profileData?.role &&
      filterNavLinksByRole(profileData?.role, navLinksNested as any),
    [profileData?.role]
  );

  const handleMouseEnter = (id: number) => {
    setHoveredIconId(id);
  };

  const handleMouseLeave = () => {
    setHoveredIconId(null);
  };

  const hanldeCloseRecordForShowModal = () => {
    dispatch(toggleRecordForShowModal());
  };

  const handleCloseCheckingFastening = () => {
    dispatch(toggleCheckingFasteningModal(false));
  };

  const handleFasteningForm = () => {
    dispatch(toggleFasteningFormModal(false));
  };

  const imgUrl = process.env.REACT_APP_FILES_URL;

  return (
    <header className="flex flex-col bg-white-default fixed lg:static top-0 w-full z-20">
      <div className="h-[65px] w-full br-container  flex items-center justify-between relative">
        <div className="hidden lg:flex">
          <Logo color="#EF3B24" width={windowSize.isLg ? "200" : "259"} />
        </div>
        <div className="flex items-center w-full lg:w-auto  gap-[25px]">
          <div className="flex items-center w-full lg:w-auto  justify-between lg:justify-normal gap-[20px] relative">
            <div
              className={` hidden lg:flex items-center justify-center gap-[10px] cursor-pointer text-accent-default relative`}
              onClick={() => setDropdownCity(!dropdownCity)}
            >
              <LocationIcon />
              <button className=" outline-none">
                {city ? city : profileData?.city?.name}
              </button>
              {dropdownCity && (
                <ul
                  className="absolute left-[50%] -translate-x-[50%] z-50  mt-[30px] top-[10px]
                  w-[201px] bg-white-default text-accent-default
                  shadow-lg border border-boder-default rounded-[8px] before:content-[''] before:w-[20px] before:h-[20px] 
                  before:absolute before:-top-[10px] before:border-t before:border-l before:z-10 before:border-boder-default before:left-[50%] before:-translate-x-[50%] before:bg-white-default before:rotate-45"
                >
                  <div className="flex flex-col max-h-[200px] overflow-y-auto toolbar-scroll">
                    {options.map((city) => (
                      <li
                        onClick={() => handleCityChange(city)}
                        key={city?.value}
                        className=" hover:bg-bg-default py-2 z-20"
                      >
                        <span className="px-[20px]">{city?.label}</span>
                      </li>
                    ))}
                  </div>
                </ul>
              )}
            </div>
            <div className="h-[18px] w-[2px] hidden lg:block bg-black-primary opacity-10 "></div>

            {!windowSize.isMd ? (
              headerIconLinks.map((item) => (
                <div
                  key={item?.id}
                  onClick={item.onClick}
                  onMouseEnter={() => handleMouseEnter(item.id)}
                  onMouseLeave={handleMouseLeave}
                  className={`w-[24px] h-[24px] flex items-center justify-center cursor-pointer relative`}
                >
                  {item.icon}
                  {hoveredIconId === item.id && <Tooltip text={item.title} />}
                  {item.title === "Уведомление" &&
                  unreadedNotificationsCount() ? (
                    <div className=" absolute bg-red-default text-white-default font-semibold text-[10px] w-[15px] h-[15px] -top-1 -right-1 rounded-full flex items-center justify-center">
                      {unreadedNotificationsCount()}
                    </div>
                  ) : null}
                </div>
              ))
            ) : (
              <MobileNavbar />
            )}
          </div>
          <Link to="/profile" className="ml-auto hidden lg:flex">
            <div className="flex items-center gap-[15px]">
              <div className="h-[18px] w-[2px] bg-black-primary opacity-10 "></div>
              <img
                src={
                  profileData?.avatar
                    ? `${imgUrl}/${profileData?.avatar}`
                    : images.defaultImg
                }
                alt="profile"
                className="  rounded-full w-[44px] h-[44px] self-start "
                width={44}
                height={44}
                crossOrigin="anonymous"
              />

              <div className=" hidden lg:flex flex-col text-accent-default">
                <h3 className="p-0 m-0 text-[16px] leading-[18.75px] font-medium truncate max-w-[200px]">
                  {profileData?.fullName}
                </h3>
                <p className="p-0 m-0 text-[12px] leading-[16px] font-medium opacity-50">
                  {/* @ts-ignore */}
                  <span>{mamberLocalName[profileData?.role]}</span>
                </p>
              </div>
            </div>
          </Link>
        </div>
        <RecordForShow
          isOpenModal={selection.recordForShowModal}
          handleToggle={hanldeCloseRecordForShowModal}
        />
        <BaseClientsAnchoringModal
          isOpen={selection.isCheckingTheFasteningOpenModal}
          setIsOpen={handleCloseCheckingFastening}
        />
        <BaseClientFormNotification
          isOpen={selection.isFasteningFormOpenModal}
          handleToggle={handleFasteningForm}
        />
        {selection.isNotificationOpen ? <NotificationsModal /> : null}
      </div>
      <div className="h-[.5px] bg-black-primary opacity-10"></div>
      <nav
        className="h-[52px] hidden  br-container w-full text-[14px] text-accent-default font-normal lg:flex justify-between 
      items-center"
      >
        <ul className=" space-x-[30px] flex h-full items-center">
          {filteredNavLinks?.map((link, index) =>
            link.nav ? (
              <li key={index} className="relative" ref={dropdownRef}>
                <button
                  onClick={handleDropdownToggle}
                  className="focus:outline-none flex items-center"
                >
                  {link.label}
                  {!dropdownOpen ? (
                    <ArrowDropdown color="#EF3B24" />
                  ) : (
                    <div className="rotate-180">
                      <ArrowDropdown color="#EF3B24" />
                    </div>
                  )}
                </button>
                {dropdownOpen && (
                  <ul
                    className="absolute left-[50%] -translate-x-[50%] z-50 flex flex-col gap-[15px] mt-[30px] 
                  w-[201px] bg-white-default text-black 
                  shadow-lg p-[20px] rounded-[8px] before:content-[''] before:w-[20px] before:h-[20px] 
                  before:absolute before:-top-[10px] before:left-[50%] before:-translate-x-[50%] before:bg-white-default before:rotate-45"
                  >
                    {link.nav.map((sublink, subIndex) => (
                      <li key={subIndex} className=" hover:bg-gray-200">
                        <NavLink
                          to={sublink.path as string}
                          className={({ isActive }) =>
                            isActive ? "underline" : undefined
                          }
                        >
                          {sublink.label}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ) : (
              <li key={index} className="flex items-center h-full">
                <NavLink
                  to={link.path as string}
                  className={({ isActive }) =>
                    isActive
                      ? " flex items-center  border-b-[2px] border-accent-default h-full"
                      : undefined
                  }
                >
                  {link.label}
                </NavLink>
              </li>
            )
          )}
        </ul>
        <div className=" relative ml-auto">
          <GlobalSearch />
        </div>
      </nav>
      <div className="h-[1px] bg-black-primary opacity-10 "></div>
      {!windowSize.isLg ? <BottomNavigationBar /> : null}
    </header>
  );
};

export default Header;

export const MobileNavbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useGetOnePremiseQuery(id);
  const selection = useSelector((state: RootState) => state.header);
  const dispatch = useDispatch();

  const routes = Object.keys(iconConfig(navigate));

  const matchingRoute = routes.find((route) =>
    matchPath(route, location.pathname)
  );

  const currentIcons = iconConfig(navigate)[matchingRoute || ""] || {
    leftIcons: [
      {
        icon: <BackIcon />,
        onClick: () => navigate(-1),
      },
    ],
    rightIcons: [],
  };

  function findClosestKey(path: string): string {
    if (pageTitles[path]) {
      return path;
    }

    const keys = Object.keys(pageTitles);
    for (const key of keys) {
      const regexPattern = key.replace(/:[^\s/]+/g, "([^\\s/]+)");
      const regex = new RegExp(`^${regexPattern}$`);

      if (regex.test(path)) {
        return key;
      }
    }

    return "";
  }

  return (
    <>
      <div className="flex gap-[15px]">
        {currentIcons.leftIcons.map((iconObj, index) => (
          <div
            key={index}
            className="w-[24px] h-[24px] flex items-center justify-center cursor-pointer"
            onClick={iconObj.onClick}
          >
            {iconObj.icon}
          </div>
        ))}
      </div>
      <h3 className=" text-[16px] font-medium absolute left-[50%] -translate-x-[50%] leading-[18.75px]">
        {pageTitles[findClosestKey(location.pathname)] === "Квартира"
          ? data?.data.name
          : pageTitles[findClosestKey(location.pathname)]}
      </h3>
      <div className="flex gap-[12px]">
        {currentIcons.rightIcons.map((iconObj, index) => (
          <div
            key={index}
            className="w-[24px] h-[24px] flex items-center justify-center cursor-pointer"
            onClick={iconObj.onClick}
          >
            {iconObj.icon}
          </div>
        ))}
      </div>
      <Modal
        isOpen={selection.isSearchModalOpen}
        onClose={() => {
          dispatch(toggleSearchModal());
        }}
        className={"md:w-auto w-full rounded-t-[30px] sm:rounded-[8px]"}
      >
        <GlobalSearch />
      </Modal>
    </>
  );
};
