import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "data/api";
import { MetaData } from "data/api/exception";
import { ProfileRequestType, User, UserMeta } from "data/api/profile/type";

export const getUserAction = createAsyncThunk<
  MetaData<User, UserMeta>,
  void,
  { rejectValue: Error }
>("user/getUser", async (_, { rejectWithValue }) => {
  try {
    const user = await api.fetch<MetaData<User, UserMeta>>({
      path: "/users/me",
    });

    return user;
  } catch (err) {
    const error = err as Error;
    return rejectWithValue(error);
  }
});

export const updateUserAction = createAsyncThunk<
  MetaData<User>,
  MetaData<ProfileRequestType>,
  { rejectValue: Error }
>("user/updateUser", async ({ data }, { rejectWithValue }) => {
  try {
    const user = await api.fetch<MetaData<User>>({
      path: "/users",
      method: "PUT",
      body: { meta: {}, data },
    });

    return user;
  } catch (err) {
    const error = err as Error;
    return rejectWithValue(error);
  }
});

export const logoutUserAction = createAsyncThunk("user/logoutUser", () => {});
