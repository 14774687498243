import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";

interface DataItem {
  name: string;
  uv: number;
  amt: number;
}

interface Props {
  data: DataItem[];
  title: string;
}

const BarChartComponent = ({ data, title }: Props) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: {
    active?: boolean;
    payload?: any[];
    label?: string;
  }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ddd",
            borderRadius: "5px",
            textAlign: "center",
            top: 0,
          }}
        >
          <p>{`${label}`}</p>
          <p>{`Value: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="p-[15px] bg-white-default rounded-lg flex-1">
      <h3 className="text-accent-default text-[16px] font-medium">{title}</h3>
      <div style={{ width: "100%", height: 230 }}>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 30,
              bottom: 14,
            }}
            barSize={10}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              label={{ value: "Апр", position: "left", offset: 0 }}
              dataKey="name"
            />
            <YAxis />
            <Tooltip
              cursor={{ fill: "transparent" }}
              content={<CustomTooltip />}
            />
            <Bar
              dataKey="uv"
              stackId="a"
              radius={[10, 10, 0, 0]}
              onMouseLeave={handleMouseLeave}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={index === activeIndex ? "#FF0000" : "#37465B"}
                  onMouseEnter={() => handleMouseEnter(index)}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BarChartComponent;
