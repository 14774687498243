export enum LeadOpStatus {
  ACTIVE = "активные",
  PAUSE = "на паузе",
  WIN = "выиграна",
  LOST = "проиграна",
  OTHER = "other",
}

export enum DealStatus {
  ACTIVE = "Активные",
  PAUSE = "На паузе",
  WIN = "Выиграна",
  LOST = "Проиграна",
}
export enum DealSteps {
  OPEN = "открыта",
  INTEREST_IN_PURCHASING = "интерес к покупке",
  PRESENTATION = "презентация",
  BOOKED = "бронь",
  REQUEST_FOR_CONTRACT = "заявка на договор",
  CONTRACT_IS_REGISTERED = "договор зарегистрирован",
  WON = "выиграна",
  BOOK_CANCELED = "отмененная бронь",
  LOST_BOOK = "слетевшая бронь",
  ON_PAUSE = "на паузе",
  CHECK_LEAD = "проверка лида",
  FAILED = "проиграна",
}

export const dealStepsOptions = [
  { value: DealSteps.OPEN, label: "Oткрыта" },
  {
    value: DealSteps.INTEREST_IN_PURCHASING,
    label: DealSteps.INTEREST_IN_PURCHASING,
  },
  {
    value: DealSteps.PRESENTATION,
    label: DealSteps.PRESENTATION,
  },
  {
    value: DealSteps.BOOKED,
    label: DealSteps.BOOKED,
  },
  {
    value: DealSteps.REQUEST_FOR_CONTRACT,
    label: DealSteps.REQUEST_FOR_CONTRACT,
  },
  {
    value: DealSteps.WON,
    label: DealSteps.WON,
  },
  {
    value: DealSteps.CONTRACT_IS_REGISTERED,
    label: DealSteps.CONTRACT_IS_REGISTERED,
  },
  {
    value: DealSteps.BOOK_CANCELED,
    label: DealSteps.BOOK_CANCELED,
  },
  {
    value: DealSteps.LOST_BOOK,
    label: DealSteps.LOST_BOOK,
  },
  {
    value: DealSteps.ON_PAUSE,
    label: DealSteps.ON_PAUSE,
  },
  {
    value: DealSteps.CHECK_LEAD,
    label: DealSteps.CHECK_LEAD,
  },
  {
    value: DealSteps.FAILED,
    label: DealSteps.FAILED,
  },
];
