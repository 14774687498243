import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import BellIcon from "components/constant/icons/BellIcon";
import HamburgerMenu from "components/constant/icons/HamburgerMenu";
import HomeIcon from "components/constant/icons/HomeIcon";
import SearchIcon from "components/constant/icons/SearchIcon";

import { navLinksFlat } from "utils/navUtils";
import { images } from "utils/resource";
import { useRoles } from "hooks/useRoles";
import { UserRole } from "enums/users";
import { NavLinkType } from ".";
import { NavLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { RootState, useAppSelector } from "store";
import { selectUser } from "store/UserReducer/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  handleSelectCity,
  toggleNotificationModal,
  toggleSearchModal,
} from "store/HeaderReducer";
import Logo from "components/constant/icons/Logo";
import LocationIcon from "components/constant/icons/LocationIcon";
import { useGetCitiesQuery } from "data/api/city";
import ArrowTableIcon from "components/constant/icons/Table/ArrowTableIcon";

export const BottomNavigationBar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [dropdownCityOpen, setDropdownCityOpen] = useState(false);

  const userData = useAppSelector(selectUser);

  const dispatch = useDispatch();

  const location = useLocation();

  const navigate = useNavigate();

  const roles = useRoles(null);

  const dropdownRef = useRef<HTMLUListElement | null>(null);

  const dropdownCityRef = useRef<HTMLUListElement | null>(null);

  const selections = useSelector((state: RootState) => state.header);

  const { data: cities } = useGetCitiesQuery();

  const imgUrl = process.env.REACT_APP_FILES_URL;

  const profileData = userData?.data;

  useEffect(() => {
    setDropdownOpen(false);
  }, [location]);

  const handleClickOutside = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      if (dropdownCityOpen) {
        handleBackToMenu();
      } else {
        setDropdownOpen(false);
      }
    }

    if (
      dropdownCityRef.current &&
      !dropdownCityRef.current.contains(e.target as Node)
    ) {
      handleBackToMenu();
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  const icons = [
    { id: 1, icon: <HomeIcon />, label: "/", onclick: () => navigate("/") },
    {
      id: 2,
      icon: <SearchIcon color="#37465B" />,
      label: "search",
      onclick: () => dispatch(toggleSearchModal()),
    },
    {
      id: 3,
      icon: <HamburgerMenu />,
      label: "menu",
      onclick: () => {
        setDropdownOpen(!dropdownOpen);
      },
    },
    {
      id: 4,
      icon: <BellIcon />,
      label: "notifications",
      onclick: () => {
        dispatch(toggleNotificationModal(true));
      },
    },
    {
      id: 5,
      icon: (
        <img
          src={
            profileData?.avatar
              ? `${imgUrl}/${profileData?.avatar}`
              : images.defaultImg
          }
          alt="profile"
          className="object-contain rounded-full opacity-70 cursor-pointer w-[26px] h-[26px]"
          crossOrigin="anonymous"
        />
      ),
      onclick: () => navigate("/profile"),
      label: "/profile",
    },
  ];

  function filterNavLinksByRole(
    role: UserRole,
    links: NavLinkType[]
  ): NavLinkType[] {
    return links.filter((link) => !(link.notAllowedRoles || []).includes(role));
  }
  const filteredNavLinks = useMemo(
    () =>
      roles?.currentRole &&
      filterNavLinksByRole(roles.currentRole, navLinksFlat),
    [roles?.currentRole]
  );

  const handleBackToMenu = () => {
    setDropdownCityOpen(false);
    setDropdownOpen(true);
  };

  const handleOpenCitySelectModal = () => {
    setDropdownCityOpen(true);
    setDropdownOpen(false);
  };

  const handleCityChange = (city_id: number) => {
    dispatch(handleSelectCity(city_id));
    localStorage.setItem("city_id", JSON.stringify(city_id));
    handleBackToMenu();
  };

  const city = cities?.data.find((item) => item?.id === selections.city_id);

  return (
    <nav className="bg-white-default fixed bottom-0 h-[60px] w-full  shadow-sm border-t border-boder-default bottom-navbar">
      <div className="bg-container flex items-center justify-around sm:gap-10 px-[20px] w-full">
        {icons.map((item) => (
          <div
            onClick={() => item.onclick()}
            key={item.id}
            className={`cursor-pointer pt-[8px] ${
              location.pathname === item.label &&
              item.label !== "menu" &&
              "border-t-2 border-accent-default "
            }`}
          >
            {item.icon}
          </div>
        ))}
      </div>
      <AnimatePresence>
        {dropdownOpen && (
          <motion.div
            initial={{ y: "100vh", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "100vh", opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <ul
              ref={dropdownRef}
              className="absolute left-[50%] -translate-x-[50%] z-10 flex flex-col bottom-[78px] mt-[30px]
                    w-[320px] bg-white-default text-black 
                    shadow-lg  rounded-[8px] before:content-[''] before:w-[20px] before:h-[20px] 
                    before:absolute before:-bottom-[10px] before:left-[50%] before:-translate-x-[50%] before:bg-white-default before:rotate-45"
            >
              <div className="flex flex-col max-h-[75vh] overflow-y-auto text-center rounded-t-lg ">
                <div className="flex flex-col items-center justify-center gap-4 pt-5 pb-[10px]">
                  <Logo color="#EF3B24" width="179" />
                  <div className="flex items-center gap-[5px]">
                    <LocationIcon />
                    <span
                      onClick={handleOpenCitySelectModal}
                      className="text-sm text-accent-default"
                    >
                      {city?.name}
                    </span>
                  </div>
                </div>
                {filteredNavLinks?.length &&
                  filteredNavLinks.map((item, i) => (
                    <NavLink
                      to={item.path || "/"}
                      key={i}
                      className={({ isActive }) =>
                        isActive
                          ? `bg-bg-default ${i === 0 && "rounded-t-lg"}`
                          : undefined
                      }
                    >
                      <li className=" hover:bg-gray-200  border-t py-3 border-boder-default opacity-70 hover:bg-bg-default text-sm text-accent-default font-medium list-none">
                        {item.label}
                      </li>
                    </NavLink>
                  ))}
              </div>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {dropdownCityOpen && (
          <motion.div
            initial={{ y: "100vh", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "100vh", opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <ul
              ref={dropdownCityRef}
              className="absolute left-[50%] -translate-x-[50%] z-10 flex flex-col bottom-[78px] mt-[30px]
                    w-[320px] bg-white-default text-black 
                    shadow-lg  rounded-[8px] before:content-[''] before:w-[20px] before:h-[20px] 
                    before:absolute before:-bottom-[10px] before:left-[50%] before:-translate-x-[50%] before:bg-white-default before:rotate-45"
            >
              <div className="flex flex-col max-h-[75vh] overflow-y-auto text-center rounded-t-lg ">
                <div className="flex p-[10px] justify-between">
                  <div onClick={handleBackToMenu} className=" rotate-[90deg]">
                    <ArrowTableIcon />
                  </div>
                  <h2 className=" text-accent-default font-dewi text-[18px]">
                    Выбор города
                  </h2>
                  <div></div>
                </div>
                {filteredNavLinks?.length &&
                  cities?.data?.map((item, i) => (
                    <li
                      onClick={() => handleCityChange(item.id)}
                      key={item?.id}
                      className={` hover:bg-gray-200  border-t py-3 border-boder-default opacity-70 hover:bg-bg-default text-sm text-accent-default font-medium list-none ${
                        item.id === city?.id && "bg-bg-default"
                      }`}
                    >
                      {item.name}
                    </li>
                  ))}
              </div>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};
