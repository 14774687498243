import React, { useEffect, useState } from "react";
import { useRoles } from "hooks/useRoles";
import { infoCartData, personalInfoCardData, setColorByStatus } from "./data";
import AnaliticsInfoCart from "./Card/AnaliticsInfoCart";
import PieChartComponent from "./charts/PieChart";
import {
  useGetLeadsByStatusAnalyticsQuery,
  useGetLeadsCountAnalyticsQuery,
  useGetMainAnalyticsInfoQuery,
} from "data/api/analytics";
import {
  MainAnalyticsInfoType,
  PersonalAnalyticsInfo,
} from "data/api/analytics/type";
import { objectToQueryString } from "utils/objectToQuery";
import { IFiltersAnalytics } from ".";

export interface optionType {
  value: string;
  label: string;
}

export interface IProps {
  filter: IFiltersAnalytics;
}

type KeyOFObj = MainAnalyticsInfoType | PersonalAnalyticsInfo;

const AnalyticsMainInfoList = ({ filter }: IProps) => {
  const roles = useRoles(null);

  const { data } = useGetMainAnalyticsInfoQuery(objectToQueryString(filter));

  const [selectStep, setSelectStep] = useState<optionType | null>(null);

  const { data: leadsCount } = useGetLeadsCountAnalyticsQuery(
    objectToQueryString({ status: selectStep?.value, ...filter })
  );

  const { data: statusLeads } = useGetLeadsByStatusAnalyticsQuery(
    objectToQueryString(filter)
  );

  const [info, setInfo] = useState<
    MainAnalyticsInfoType | PersonalAnalyticsInfo | null
  >(null);

  useEffect(() => {
    if (roles.isAdmin) {
      const infoData = data?.data as MainAnalyticsInfoType;
      setInfo({
        complited_leads_fee: {
          past: infoData?.complited_leads_fee?.past || 0,
          current: infoData?.complited_leads_fee?.current || 0,
          difference: infoData?.complited_leads_fee?.difference || 0,
          percent: infoData?.complited_leads_fee?.percent || 0,
        },
        complited_leads_fee_avg: {
          past: infoData?.complited_leads_fee_avg?.past || 0,
          current: infoData?.complited_leads_fee_avg?.current || 0,
          difference: infoData?.complited_leads_fee_avg?.difference || 0,
          percent: infoData?.complited_leads_fee_avg?.percent || 0,
        },
        completed_leads: {
          past: infoData?.completed_leads?.past || 0,
          current: infoData?.completed_leads?.current || 0,
          difference: infoData?.completed_leads?.difference || 0,
          percent: infoData?.completed_leads?.percent || 0,
        },
        news_views: {
          past: infoData?.news_views?.past || 0,
          current: infoData?.news_views?.current || 0,
          difference: infoData?.news_views?.difference || 0,
          percent: infoData?.news_views?.percent || 0,
        },
        news_likes: {
          past: infoData?.news_likes?.past || 0,
          current: infoData?.news_likes?.current || 0,
          difference: infoData?.news_likes?.difference || 0,
          percent: infoData?.news_likes?.percent || 0,
        },
        complited_leads_avg_price: {
          past: infoData?.complited_leads_avg_price?.past || 0,
          current: infoData?.complited_leads_avg_price?.current || 0,
          difference: infoData?.complited_leads_avg_price?.difference || 0,
          percent: infoData?.complited_leads_avg_price?.percent || 0,
        },
        complited_leads_avg_size: {
          past: infoData?.complited_leads_avg_size?.past || 0,
          current: infoData?.complited_leads_avg_size?.current || 0,
          difference: infoData?.complited_leads_avg_size?.difference || 0,
          percent: infoData?.complited_leads_avg_size?.percent || 0,
        },

        premise_basket_links_count: {
          past: infoData?.premise_basket_links_count?.past || 0,
          current: infoData?.premise_basket_links_count?.current || 0,
          difference: infoData?.premise_basket_links_count?.difference || 0,
          percent: infoData?.premise_basket_links_count?.percent || 0,
        },
        event_invitations: {
          past: infoData?.event_invitations?.past || 0,
          current: infoData?.event_invitations?.current || 0,
          difference: infoData?.event_invitations?.difference || 0,
          percent: infoData?.event_invitations?.percent || 0,
        },
      } as MainAnalyticsInfoType);
    } else {
      const infoData = data?.data as PersonalAnalyticsInfo;
      setInfo({
        clients: {
          past: infoData?.completed_leads?.past || 0,
          current: infoData?.completed_leads?.current || 0,
          difference: infoData?.completed_leads?.difference || 0,
          percent: infoData?.completed_leads?.percent || 0,
        },
        completed_leads: {
          past: infoData?.completed_leads?.past || 0,
          current: infoData?.completed_leads?.current || 0,
          difference: infoData?.completed_leads?.difference || 0,
          percent: infoData?.completed_leads?.percent || 0,
        },
        complited_leads_avg_price: {
          past: infoData?.complited_leads_avg_price?.past || 0,
          current: infoData?.complited_leads_avg_price?.current || 0,
          difference: infoData?.complited_leads_avg_price?.difference || 0,
          percent: infoData?.complited_leads_avg_price?.percent || 0,
        },
        complited_leads_avg_size: {
          past: infoData?.complited_leads_avg_size?.past || 0,
          current: infoData?.complited_leads_avg_size?.current || 0,
          difference: infoData?.complited_leads_avg_size?.difference || 0,
          percent: infoData?.complited_leads_avg_size?.percent || 0,
        },
      } as PersonalAnalyticsInfo);
    }
  }, [data]);

  const formatedData = Object.entries(info || {});

  const infoDataByRole1 = formatedData.slice(0, 2);

  const infoDataByRole = formatedData.slice(2);

  const hardCodeData = roles.isAdmin ? infoCartData : personalInfoCardData;

  return (
    <div
      className={`grid grid-cols-2 lg:grid-cols-4 gap-[10px] -order-1 lg:order-2 ${
        roles.isAdmin
          ? "grid-cols-2 lg:grid-cols-4 -order-1 lg:order-2"
          : "grid-cols-2 lg:grid-col-3 xl:grid-col-4 2xl:grid-cols-5"
      }`}
    >
      {infoDataByRole1.map(([name, item], i) => (
        <AnaliticsInfoCart
          key={`${name}` + i}
          title={hardCodeData[name as keyof KeyOFObj].title}
          icons={hardCodeData[name as keyof KeyOFObj].icons}
          item={item}
          isrewardInfo={true}
        />
      ))}
      {roles.isAdmin && statusLeads?.data.length ? (
        <div className="rounded-lg col-span-2 bg-white-default flex flex-col items-center sm:items-start sm:flex-row  sm:gap-[30px] p-[15px] ">
          <h3 className="text-accent-default block sm:hidden text-[16px] font-medium">
            Статус сделок
          </h3>

          <div>
            <PieChartComponent data={statusLeads?.data} />
          </div>
          <div className="flex flex-col mt-[10px]  sm:mt-0 gap-[10px] ">
            <h3 className="text-accent-default hidden sm:block text-[16px] font-medium">
              Статус сделок
            </h3>
            <div className="flex gap-[10px] justify-center sm:justify-start flex-wrap">
              {statusLeads?.data?.map((item, i) => (
                <div key={i} className="flex items-center justify-center gap-2">
                  <div
                    className={`w-[18px] h-[18px] rounded-full bg-[${setColorByStatus(
                      item.status
                    )}]`}
                  ></div>
                  <p className=" text-sm text-accent-default">{item.status}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}

      {infoDataByRole.map(([name, item], i) => (
        <AnaliticsInfoCart
          key={`${name}` + i}
          title={hardCodeData[name as keyof KeyOFObj].title}
          icons={hardCodeData[name as keyof KeyOFObj].icons}
          item={item}
        />
      ))}
      {roles.isAdmin && (
        <AnaliticsInfoCart
          title={hardCodeData["leads_count" as keyof KeyOFObj].title}
          icons={hardCodeData["leads_count" as keyof KeyOFObj].icons}
          item={leadsCount?.data}
          isDropDown={true}
          selectStep={selectStep}
          setSelectStep={setSelectStep}
        />
      )}
    </div>
  );
};

export default AnalyticsMainInfoList;
