import { Primise } from "enums/primisesType";
import { Deal } from "./type";
import { DealStatus, DealSteps } from "enums/deals";

export const dealsOptions = [
  { value: "", label: "Все" },
  { value: DealSteps.OPEN, label: "Открыть" },
  { value: DealSteps.INTEREST_IN_PURCHASING, label: "Интерес к покупке" },
  { value: DealSteps.PRESENTATION, label: "Презентация" },
  { value: DealSteps.BOOKED, label: "Бронь" },
  { value: DealSteps.REQUEST_FOR_CONTRACT, label: "Заявка на договор" },
  {
    value: DealSteps.CONTRACT_IS_REGISTERED,
    label: "Договор зарегистрирован",
  },
  {
    value: DealSteps.WON,
    label: "Выиграна",
  },
];

export const categorizeDeals = (
  data: Deal[],
  checked: boolean
): Record<DealStatus, Deal[]> => {
  return data?.reduce((acc, deal) => {
    acc[deal.state] = acc[deal.state] || [];
    acc[deal.state].push(deal);

    return acc;
  }, {} as Record<DealStatus, Deal[]>);
};

export function sortAndFilterArray(arr: string[]): string[] {
  const referenceArr = [
    DealStatus.ACTIVE,
    DealStatus.PAUSE,
    DealStatus.LOST,
    DealStatus.WIN,
  ];
  return referenceArr.filter((item) => arr.includes(item));
}

export interface Option {
  label: string;
  value: string | number;
}
