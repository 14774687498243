import React, { useEffect, useState } from "react";

import ButtonForm from "../../components/Button/ButtonForm";
import Close from "../../components/constant/icons/Close";
import Modal from "../../components/Modal";
import ImgUpload from "../../components/Form/ImgUpload";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import Label from "../../components/Form/Label";
import TextField from "../../components/Form/TextField/TextField";
import ToggleSwitcher from "../../components/ToggleSwitcher";
import { Editor } from "../../components/Form/Editor";
import { useWindowSize } from "../../hooks/useWindowSize";
import CloseWithBorderIcon from "../../components/constant/icons/CloseWithBorderIcon";
import {
  useCreateNewsMutation,
  useGetNewsCategoriesQuery,
  useUpdateNewsMutation,
} from "data/api/news";
import { useFormatForDropDown } from "hooks/useDropDownOption";
import { News, NewsCategories, NEWSRequest } from "data/api/news/type";
import { useGetCitiesQuery } from "data/api/city";
import { useUploadFileMutation } from "data/api/file";
import { roleOptions, UserRole } from "enums/users";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { cityItem } from ".";
import showToast from "utils/showToast";

interface NewsCreateEditModalData {
  isOpen: boolean;
  setIsOpen: (isDelete: boolean) => void;
  selected?: News | null;
  setSelect?: (value: any) => void;
}

interface SelectData {
  label: string;
  value: string;
}

const NewsCreateEditModal = ({
  isOpen,
  setIsOpen,
  selected,
  setSelect,
}: NewsCreateEditModalData) => {
  const { isSm } = useWindowSize();

  const selection = useSelector((state: RootState) => state);

  const [selectedImg, setSelectedImg] = useState<string>("");

  const [titleValue, setTitleValue] = useState("");

  const [textValue, setTextValue] = useState("");

  const [selectCity, setSelectCity] = useState<cityItem | null>(null);

  const [selectRole, setSelectRole] = useState<
    | {
        label: string;
        value: UserRole | null;
      }
    | null
    | undefined
  >(null);
  const [selectCategory, setSelectCategory] = useState<SelectData[]>([]);

  const [isToggle, setIsToggle] = useState(false);

  const [isToggleLike, setIsToggleLike] = useState(false);

  const [isPublication, setIsPublication] = useState(false);

  const [fileMutate] = useUploadFileMutation();

  const { data: newsCategory } = useGetNewsCategoriesQuery();

  const { data: cities } = useGetCitiesQuery();

  const [createNewsMutate] = useCreateNewsMutation();

  const [updateNewsMutate] = useUpdateNewsMutation();

  const [loading, setLoading] = useState(false);

  const optionsCategories = useFormatForDropDown<NewsCategories>(
    newsCategory?.data ?? []
  );
  const optionsCities = useFormatForDropDown<NewsCategories>(
    cities?.data ?? []
  );

  const isDisabledNext = () => {
    return !(
      selectedImg &&
      titleValue &&
      textValue &&
      selectCity !== null &&
      selectRole !== null
    );
  };

  useEffect(() => {
    if (!selected && isOpen) {
      const city = optionsCities.find(
        (item) => item.value === selection.header.city_id
      );
      setSelectCity(city as cityItem);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selected) {
      setSelectedImg(selected?.cover_image || "");
      setTitleValue(selected.title || "");
      setTextValue(selected.content || "");
      setIsToggle(selected.is_banner || false);
      setIsPublication(selected.is_draft || false);
      setIsToggleLike(selected.is_like_enabled || false);
      const category = [];
      if (selected?.primary_category_id) {
        category.push({
          value: selected?.primary_category_id + "",
          label: selected?.primary_category?.name ?? "",
        });
      }
      if (selected?.second_category_id) {
        category.push({
          value: selected?.second_category_id + "",
          label: selected?.secondary_category?.name ?? "",
        });
      }
      setSelectCategory(category);
      const role = roleOptions.find((item) => item.value === selected.access);
      const city = optionsCities.find(
        (item) => item.value === selected?.city_id
      );
      setSelectCity(city as cityItem);
      setSelectRole({
        value: role?.value ?? null,
        label: role?.label ?? "Все",
      });
    }
  }, [selected]);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await fileMutate(formData).unwrap();
        const fileName = response.data.filename;
        setSelectedImg(fileName);
      } catch (error) {
        console.error("File upload failed", error);
      }
    }
  };

  function handleRemoveCategory(key: string) {
    const filterList = selectCategory.filter((text) => text.value !== key);
    setSelectCategory(filterList);
  }

  async function handleSave() {
    if (selected) {
      handleUpdate();
      return;
    }
    setLoading(true);
    const { data: res, error } = await createNewsMutate({
      title: titleValue,
      content: textValue,
      cover_image: selectedImg,
      primary_category_id: +selectCategory[0]?.value,
      second_category_id: +selectCategory[1]?.value,
      is_like_enabled: isToggleLike,
      access: selectRole?.value || undefined,
      is_banner: isToggle,
      is_draft: isPublication,
      city_id: Number(selectCity?.value),
    });

    if (res?.data) {
      showToast("Новости успешно создано!", "success", 2000);
      handleCloseModal();
      setLoading(false);
      resetForm();
      setIsOpen(true);
      return;
    }
    if (error?.message) {
      showToast(error?.message, "error", 2000);
      setLoading(false);
      return;
    }
  }

  async function handleUpdate() {
    setLoading(true);
    if (selected?.id) {
      const { data: res, error } = await updateNewsMutate({
        data: {
          title: titleValue,
          content: textValue,
          cover_image: selectedImg,
          primary_category_id: +selectCategory[0]?.value,
          second_category_id: +selectCategory[1]?.value,
          is_like_enabled: isToggleLike,
          access: selectRole?.value || null,
          is_banner: isToggle,
          is_draft: isPublication,
          city_id: Number(selectCity?.value),
        },
        id: selected.id,
      });

      if (res?.data) {
        showToast("Новости успешно создано!", "success", 2000);
        handleCloseModal();
        setLoading(false);
        resetForm();
        setIsOpen(true);
        return;
      }
      if (error?.message) {
        showToast(error?.message, "error", 2000);
        setLoading(false);
        return;
      }
    }
  }

  function handlePreview() {
    const select: NEWSRequest = {
      id: selected?.id,
      cover_image: selectedImg,
      title: titleValue,
      content: textValue,
      primary_category_id: Number(selectCategory[0]?.value) ?? null,
      primary_category: { name: selectCategory[0]?.label },
      is_like_enabled: isToggleLike,
      date: new Date(),
      access: selectRole?.value || null,
      city_id: Number(selectCity?.value),
      is_banner: isToggle,
      is_draft: isPublication,
    };
    if (selectCategory[1]?.value) {
      select.second_category_id = Number(selectCategory[1].value);
      select.secondary_category = { name: selectCategory[1]?.label };
    }

    setSelect && setSelect(select);

    setIsOpen(false);
  }

  const handleSelectCategories = (value: SelectData) => {
    if (!value.value) {
      setSelectCategory([...optionsCategories] as SelectData[]);

      return;
    }

    const isExist = selectCategory.findIndex(
      (item) => item.value == value.value
    );

    if (selectCategory?.length < 2 && isExist === -1 && value.value) {
      setSelectCategory((prev) => [...(prev || []), value]);
    }
  };

  function resetForm() {
    setSelectedImg("");
    setTitleValue("");
    setTextValue("");
    setSelectCity(null);
    setSelectCategory([]);
    setIsToggle(false);
    setIsToggleLike(false);
    setIsPublication(false);
    setSelectRole(null);
  }

  const handleCloseModal = () => {
    setIsOpen(true);
    resetForm();
  };

  return (
    <Modal
      isOpen={isOpen}
      className="md:w-[672px] w-full rounded-lg "
      onClose={() => handleCloseModal()}
    >
      <div className="overflow-auto toolbar-scroll max-h-[80vh] pr-3">
        <div className={"flex md:justify-start justify-center relative"}>
          <div
            className={
              "flex md:justify-start justify-center items-center gap-[10px] w-full"
            }
          >
            <h1
              className={"font-extrabold text-[20px] font-dewi md:max-w-[80%]"}
            >
              {selected ? "Редактирование" : "Создание"} новости
            </h1>

            {selected?.is_draft && (
              <p className={"text-sm font-medium text-[#DB9E00] font-roboto"}>
                Черновик
              </p>
            )}
          </div>

          {!isSm && (
            <div className={"absolute right-0"}>
              <ButtonForm
                text={<CloseWithBorderIcon color={"black"} />}
                className={"p-0 px-[0px!important]"}
                onClick={() => handleCloseModal()}
              />
            </div>
          )}
        </div>

        <div className={"py-[20px]"}>
          <ImgUpload
            title={"Обложка"}
            requirementText={
              "Добавьте изображение с разрешением минимум 800х377 px"
            }
            value={
              selectedImg
                ? `${process.env.REACT_APP_FILES_URL}/${selectedImg}`
                : ""
            }
            handleImageUpload={handleImageUpload}
          />

          <div className={"pt-[30px] pb-[20px]"}>
            <TextField
              value={titleValue}
              className="mb-[20px]"
              onChangeValue={setTitleValue}
              label={"Заголовок"}
              placeholder="Напишите заголовок новости"
              type="text"
              name="teg"
            />

            <Label label="Описание" className={"w-full"}>
              <Editor value={textValue} onChangeValue={setTextValue} />
            </Label>
          </div>

          <div className={" flex flex-col gap-[15px]"}>
            <Label label="Роль" className={"w-full"}>
              <Dropdown
                value={selectRole}
                onChange={setSelectRole}
                options={[{ label: "Все", value: "" }, ...roleOptions]}
                placeholder="Выберите pоль"
                className="cursor-pointer"
              />
            </Label>
            <Label label="Город" className={"w-full"}>
              <Dropdown
                value={selectCity}
                onChange={setSelectCity}
                options={[...optionsCities]}
                placeholder="Выберите город"
                className="cursor-pointer"
              />
            </Label>

            <div className={""}>
              <Label label="Категория" className={"w-full"}>
                <Dropdown
                  onChange={(value) => handleSelectCategories(value)}
                  options={[...optionsCategories]}
                  placeholder="Введите категорию"
                  className="cursor-pointer"
                />
              </Label>
              <div className={"flex flex-wrap gap-[10px] pt-[10px]"}>
                {selectCategory?.map((text, idx) => (
                  <div
                    key={`category-item-${text.value}`}
                    className={
                      "flex items-center bg-accent-default rounded-[14px] text-white-default p-[6px] pl-[16px] gap-[2px]"
                    }
                  >
                    {text.label}

                    <div
                      className="cursor-pointer"
                      onClick={() => handleRemoveCategory(text.value)}
                    >
                      <Close />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={"pt-[28px] flex flex-col gap-[15px]"}>
            <ToggleSwitcher
              label="Отображать в баннере"
              isToggle={isToggle}
              setIsToggle={setIsToggle}
            />
            <ToggleSwitcher
              label="Лайк"
              isToggle={isToggleLike}
              setIsToggle={setIsToggleLike}
            />
          </div>
          <hr className="my-3 text-boder-default" />
          <ToggleSwitcher
            label="Запланированная публикация"
            isToggle={isPublication}
            setIsToggle={setIsPublication}
          />
        </div>

        <div className={"flex gap-[10px] pt-[10px]"}>
          <ButtonForm
            text={"Предпросмотр"}
            className={
              "p-3 bg-bg-default text-accent-default justify-center font-medium w-full"
            }
            onClick={handlePreview}
            disabled={isDisabledNext()}
          />

          <ButtonForm
            text={selected ? "Сохранить" : "Создать"}
            className={
              "p-3 bg-accent-default text-white-default justify-center font-medium w-full"
            }
            onClick={handleSave}
            disabled={isDisabledNext() || loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NewsCreateEditModal;
