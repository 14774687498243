import { Primise } from "enums/primisesType";

interface Building {
  id?: number;
  created_at?: string;
  updated_at?: string;
  name: string;
  total_storage?: number | string;
  total_vacant_storage?: number | string;
  total_parking_space?: number | string;
  total_vacant_parking_space?: number | string;
  total_commercial?: number | string;
  total_vacant_commercial?: number | string;
  address?: string;
  number_of_floors?: number | string;
  photos?: string[];
}

interface Project {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  detailed_description: string;
  brief_description: string;
  photo: string;
  price: number;
  location: string;
  long: number | string;
  lat: number | string;
  link: number | string;
  end_date?: string;
}

interface Section {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  building_id: number;
}

export interface Apartment {
  similiarapartmentcount: number;
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  type: Primise;
  building: Building;
  building_id: number;
  price: string;
  size: string;
  status: string;
  number: string;
  floor: number;
  photo: string;
  rooms: number;
  photos: string[];
  title: string;
  end_date: string;
  mortagePayment: string;
  section: Section;
  section_id: number;
  is_booked: boolean;
  project: Project;
  project_id: number;
  premise_schema?: ApartmentSchema;
  is_sold: boolean;
  season: {
    created_at: "2024-08-09T15:11:56.232977+03:00";
    date: "2024-06-01";
    id: 3;
    season_name: "3";
    updated_at: "2024-08-09T15:11:56.232977+03:00";
    year: "2024";
  };
  purchase_option: string[];
}

export interface ApartmentSchema {
  id: number;
  sunrise_angle: number;
  schema_image: string;
}

type GroupedData = {
  [key: string]: Apartment[];
};

export const transformData = (
  data: Apartment[],
  key: "name" | "floor"
): GroupedData => {
  if (!data || data.length === 0) {
    return {};
  }

  const groupedData = data.reduce((acc: GroupedData, item: Apartment) => {
    let groupKey: string;

    if (key === "name") {
      groupKey = item.section?.name || "Секция";
    } else if (key === "floor") {
      groupKey = item.floor.toString();
    } else {
      throw new Error(`Key ${key} not found in data`);
    }

    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }
    acc[groupKey].push(item);
    return acc;
  }, {});

  const sortedData: GroupedData = {};
  Object.keys(groupedData).forEach((key) => {
    sortedData[key] = groupedData[key];
  });

  return sortedData;
};

export const groupByProjectName = (data: Apartment[]): GroupedData => {
  if (!data || data.length === 0) {
    return {};
  }

  return data.reduce((acc: GroupedData, item: Apartment) => {
    const projectName = item.project?.name ?? "Unknown Project";
    if (!acc[projectName]) {
      acc[projectName] = [];
    }
    acc[projectName].push(item);
    return acc;
  }, {});
};
