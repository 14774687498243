import { apiService } from "..";
import { CalendarData, VisitCancelRequestType, VisitRequestType } from "./type";
import { MetaData } from "../exception";

export const addTagTypes = ["calendar"] as const;

export const calendarApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getCalendar: build.query<
        MetaData<{ visits: CalendarData[]; events: CalendarData[] }>,
        string
      >({
        query: (param) => ({
          path: param ? `/calendar?city_id=${param}` : "/calendar",
        }),
        providesTags: ["calendar"],
      }),
      getWeekly: build.query<
        MetaData<{ visits: CalendarData[]; events: CalendarData[] }>,
        string
      >({
        query: (query) => ({
          path: `/calendar?${query}`,
        }),
        providesTags: ["calendar"],
      }),
      recordForShow: build.mutation<
        MetaData<{ statusCode: number }>,
        VisitRequestType
      >({
        query: (data) => ({
          path: `/visits`,
          body: { meta: {}, data },
          method: "POST",
        }),
        invalidatesTags: ["calendar"],
      }),
      cancelVisitsRecord: build.mutation<
        MetaData,
        MetaData<VisitCancelRequestType>
      >({
        query: ({ data }) => ({
          path: `/visits/${data.id}`,
          body: {
            meta: {},
            data: {
              note: data.params.data.note,
              status: data.params.data.status,
            },
          },
          method: "PUT",
        }),
        invalidatesTags: ["calendar"],
      }),
    }),
  });

export const {
  useGetCalendarQuery,
  useRecordForShowMutation,
  useCancelVisitsRecordMutation,
  useGetWeeklyQuery,
} = calendarApiHooks;
