
interface DateOption {
    label: string;
    value: Date;
  }
export const timeOptions = [
    {
      label: "10:00",
      value: "10:00",
    },
    {
      label: "10:30",
      value: "10:30",
    },
    {
      label: "11:00",
      value: "11:00",
    },
    {
      label: "11:30",
      value: "11:30",
    },
    {
      label: "12:00",
      value: "12:00",
    },
    {
      label: "12:30",
      value: "12:30",
    },
    {
      label: "13:00",
      value: "13:00",
    },
    {
      label: "13:30",
      value: "13:30",
    },
    {
      label: "14:00",
      value: "14:00",
    },
    {
      label: "14:30",
      value: "14:30",
    },
    {
      label: "15:00",
      value: "15:00",
    },
    {
      label: "15:30",
      value: "15:30",
    },
    {
      label: "16:00",
      value: "16:00",
    },
    {
      label: "16:30",
      value: "16:30",
    },
    {
      label: "17:00",
      value: "17:00",
    },
    {
      label: "17:30",
      value: "17:30",
    },
    {
      label: "18:00",
      value: "18:00",
    },
  ];

  export const dateOptions = (): DateOption[] => {
    const today = new Date();
    const dateOptions: DateOption[] = [];

    for (let i = 0; i < 7; i++) {
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + i);
      const day = String(futureDate.getDate()).padStart(2, "0");
      const month = String(futureDate.getMonth() + 1).padStart(2, "0");
      const year = futureDate.getFullYear();
      const formattedDate = `${day}.${month}.${year}`;

      dateOptions.push({
        label: formattedDate,
        value: futureDate,
      });
    }

    return dateOptions;
  };
