import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import ButtonForm from "components/Button/ButtonForm";
import NewsPageCard from "components/Cards/NewsPageCard";
import ArrowDropdown from "components/constant/icons/ArrowDropdown";
import EyeIcon from "components/constant/icons/EyeIcon";
import LikeIcon from "components/constant/icons/LikeIcon";
import BreadCrump from "components/BreadCrump";
import {
  useGetOneEventQuery,
  useGetRecommendedEventsQuery,
  useJoinToEventMutation,
  useToggleLikeMutation,
} from "data/api/event";
import Loader from "components/Loader";
import { formatDate } from "@fullcalendar/core";
import { formatPhoneNumber } from "hooks/useFormatInput";
import showToast from "utils/showToast";
import LikedIcon from "components/constant/icons/LikedIcon";
import { useRoles } from "hooks/useRoles";
import InvitedUsersModal from "./InvitedUsersModal";
import InviteUserToEventModal from "./InviteUserToEventModal";
import { useSelector } from "react-redux";
import { RootState } from "store";

const EventViewPage = () => {
  const navigate = useNavigate();
  const selector = useSelector((state: RootState) => state);
  const route = useParams();
  const roles = useRoles(null);

  const [isRecordedUserModalOpen, setIsRecordedUserModalOpen] = useState(false);
  const [isRecordAgentModalOpen, setIsRecordAgentModalOpen] = useState(false);

  const { data: events } = useGetRecommendedEventsQuery(
    `event_id=${route.id}&city_id=${selector.header.city_id}`
  );
  const { data, isLoading, refetch } = useGetOneEventQuery(Number(route.id));
  const [mutateToggleLike] = useToggleLikeMutation();
  const [joinToEventMutate] = useJoinToEventMutation();
  const event = data?.data;

  const handleToggleLike = async () => {
    await mutateToggleLike({ data: { event_id: event?.id } });
    refetch();
  };

  const handleJoinToEvent = async () => {
    const { error } = await joinToEventMutate({ data: { id: event?.id } });
    if (error?.message) {
      showToast(error.message, "error", 2000);
      return;
    }
    showToast("Вы успешно присоединились к мероприятию!", "success", 2000);
    refetch();
  };

  if (isLoading) {
    return (
      <div className=" relative min-h-[50vh]">
        <Loader />
      </div>
    );
  }

  return (
    <section className=" bg-bg-default relative">
      <div className="md:py-[30px] py-[10px] br-container flex gap-[20px] flex-col lg:flex-row">
        <div className="xl:w-[76%] lg:w-[70%] w-full">
          <div className={"p-[20px] relative bg-white-default rounded-lg"}>
            <div className="relative">
              <div
                className={"absolute hidden sm:block left-[20px] top-[20px]"}
              >
                <ButtonForm
                  leftIcon={
                    <div className={"rotate-90"}>
                      <ArrowDropdown color={"#37465B"} />
                    </div>
                  }
                  className="bg-white-default p-2 pr-[20px] text-accent-default flex gap-[0!important] rounded-full"
                  text="Назад"
                  onClick={() => navigate(-1)}
                />
              </div>
              <img
                src={process.env.REACT_APP_FILES_URL + "/" + event?.photo}
                width={988}
                height={463}
                crossOrigin="anonymous"
                className={"rounded-sm w-[988px]"}
              />
              <div
                onClick={handleToggleLike}
                className={`absolute  cursor-pointer w-[44px] h-[44px] rounded-full flex items-center justify-center bg-white-default bottom-[15px] right-[15px]`}
              >
                {event?.is_liked ? <LikedIcon /> : <LikeIcon />}
              </div>
            </div>

            <div className={"mt-[30px]"}>
              <div className="flex gap-[10px] text-[14px] leading-[18px] font-normal text-blue-default">
                <div className="px-[20px] py-[6px] rounded-[100px] bg-light-blue">
                  #{event?.type}
                </div>
              </div>

              <div className={"flex justify-between pt-[15px]"}>
                <h3 className="text-[24px] leading-[28px] text-accent-default font-semibold">
                  {event?.title}
                </h3>

                <p className={"text-sm text-accent-default"}>{event?.date}</p>
              </div>

              {event?.description ? (
                <div
                  className={"my-[30px] text-accent-default text-sm"}
                  dangerouslySetInnerHTML={{
                    __html: event.description,
                  }}
                />
              ) : null}

              <div className="flex flex-col sm:flex-row gap-[20px] sm:gap-[30px] text-accent-default mb-[30px]">
                <div className="flex-1 flex flex-col gap-[30px]">
                  <div className="flex flex-col gap-[10px] font-semibold">
                    <h3 className="text-[16px] ">Ведущий</h3>
                    <p className="text-sm">{event?.leader}</p>
                  </div>
                  <div className="flex flex-col gap-[10px] font-semibold">
                    <h3 className="text-[16px] ">Тип мероприятия</h3>
                    <p className="text-sm font-normal">{event?.format}</p>
                  </div>
                  <div className="flex flex-col gap-[10px] font-semibold">
                    <h3 className="text-[16px] ">Адрес</h3>
                    <p className="text-sm font-normal">{event?.location}</p>
                  </div>
                </div>
                <div className="flex-1 flex flex-col gap-[30px]">
                  {event?.contacts?.length ? (
                    <div className="flex flex-col gap-[10px] font-semibold">
                      <h3 className="text-[16px] ">Контакты</h3>
                      {event?.contacts.map((contact, i) => (
                        <p className="text-sm" key={i}>
                          {contact.fullname}
                          <span className="font-normal ml-[10px]">
                            +7 {formatPhoneNumber(contact.phone)}
                          </span>
                        </p>
                      ))}
                    </div>
                  ) : null}

                  <div className="flex flex-col gap-[10px] font-semibold">
                    <h3 className="text-[16px] ">
                      Максимальное кол-во участников
                    </h3>
                    <p className="text-sm font-normal">{event?.max_visitors}</p>
                  </div>
                  <div className="flex flex-col gap-[10px] font-semibold">
                    <h3 className="text-[16px] ">Дата проведения</h3>
                    <p className="text-sm font-normal">
                      12:22
                      <span className="ml-[10px]">
                        4/21 {formatDate(event?.start_time as string)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div
                className={
                  "flex flex-col-reverse gap-6  sm:flex-row justify-between"
                }
              >
                <div className="flex flex-col sm:flex-row gap-[10px]">
                  {!event?.is_joined && (
                    <ButtonForm
                      text="Записаться"
                      onClick={handleJoinToEvent}
                      className={
                        "max-h-[48px] h-full sm:max-w-[147px] px-6 py-3 w-full justify-center bg-accent-default text-white-default text-ellipsis whitespace-nowrap"
                      }
                    />
                  )}
                  {(roles.isAdmin || roles.isAffiliate_manager) && (
                    <>
                      <ButtonForm
                        text="Записать пользователя"
                        onClick={() => setIsRecordAgentModalOpen(true)}
                        className={
                          "max-h-[48px] h-full sm:max-w-[197px] px-6 py-3 w-full justify-center border border-accent-default bg-white-default text-accent-default hover:bg-accent-default hover:text-white-default text-ellipsis whitespace-nowrap"
                        }
                      />
                      <ButtonForm
                        text="Открыть записи"
                        onClick={() => setIsRecordedUserModalOpen(true)}
                        className={
                          "max-h-[48px] h-full sm:max-w-[147px] px-6 py-3 w-full justify-center border border-accent-default bg-white-default text-accent-default hover:bg-accent-default hover:text-white-default text-ellipsis whitespace-nowrap"
                        }
                      />
                    </>
                  )}
                </div>

                <div className="flex gap-[15px] items-center  text-[12px] text-accent-disabled font-medium leading-[16px]">
                  {(event?.views_count || event?.views_count === 0) && (
                    <div className="flex items-center justify-center gap-[8px]">
                      <EyeIcon color="#8E95A1" width="18" />
                      <span>{event?.views_count}</span>
                    </div>
                  )}
                  {(event?.likes_count || event?.likes_count === 0) && (
                    <div className="flex items-center justify-center gap-[8px]">
                      <LikeIcon />
                      <span>{event?.likes_count}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:w-[24%] lg:w-[30%] w-full flex flex-col gap-[10px] self-start mt-2 sm:mt-0">
          <div className="block sm:hidden">
            <BreadCrump title="Рекомендованные мероприятия" link="/events" />
          </div>
          <div>
            {events?.data?.length
              ? events.data
                  .slice(0, 5)
                  .map((item) => (
                    <NewsPageCard
                      key={`news-block-item-${item.id}`}
                      imageSrc={
                        process.env.REACT_APP_FILES_URL + "/" + item?.photo ||
                        ""
                      }
                      title={item.title}
                      watched={item.views_count}
                      likes={item.likes_count}
                      date={item.date || ""}
                      onClick={() => navigate(`/events/view/${item.id}`)}
                    />
                  ))
              : null}
          </div>
        </div>
      </div>
      {/* Action Modals */}
      <InviteUserToEventModal
        isRecordAgentModalOpen={isRecordAgentModalOpen}
        setIsRecordAgentModalOpen={setIsRecordAgentModalOpen}
        eventData={data?.data}
        refetch={refetch}
      />
      <InvitedUsersModal
        setIsRecordedUserModalOpen={setIsRecordedUserModalOpen}
        isRecordedUserModalOpen={isRecordedUserModalOpen}
        invitedUsers={event?.invited_users}
        refetch={refetch}
      />
    </section>
  );
};

export default EventViewPage;
