import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from "react";
import ArrowDropdown from "components/constant/icons/ArrowDropdown";
import { generateDateArray } from "../../utils/generateDate";
import moment from "moment";

interface Props {
  fullDate: string | null;
  setFullDate: Dispatch<SetStateAction<string | null>>;
}

const CalendarList = ({ fullDate, setFullDate }: Props) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [arrowDisable, setArrowDisable] = useState(true);

  const handleHorizantalScroll = (
    element: HTMLDivElement | null,
    speed: number,
    distance: number,
    step: number
  ): void => {
    if (!element) return;

    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const today = moment();
  const startMonth = today.month() + 1;
  const startYear = today.year();
  const endMonth = startMonth + 1;
  const endYear = today.year();

  const dateArray = useMemo(
    () => generateDateArray(startMonth, startYear, endMonth, endYear),
    [startMonth, startYear, endMonth, endYear]
  );

  return (
    <div className="relative mt-[15px] sm:pl-[38px] sm:pr-[35px] sm:mt-[20px]">
      <button
        className=" hidden absolute left-0 -translate-y-[30%] top-[50%] w-[36px] h-[36px] rounded-full sm:flex items-center justify-center rotate-90 bg-white-default hover:bg-bg-focus"
        onClick={() => {
          handleHorizantalScroll(elementRef.current, 25, 100, -10);
        }}
        disabled={arrowDisable}
      >
        <ArrowDropdown color="#37465B" />
      </button>
      <button
        className=" hidden absolute right-0 top-[50%] -translate-y-[30%] w-[36px] h-[36px] rounded-full sm:flex items-center justify-center -rotate-90 bg-white-default hover:bg-bg-focus"
        onClick={() => {
          handleHorizantalScroll(elementRef.current, 25, 100, 10);
        }}
      >
        <ArrowDropdown color="#37465B" />
      </button>
      <div
        className="relative
								before:content-[''] before:absolute
								before:bg-[linear-gradient(90deg,_#F4F4F4_0%,_#F4F4F400_100%)]
								before:top-[26px] before:left-0 before:bottom-[12px] before:w-[29px]  before:pointer-events-none  before:z-10
								after:content-[''] after:absolute
								after:bg-[linear-gradient(90deg,_#F4F4F400_0%,_#F4F4F4_100%)]
								after:top-[26px] after:right-0 after:bottom-[12px] after:w-[29px] after:pointer-events-none after:z-1"
      >
        <div
          className="flex items-center gap-4 no-scrollbar overflow-x-scroll sm:overflow-x-hidden pb-3"
          ref={elementRef}
        >
          {dateArray.map((month, idx) => (
            <div key={`month${idx}`} className="flex flex-col gap-[6px]">
              <h2 className="font-dewi text-sm sm:text-[18px] text-accent-default font-extrabold">
                {month.month}
              </h2>
              <div className="flex gap-[10px]">
                {month.dates.map((date) => (
                  <div
                    onClick={() => setFullDate(date.fullDate)}
                    key={date.id}
                    className={`h-[56px] bg-white-default cursor-pointer text-[16px] w-[40px] border  rounded flex flex-col items-center justify-center ${
                      date.weekday === "сб" || date.weekday === "вс"
                        ? "text-red-default"
                        : "text-accent-default"
                    } ${
                      date.fullDate === fullDate
                        ? "border-accent-disabled"
                        : "border-boder-default"
                    }`}
                  >
                    <h3>{date.date}</h3>
                    <p className="text-[12px] opacity-50 capitalize">
                      {date.weekday.slice(0, 3)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CalendarList;
