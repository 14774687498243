import React, { useEffect, useState } from "react";
import ButtonForm from "../../components/Button/ButtonForm";
import Modal from "../../components/Modal";
import { SearchField } from "../../components/Form/SearchField";
import DatePickerField from "../../components/Form/DatePicker/DatePickerField";
import Dropdown, {
  selectOption,
} from "../../components/Form/Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsOpenFilters,
  getIsOpenSearch,
  handleOpenFilters,
  handleOpenSearch,
  toggleNotificationFormModal,
} from "../../store/BaseClientsReducer";
import { PaginationType } from ".";
import Loader from "components/Loader";
import { formatPhoneNumber } from "hooks/useFormatInput";
import { FixingType } from "enums/clientFixingType";
import { ActiveClientToggler } from "./BaseClientsFilters";
import { ActiveClientTogglerComp } from "components/ToggleSwitcher/ActiveClientToggler";
import { motion } from "framer-motion";
import AnimationFrame from "components/AnimationFrame.tsx";
import { checkExpirationStatus } from "utils/checkdateExpirationDate";
import { expireDateColumn } from "../../components/Form/Table/utils";

interface BaseClientsMobile {
  items: any[];
  dateStartDefault?: string | null;
  dateEndDefault?: string | null;
  setIsOpenAnchoringModal?: () => void;
  paginationInfo: PaginationType | undefined;
  handleUpdateList: () => void;
  isLoading: boolean;
  handleLeftClickTable: (item: any) => void;
  handleChangeFields: (
    searchSelectItem: any,
    dateStart: Date,
    dateEnd: Date,
    selectSecond: any,
    activeClient: ActiveClientToggler
  ) => void;
}

const BaseClientsMobile = ({
  items,
  dateStartDefault = null,
  dateEndDefault = null,
  setIsOpenAnchoringModal,
  paginationInfo,
  handleUpdateList,
  isLoading,
  handleChangeFields,
  handleLeftClickTable,
}: BaseClientsMobile) => {
  const dispatch = useDispatch();
  const storeIsOpenFilters = useSelector(getIsOpenFilters);
  const storeIsOpenSearch = useSelector(getIsOpenSearch);

  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [isOpenDate, setIsOpenDate] = useState(false);

  const [searchSelectItem, setSearchSelectItem] = useState(null);
  const [activeClient, setActiveClientFilter] = useState<ActiveClientToggler>(
    ActiveClientToggler.ACTIVE
  );

  const [dateStart, setDateStart] = useState<Date | null>(
    dateStartDefault ? new Date(dateStartDefault) : null
  );
  const [dateEnd, setDateEnd] = useState<Date | null>(
    dateEndDefault ? new Date(dateEndDefault) : null
  );

  const [selectFastenings, setSelectFastenings] = useState<selectOption[]>();

  const [optionsFirst, setOptionsFirst] = useState<selectOption[]>([]);

  useEffect(() => {
    storeIsOpenFilters && setIsOpenFilters(storeIsOpenFilters);
  }, [storeIsOpenFilters]);

  useEffect(() => {
    storeIsOpenSearch && setIsOpenSearch(storeIsOpenSearch);
  }, [storeIsOpenSearch]);

  useEffect(() => {
    getSelectSecondList();
  }, []);

  function getSelectSecondList() {
    // $getList().then((response) => {
    const response = [
      { label: "Все", value: "" },
      {
        label: "Проверка лида",
        value: FixingType.LEAD_VERIFICATION,
      },
      {
        label: "Отказ в закреплении",
        value: FixingType.CENCEL_FIXING,
      },
      {
        label: "Слабое закрепление",
        value: FixingType.WEAK_FIXING,
      },
      {
        label: "Сильное закрепление",
        value: FixingType.STRONG_FIXING,
      },
    ];

    if (!response) return;

    setOptionsFirst(response);
  }

  function onChangeSearchField(value: any) {
    if (value) {
      dispatch(handleOpenSearch(false));
      setIsOpenSearch(false);
    }
    setSearchSelectItem(value);
  }

  const handleOpenInfoModal = (item: any) => {
    handleLeftClickTable(item);
  };

  const handleToggleNotificationForm = () => {
    dispatch(toggleNotificationFormModal());
  };

  useEffect(() => {
    handleChangeFields(
      searchSelectItem,
      dateStart as Date,
      dateEnd as Date,
      selectFastenings,
      activeClient
    );
  }, [searchSelectItem, dateStart, dateEnd, selectFastenings, activeClient]);

  console.log("item", items);

  return (
    <div className={"bg-bg-default min-h-[50vh]"}>
      <div className="flex items-center justify-center w-full mt-5">
        <ActiveClientTogglerComp
          activeClient={activeClient}
          setActiveClientFilter={setActiveClientFilter}
          hasTitle={false}
        />
      </div>

      <div className={"px-[15px] py-[20px] min-h-[30vh] relative"}>
        <ButtonForm
          text="Проверить закрепление"
          onClick={setIsOpenAnchoringModal}
          className={
            "w-full bg-accent-default hover:bg-accent-focus p-3 justify-center text-white-default mb-[10px]"
          }
        />
        <ButtonForm
          text={"Форма уведомления"}
          className={
            "w-full bg-accent-default hover:bg-accent-focus text-white-default p-3 justify-center"
          }
          onClick={handleToggleNotificationForm}
        />

        {isLoading && <Loader />}
        <AnimationFrame>
          {items?.length ? (
            <>
              <div className={"py-5"}>
                {items?.map((item) => (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.6 }}
                    key={item?.id}
                  >
                    <div
                      onClick={() => handleOpenInfoModal(item)}
                      className={
                        "flex justify-between p-[15px] mb-[10px] rounded-lg bg-white-default"
                      }
                    >
                      <div className={"left"}>
                        <p className={"mb-[5px] text-sm font-medium"}>
                          {item?.name}
                        </p>

                        <p className={"text-sm"}>
                          +7 {formatPhoneNumber(item?.phone)}
                        </p>
                      </div>

                      <div className={"right text-right "}>
                        <div
                          className={
                            "mb-[5px] text-sm font-medium text-blue-default"
                          }
                        >
                          {item?.fixing_type.key}
                        </div>

                        <p className={"text-sm "}>
                          {typeof item.datePin === "object"
                            ? expireDateColumn(item.datePin?.text)
                            : item.datePin}
                        </p>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>

              {paginationInfo
                ? !(paginationInfo?.currPage >= paginationInfo?.totalPage) && (
                    <button
                      type={"button"}
                      className={
                        "py-3 px-6 mx-auto text-blue-default text-sm font-medium flex mt-[15px]"
                      }
                      onClick={() => handleUpdateList()}
                    >
                      Загрузить еще
                    </button>
                  )
                : null}
            </>
          ) : (
            !isLoading && (
              <div className={"p-4 pt-6 flex justify-center"}>
                Список пустой
              </div>
            )
          )}
        </AnimationFrame>
      </div>

      <Modal
        isOpen={isOpenSearch}
        onClose={() => {
          dispatch(handleOpenSearch(false));
          setIsOpenSearch(false);
        }}
        className={"md:w-auto w-full rounded-t-[30px] sm:rounded-[8px]"}
      >
        <SearchField
          selectItem={searchSelectItem}
          onChange={onChangeSearchField}
          isShowBtnDelete={false}
          className={"md:rounded-[4px!important]"}
        />
      </Modal>

      <Modal
        isOpen={isOpenFilters}
        onClose={() => {
          dispatch(handleOpenFilters(false));
          setIsOpenFilters(false);
        }}
        className={"md:w-auto w-full rounded-t-[30px] sm:rounded-[8px] "}
      >
        <div
          className={`flex flex-col items-center max-h-[80vh] toolbar-scroll pr-3 ${
            isOpenDate ? "" : "overflow-y-auto"
          }`}
        >
          <h3 className="text-xl font-extrabold text-accent-default mb-[20px] font-dewi">
            Фильтр
          </h3>
          <div className="w-full">
            <SearchField
              selectItem={searchSelectItem}
              onChange={onChangeSearchField}
              isShowBtnDelete={true}
              className={" w-full rounded-[4px!important]"}
            />
          </div>

          <Dropdown
            placeholder={"Закрепление"}
            value={selectFastenings}
            options={optionsFirst}
            className={"mt-[10px] w-full"}
            onChange={setSelectFastenings}
          />
          <div className={"flex flex-col mt-[10px] gap-[10px] w-full"}>
            {/*<DatePickerField*/}
            {/*  className={"w-full"}*/}
            {/*  startDate={dateStart}*/}
            {/*  placeholder={"Закреплен до..."}*/}
            {/*  isIcon*/}
            {/*  onChangeDate={setDateStart}*/}
            {/*/>*/}

            <DatePickerField
							className={"w-full calendar-block"}
              startDate={dateEnd}
              placeholder={"Дата добавления"}
              isIcon
              isRightIcon
              isClearable
              onChangeDate={setDateEnd}
              setIsOpen={setIsOpenDate}
            />
          </div>

          <ButtonForm
            text="Применить"
            onClick={() => {
              dispatch(handleOpenFilters(false));
              setIsOpenFilters(false);
            }}
            className={
              "mt-[30px] max-h-[48px] px-6 w-full justify-center bg-accent-default text-white-default text-ellipsis whitespace-nowrap p-3"
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default BaseClientsMobile;
