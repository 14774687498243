import React, { useEffect, useState } from "react";
import TableContainer from "../../components/Form/Table";
import BaseClientsFilters, { ActiveClientToggler } from "./BaseClientsFilters";
import WarningIcon from "../../components/constant/icons/Table/WarningIcon";
import BaseClientsMobile from "./BaseClientsMobile";
import BaseClientsInfoModal from "./BaseClientsInfoModal";
import { useWindowSize } from "../../hooks/useWindowSize";
import BaseClientsAnchoringModal from "./BaseClientsAnchoringModal";
import {
  useFilterBaseClientsListMutation,
  useGetClientByIdMutation,
} from "../../data/api/base-clients";
import BaseClientFormNotification from "./BaseClientFormNotification";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  toggleBaseClientInfoModal,
  toggleNotificationFormModal,
} from "store/BaseClientsReducer";
import { FixingType } from "enums/clientFixingType";
import AnimationFrame from "components/AnimationFrame.tsx";
import BaseClientsReClipModal from "./BaseClientsReClipModal";
import { useLocation, useNavigate } from "react-router";

interface FilterData {
  page: number;
  limit: number;
  client_id: number | null;
  phone_number?: string;
  project_id?: number;
  actived_from_date: Date | string | null;
  actived_to_date: Date | string | null;
  fixing_type: FixingType;
  state: ActiveClientToggler;
  sort_by?: string;
  order_by?: string;
}

export interface PaginationType {
  currPage: number;
  totalPage: number;
  limit: number;
  total: number;
}

export interface typeItem {
  label: string;
  value: string;
  color?: string;
}

const BaseClients = () => {
  const { isMd } = useWindowSize();

  const dispatch = useDispatch();

  const [mutate] = useFilterBaseClientsListMutation();

  const [getOneUserMutate] = useGetClientByIdMutation();

  const location = useLocation();

  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  const route_id = queryParams.get("id");

  const [headerTable, setHeaderTable] = useState([
    {
      key: "name",
      title: "ФИО",
      sortable: true,
    },
    {
      key: "phone",
      title: "Номер телефона",
    },
    {
      key: "comment",
      title: "Коментарий",
    },
    {
      key: "date",
      title: "Дата доб.",
      sortable: true,
    },
    {
      key: "fixing_type",
      title: "Закрепление",
      sortable: true,
    },
    {
      key: "datePin",
      title: "Срок закрепления",
      sortable: true,
    },
  ]);
  const [contentTable, setContentTable] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [updatePage, setUpdatePage] = useState<boolean>(false);

  const [paginationInfo, setPaginationInfo] = useState<
    PaginationType | undefined
  >({
    currPage: 0,
    limit: 0,
    total: 0,
    totalPage: 0,
  });

  const [filter, setFilter] = useState<FilterData>({
    page: 1,
    limit: 30,
    client_id: null,
    actived_from_date: null,
    actived_to_date: null,
    fixing_type: FixingType.ALL,
    state: ActiveClientToggler.ACTIVE,
  });
  const [clientInfoData, setClientInfoData] = useState<any>(null);

  const [isOpenAnchoringModal, setIsOpenAnchoringModal] =
    useState<boolean>(false);
  const [isOpenReClipModal, setIsOpenReClipModal] = useState<boolean>(false);

  const selection = useSelector((state: RootState) => state.baseClients);

  const filtersToQueryString = (filters: any) => {
    return Object.entries(filters)
      .filter(([key, value]) => value)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  };

  const formatDate = (dateString: string | Date) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`; // ${hours}:${minutes}`;
  };

  const clearRoute = () => {
    navigate("/base-clients", { replace: true });
  };

  const typePinList: typeItem[] = [
    {
      label: "сильное закрепление",
      value: "сильное закрепление",
    },
    {
      label: "слабое закрепление",
      value: "слабое закрепление",
    },
    {
      label: "отказ в закреплении",
      value: "отказ в закреплении",
    },
    {
      label: "проверка лида",
      value: "проверка лида",
    },
    {
      label: "",
      value: "",
    },
  ];

  const onChangeTableList = (item: any): any => {
    // const searchTypePin = typePinList.find((i) => i.value === item.fixing_type);
    const typeError = item.fixing_type === "отказ в закреплении";

    return {
      ...item,
      name: item.fullname,
      phone: item.phone_number,
      date: formatDate(item.actived_date),
      fixing_type: {
        key: item.fixing_type,
        text: (
          <div className={`flex text-center gap-2`}>
            {typeError && <WarningIcon color={"#C93B29"} />}

            <p style={{ color: typeError ? "#C93B29" : "" }}>
              {/* {searchTypePin ? searchTypePin.label : ""} */}
              {item.fixing_type}
            </p>
          </div>
        ),
      },
      datePin: {
        key: item.expiration_date,
        text: formatDate(item.expiration_date),
      },
    };
  };

  useEffect(() => {
    setIsLoading(true);
    loadList();
  }, [filter]);

  useEffect(() => {
    if (route_id) {
      getOneUser();
    }
  }, [route_id]);

  async function getOneUser() {
    const { data: res } = await getOneUserMutate(Number(route_id));
    if (res?.data) {
      const updateList = onChangeTableList(res.data);
      handleLeftClickTable(updateList);
    }
  }

  async function loadList() {
    const queryString = filtersToQueryString(filter);
    const { data: res } = await mutate(queryString);
    setIsLoading(false);
    if (!res || !res.data) return;

    setPaginationInfo(res.meta?.links);

    const updateList = res.data.map((item) => onChangeTableList(item));

    if (updatePage) {
      setUpdatePage(false);
      setContentTable((list) => [...list, ...updateList]);

      return;
    }

    setContentTable(updateList);
  }

  async function handleUpdateList() {
    setUpdatePage(true);
    const filterUpdate = {
      ...filter,
      page: filter.page + 1,
    };

    setFilter(filterUpdate);
  }

  const getFormDate = (date: Date): string | null => {
    if (!date) return null;

    let utcTime = date.getTime() - date.getTimezoneOffset() * 60000;

    let targetDate = new Date();

    targetDate.setTime(utcTime);

    return targetDate.toISOString();
  };

  function handleChangeFields(
    searchSelectItem: any,
    dateStart: Date,
    dateEnd: Date,
    selectFixingType: any,
    activeClient: ActiveClientToggler
  ) {
    setFilter({
      ...filter,
      page: 1,
      client_id: searchSelectItem?.id,
      actived_from_date: getFormDate(dateStart),
      actived_to_date: getFormDate(dateEnd),
      fixing_type: selectFixingType?.value,
      state: activeClient,
    });
  }

  function handleSortBy(sort_by: string, order_by: string) {
    setFilter({
      ...filter,
      page: 1,
      sort_by,
      order_by,
    });
  }

  function handleLeftClickTable(e: any) {
    setClientInfoData(() => {
      let updateItem: any = {
        // collapse: e.collapse
      };

      Object.keys(e).map((item) => {
        updateItem = {
          ...updateItem,
          info: {
            ...updateItem.info,
            [`${item}`]: e[item],
          },
        };
      });

      return updateItem;
    });

    if (e?.status === "Завершено") {
      setIsOpenReClipModal(true);

      return;
    }

    handleToggleBaseClientInfoModal(e?.id);
  }

  const handleToggleBaseClientInfoModal = (id: number) => {
    dispatch(toggleBaseClientInfoModal(id));
    if (route_id && selection.isOpenBaseClientModal === clientInfoData?.info.id)
      clearRoute();
  };

  const handleToggleNotificationForm = () => {
    dispatch(toggleNotificationFormModal());
    clientInfoData?.info &&
      handleToggleBaseClientInfoModal(clientInfoData.info.id);
  };

  return (
    <main className={"bg-bg-default"}>
      {!isMd ? (
        <div className="br-container pt-[20px] pb-[30px] min-h-[50vh]">
          <BaseClientsFilters
            typePinList={typePinList}
            handleChangeFields={handleChangeFields}
            setIsOpenAnchoringModal={() => setIsOpenAnchoringModal(true)}
            setIsOpenFormNotification={() => {
              setClientInfoData(null);
              handleToggleNotificationForm();
            }}
          />
          <AnimationFrame>
            <div className={"pb-[15px] bg-white-default rounded-lg"}>
              <TableContainer
                handleSortBy={handleSortBy}
                header={headerTable}
                content={contentTable}
                setData={setContentTable}
                onLeftClickTrContent={handleLeftClickTable}
                loading={isLoading}
                sortable={false}
                textContentEmpty={"Список пустой"}
              />
            </div>
          </AnimationFrame>

          {paginationInfo
            ? !(paginationInfo?.currPage >= paginationInfo?.totalPage) && (
                <button
                  type={"button"}
                  className={
                    "py-3 px-6 mx-auto text-blue-default text-sm font-medium flex mt-[15px]"
                  }
                  onClick={() => handleUpdateList()}
                >
                  Загрузить еще
                </button>
              )
            : null}
        </div>
      ) : (
        <BaseClientsMobile
          handleChangeFields={handleChangeFields}
          items={contentTable}
          setIsOpenAnchoringModal={() => setIsOpenAnchoringModal(true)}
          paginationInfo={paginationInfo}
          handleUpdateList={handleUpdateList}
          isLoading={isLoading}
          handleLeftClickTable={handleLeftClickTable}
        />
      )}

      {clientInfoData && (
        <BaseClientsInfoModal
          isOpen={selection.isOpenBaseClientModal === clientInfoData?.info.id}
          toggleInfoModal={handleToggleBaseClientInfoModal}
          selection={clientInfoData}
          refetch={loadList}
          resetData={() => setClientInfoData(null)}
        />
      )}

      <BaseClientsAnchoringModal
        isOpen={isOpenAnchoringModal}
        setIsOpen={setIsOpenAnchoringModal}
        handleToggle={() =>
          handleToggleBaseClientInfoModal(clientInfoData.info.id)
        }
      />

      <BaseClientFormNotification
        client={
          clientInfoData?.info
            ? {
                name: clientInfoData?.info?.name,
                phone: clientInfoData?.info?.phone,
              }
            : undefined
        }
        isOpen={selection.isNotificationModalOpen}
        handleToggle={handleToggleNotificationForm}
      />

      <BaseClientsReClipModal
        isOpen={isOpenReClipModal}
        setIsOpen={setIsOpenReClipModal}
      />
    </main>
  );
};

export default BaseClients;
