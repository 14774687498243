import React, {
  useState,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";

import Label from "components/Form/Label";
import TextField from "components/Form/TextField/TextField";
import PhoneIcon from "components/constant/icons/PhoneIcon";
import DatePickerTimeField from "components/Form/DatePicker/DatePickerTimeField";
import ClockIcon from "components/constant/icons/ClockIcon";
import CancelIcon from "components/constant/icons/CancelIcon";
import PlusIcon from "components/constant/icons/PlusIcon";
import { IContact, WorkSchedule } from "data/api/contact/type";
import Dropdown from "components/Form/Dropdown/Dropdown";
import { getCityByDaData, useGetCitiesQuery } from "data/api/city";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import ArrowDropdown from "components/constant/icons/ArrowDropdown";
import moment from "moment";
import { useDispatch } from "react-redux";
import { handleDeleteContact } from "store/ContactReducer";
import MaskComponent from "components/MaskInput/MaskComponent";

interface IProps {
  inputsValue: IContact;
  setInputValue: Dispatch<SetStateAction<IContact[]>>;
  index: number;
}

export interface AddressDetail {
  title: string;
  lat: number;
  long: number;
}

const ContactEditForm: React.FC<IProps> = ({
  inputsValue,
  setInputValue,
  index,
}) => {
  const { data: city } = useGetCitiesQuery();

  const [debouncedValue, setDebouncedValue] = useState(
    inputsValue.address.title
  );
  const [addressDetails, setAddressDetails] = useState<AddressDetail[]>([]);

  const [isResultsVisible, setIsResultsVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputsValue.address.title);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [inputsValue.address.title]);

  useEffect(() => {
    if (debouncedValue) {
      handleSearchAddress(debouncedValue);
    }
  }, [debouncedValue]);

  async function handleSearchAddress(query: string) {
    const res = await getCityByDaData(query);

    const formattedData: AddressDetail[] = res.map((item) => ({
      title: item.value,
      lat: item.data.geo_lat,
      long: item.data.geo_lon,
    }));

    setAddressDetails(formattedData);
  }

  const optionsCity = useFormatForDropDownOptions(city?.data ?? []);

  const weekDay: Record<WorkSchedule["weekday"], string> = {
    0: "Пн",
    1: "Вт",
    2: "Ср",
    3: "Чт",
    4: "Пт",
    5: "Сб",
    6: "Вс",
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = e.target;

    if (name === "address") {
      setIsResultsVisible(true);
    }

    setInputValue((prev) =>
      prev.map((input, i) => {
        if (i === index) {
          if (name === "address") {
            return {
              ...input,
              ref_id: input.ref_id?.startsWith("new-")
                ? input.ref_id
                : `old-${Date.now()}`,
              [name]: {
                ...input[name],
                title: value,
              },
            };
          } else {
            return {
              ...input,
              ref_id: input.ref_id?.startsWith("new-")
                ? input.ref_id
                : `old-${Date.now()}`,
              [name]: value,
            };
          }
        }
        return input;
      })
    );
  };

  const handleWorkingHoursChange = (
    index: number,
    weekday: number | null,
    timeType: "start_time" | "end_time",
    selectedTime: Date | null
  ) => {
    setInputValue((prev) =>
      prev.map((input, i) =>
        i === index
          ? {
              ...input,
              ref_id: input.ref_id?.startsWith("new-")
                ? input.ref_id
                : `old-${Date.now()}`,
              work_schedule: input.work_schedule.map((schedule) =>
                schedule.weekday === weekday
                  ? {
                      ...schedule,
                      [timeType]: selectedTime
                        ? moment(selectedTime).format("HH:mm")
                        : null,
                    }
                  : schedule
              ),
            }
          : input
      )
    );
  };

  const handlePhoneChange = (
    index: number,
    phoneIndex: number,
    value: string
  ) => {
    setInputValue((prev) =>
      prev.map((input, i) =>
        i === index
          ? {
              ...input,
              ref_id: input.ref_id?.startsWith("new-")
                ? input.ref_id
                : `old-${Date.now()}`,
              phone_number: input.phone_number.map((phone, pIndex) =>
                pIndex === phoneIndex ? value : phone
              ),
            }
          : input
      )
    );
  };

  const removePhoneNumber = (index: number, phoneIndex: number) => {
    setInputValue((prev) =>
      prev.map((input, i) =>
        i === index
          ? {
              ...input,
              ref_id: input.ref_id?.startsWith("new-")
                ? input.ref_id
                : `old-${Date.now()}`,
              phone_number: input.phone_number.filter((_, pIndex) =>
                inputsValue.phone_number.length > 1
                  ? pIndex !== phoneIndex
                  : input.phone_number
              ),
            }
          : input
      )
    );
  };

  const addPhoneNumber = (index: number) => {
    setInputValue((prev) =>
      prev.map((input, i) =>
        i === index
          ? {
              ...input,
              ref_id: input.ref_id?.startsWith("new-")
                ? input.ref_id
                : `old-${Date.now()}`,
              phone_number: [...input.phone_number, ""],
            }
          : input
      )
    );
  };

  const onChageCity = (e: { label: string; value: number }) => {
    setInputValue((prev) =>
      prev.map((input, i) =>
        i === index
          ? {
              ...input,
              ref_id: input.ref_id?.startsWith("new-")
                ? input.ref_id
                : `old-${Date.now()}`,
              city_id: e.value,
            }
          : input
      )
    );
  };

  const handleAddressSelect = (item: AddressDetail) => {
    setInputValue((prev) =>
      prev.map((input, i) => {
        if (i === index) {
          return {
            ...input,
            ref_id: input.ref_id?.startsWith("new-")
              ? input.ref_id
              : `old-${Date.now()}`,
            address: {
              id: input.address.id,
              title: item.title,
              lat: String(item.lat),
              long: String(item.long),
            },
          };
        }
        return input;
      })
    );
    setIsResultsVisible(false);
  };

  function convertTimeStringToDate(timeString: Date | undefined): Date | null {
    if (!timeString) {
      return null;
    }

    const currentDate = moment();

    const dateTimeString = `${currentDate.format("YYYY-MM-DD")}T${timeString}`;

    const dateTimeMoment = moment(dateTimeString);

    return dateTimeMoment.toDate();
  }

  function deleteForm() {
    if (inputsValue.ref_id && inputsValue.ref_id.startsWith("new-")) {
      setInputValue((prev) => prev.filter((input, i) => i !== index));
    } else {
      setInputValue((prev) => prev.filter((input, i) => i !== index));
      dispatch(handleDeleteContact(inputsValue.id));
    }
  }

  const getValue = (
    opts: { label: string; value: string | number }[],
    val: number | string | undefined
  ) => {
    if (val) {
      return opts.filter((o) => o.value == val);
    }
  };

  return (
    <div className="w-[406px] flex flex-col gap-5 border border-dashed border-blue-default p-[15px] rounded-[4px] relative self-start">
      <button onClick={deleteForm} className="absolute top-3 right-3">
        <CancelIcon color="#EF3B24" />
      </button>
      <TextField
        value={inputsValue.title}
        placeholder="Заголовок"
        label="Заголовок"
        className="flex-1"
        name="title"
        onChange={(e) => handleChange(e, index)}
      />
      <Label label="Город">
        <Dropdown
          name="city_id"
          options={[{ value: "", label: "Все" }, ...optionsCity]}
          value={getValue(optionsCity, inputsValue.city_id)}
          onChange={onChageCity}
          placeholder="Город не выбран"
        />
      </Label>
      <div className="relative">
        <Label label="Город">
          <div className="relative">
            <input
              value={inputsValue.address.title}
              placeholder="address"
              onFocus={() => setIsResultsVisible(true)}
              className="flex-1 px-[16px] py-[12px] placeholder:text-accent-disabled  w-full border-[1px] focus:border-boder-focus border-boder-default outline-none placeholder:font-normal font-normal text-[14px] rounded-[4px]"
              name="address"
              onChange={(e) => handleChange(e, index)}
            />
            <div
              onClick={() => setIsResultsVisible(!isResultsVisible)}
              className={` absolute  cursor-pointer top-[50%] -translate-y-[50%] bg-white-default right-[2px] w-[30px] flex items-center justify-center  ${
                isResultsVisible ? "rotate-180" : ""
              }`}
            >
              <ArrowDropdown />
            </div>
          </div>
        </Label>
        {isResultsVisible && (
          <div className=" absolute w-full flex flex-col bg-white-default max-h-[200px] overflow-auto toolbar-scroll z-20 border border-boder-default shadow-md rounded-sm">
            {addressDetails.length ? (
              addressDetails?.map((item) => (
                <div
                  onClick={() => handleAddressSelect(item)}
                  className="w-full hover:bg-bg-default cursor-pointer text-sm text-accent-default py-3 px-4"
                >
                  {item.title}
                </div>
              ))
            ) : (
              <div
                onClick={() => setIsResultsVisible(false)}
                className="w-full hover:bg-bg-default cursor-pointer text-sm text-accent-default py-3 px-4"
              >
                Ничего не найдено
              </div>
            )}
          </div>
        )}
      </div>

      <TextField
        value={inputsValue.address_link}
        placeholder="url"
        className="flex-1"
        name="address_link"
        onChange={(e) => handleChange(e, index)}
      />

      <div className="flex flex-col gap-[10px]">
        <p className="text-sm text-accent-default mb-2">Пн - Вс 9:00-21:00</p>
        {inputsValue.work_schedule.map((day) => (
          <div key={day.weekday} className="flex gap-[10px]">
            <button
              className={`w-[58px] h-[47px] ${
                day.start_time && day.start_time
                  ? day.weekday === 5 || day.weekday === 6
                    ? "bg-red-default text-white-default"
                    : "bg-accent-default text-white-default"
                  : "border border-accent-default text-accent-default "
              }   outline-none rounded-[4px] text-[16px] font-medium`}
            >
              {weekDay[day.weekday]}
            </button>
            <DatePickerTimeField
              onChangeDate={(date) =>
                handleWorkingHoursChange(index, day.weekday, "start_time", date)
              }
              startDate={convertTimeStringToDate(day.start_time)}
              isIcon={<ClockIcon />}
            />
            {/* <div>
              <input type="text" />
            </div> */}
            <DatePickerTimeField
              onChangeDate={(date) =>
                handleWorkingHoursChange(index, day.weekday, "end_time", date)
              }
              startDate={convertTimeStringToDate(day.end_time)}
              isIcon={<ClockIcon />}
            />
          </div>
        ))}
      </div>
      {inputsValue.phone_number.map((phone, phoneIndex) => (
        <div key={phoneIndex} className="flex gap-4 ">
          <Label label="Телефон" className="w-full">
            <MaskComponent
              name="phone_number"
              imgLeft={<PhoneIcon />}
              value={phone}
              onChange={(value) => handlePhoneChange(index, phoneIndex, value)}
            />
          </Label>
          {/* <TextField
            placeholder="(909)"
            label="Телефон"
            type="tel"
            countryCode="+7"
            maxLength={15}
            value={formatPhoneNumber(phone)}
            imgLeft={<PhoneIcon />}
            name="phone_number"
            className="flex-1"
            onChange={(e) =>
              handlePhoneChange(index, phoneIndex, e.target.value)
            }
          /> */}
          <button
            className="mt-5"
            onClick={() => removePhoneNumber(index, phoneIndex)}
          >
            <CancelIcon color="#EF3B24" />
          </button>
        </div>
      ))}

      <button
        className="h-[48px] w-full rounded-lg flex items-center justify-center border border-dashed border-blue-default outline-none"
        onClick={() => addPhoneNumber(index)}
      >
        <PlusIcon width={28} height={28} color="#3583FF" />
      </button>
    </div>
  );
};

export default ContactEditForm;
