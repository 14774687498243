import { apiService } from "..";
import { MetaData } from "../exception";
import { Event, InviteUsersToAgencyRequestType } from "./type";

export const addTagTypes = ["event"] as const;

export const eventApiHooks = apiService
  .enhanceEndpoints({ addTagTypes })
  .injectEndpoints({
    endpoints: (build) => ({
      getEvents: build.query<MetaData<Event[]>, string | null>({
        query: (query) => ({
          path: query ? `/events?${query}` : "/events",
        }),
        providesTags: ["event"],
      }),
      getEventsAll: build.query<MetaData<Event[]>, void>({
        query: () => ({
          path: `/events?is_draft=true`,
        }),
        providesTags: ["event"],
      }),
      getRecommendedEvents: build.query<MetaData<Event[]>, string>({
        query: (query) => ({
          path: `/events/recommend?${query}`,
        }),
        providesTags: ["event"],
      }),
      getEventsBanner: build.query<MetaData<Event[]>, string | null>({
        query: (query) => ({
          path: query ? `/events/banner?${query}` : "/events/banner",
        }),
        providesTags: ["event"],
      }),
      getOneEvent: build.query<{ data: Event }, number>({
        query: (id) => ({
          path: `/events/${id}`,
        }),
        providesTags: ["event"],
      }),
      toggleLike: build.mutation<
        MetaData<{ isliked: boolean }>,
        MetaData<{ event_id: number | undefined }>
      >({
        query: ({ data }) => ({
          path: `/events/toggle-like`,
          method: "POST",
          body: { meta: {}, data: { event_id: data.event_id } },
        }),
        invalidatesTags: ["event"],
      }),
      toggleIsDraft: build.mutation<
        MetaData<{ is_draft: boolean }>,
        MetaData<{ event_id: number | undefined }>
      >({
        query: ({ data }) => ({
          path: `/events/toggle-draft`,
          method: "POST",
          body: { meta: {}, data: { event_id: data.event_id } },
        }),
        invalidatesTags: ["event"],
      }),
      addEvent: build.mutation<MetaData<Event>, MetaData<Event>>({
        query: ({ data }) => ({
          path: "/events",
          method: "POST",
          body: { meta: {}, data: data },
        }),
        invalidatesTags: ["event"],
      }),
      editEvent: build.mutation<
        MetaData<Event>,
        MetaData<{ id: number | undefined; data: Event }>
      >({
        query: ({ data }) => ({
          path: `/events/${data.data.id}`,
          method: "PUT",
          body: { meta: {}, data: data.data },
        }),
        invalidatesTags: ["event"],
      }),
      deleteEvent: build.mutation<MetaData<Event>, number>({
        query: (id) => ({
          path: `/events/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["event"],
      }),
      joinToEvent: build.mutation<
        MetaData<Event[]>,
        MetaData<{ id: number | undefined }>
      >({
        query: ({ data }) => ({
          path: `/events/join-to-event`,
          method: "POST",
          body: { meta: {}, data: data },
        }),
        invalidatesTags: ["event"],
      }),
      deleteUserInvitation: build.mutation<
        MetaData<Event[]>,
        MetaData<{ id: number | undefined }>
      >({
        query: ({ data }) => ({
          path: `/events/delete-user-invitation`,
          method: "POST",
          body: { meta: {}, data: data },
        }),
        invalidatesTags: ["event"],
      }),

      inviteUsersToEvent: build.mutation<
        MetaData<Event[]>,
        MetaData<InviteUsersToAgencyRequestType>
      >({
        query: ({ data }) => ({
          path: `/events/invite-users`,
          method: "POST",
          body: { meta: {}, data: data },
        }),
        invalidatesTags: ["event"],
      }),
      acceptInvitation: build.mutation<
        MetaData<{ event_id: number; is_accepted: boolean }>,
        MetaData<{ event_id: number; is_accepted: boolean }>
      >({
        query: ({ data }) => ({
          path: `/events/accept-invitation`,
          method: "POST",
          body: { meta: {}, data: data },
        }),
        invalidatesTags: ["event"],
      }),
    }),
  });

export const {
  useGetEventsQuery,
  useGetOneEventQuery,
  useGetRecommendedEventsQuery,
  useToggleLikeMutation,
  useAddEventMutation,
  useEditEventMutation,
  useDeleteEventMutation,
  useJoinToEventMutation,
  useInviteUsersToEventMutation,
  useDeleteUserInvitationMutation,
  useToggleIsDraftMutation,
  useAcceptInvitationMutation,
  useGetEventsBannerQuery,
  useGetEventsAllQuery,
} = eventApiHooks;
