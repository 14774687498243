import ExpireIcon from "components/constant/icons/ExpireIcon";
import WarningIcon from "components/constant/icons/WarningIcon";
import { checkExpirationStatus } from "utils/checkdateExpirationDate";

export const expireDateColumn = (expiration_date: string) => {
  const status = checkExpirationStatus(expiration_date);
  switch (status) {
    case "expired":
      return (
        <span className="flex items-center gap-2 text-red-default">
          <ExpireIcon /> Закончился
        </span>
      );

    case "gonna_expire":
      return (
        <span className="flex items-center gap-2 text-[#DAAA00]">
          <WarningIcon /> {expiration_date}
        </span>
      );

    case "success":
      return expiration_date;

    default:
      return expiration_date;
  }
};

export function getItemStatusParking(item: any) {
  if (item.is_sold) {
    return <span className="  text-[#EF3B24]">Продано</span>;
  } else if (item.status === "free") {
    return <span className=" text-[#4BCC36] ">Доступен для покупки</span>;
  } else {
    return <span className=" text-[#EF3B24]">Забронировано</span>;
  }
}
