import React, { Dispatch, SetStateAction, useState } from "react";
import { useWindowSize } from "hooks/useWindowSize";
import { MetricTypeMainInfo } from "data/api/analytics/type";
import Dropdown from "components/Form/Dropdown/Dropdown";
import { dealStepsOptions } from "enums/deals";
import { optionType } from "../AnalyticsMainInfoList";

interface Props {
  item?: MetricTypeMainInfo;
  icons: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  isrewardInfo?: boolean;
  isDropDown?: boolean;
  selectStep?: optionType | null;
  setSelectStep?: Dispatch<SetStateAction<optionType | null>>;
}

const AnaliticsInfoCart = ({
  item,
  isrewardInfo,
  title,
  icons: IconComponent,
  isDropDown,
  selectStep,
  setSelectStep,
}: Props) => {
  const { isLg } = useWindowSize();

  function formatNumber(num: number | undefined): number | void {
    if (num) {
      return Number.isInteger(num) ? num : parseFloat(num.toFixed(2));
    }
  }
  return (
    <div
      className={`rounded-lg bg-white-default flex   gap-[10px] sm:gap-5 p-[10px] sm:p-[15px] ${
        isrewardInfo && !isLg
          ? "col-span-2 flex-row justify-between"
          : "col-span-1 flex-col"
      }`}
    >
      <div className="flex gap-2 sm:gap-3 items-center">
        <div className="w-[44px] h-[44px] rounded-lg bg-bg-default flex items-center justify-center">
          <IconComponent color="#3583FF" />
        </div>
        <p className=" leading-[16px] sm:leading-[22px] w-[100px] sm:w-auto text-[12px] sm:text-[16px]">
          {title}
        </p>
        {isDropDown && (
          <div className="ml-auto">
            <Dropdown
              placeholder={"Показ"}
              value={selectStep}
              options={dealStepsOptions}
              className={
                " select-bg-transparent p-[7px] border border-boder-default"
              }
              onChange={(value) => setSelectStep?.(value)}
            />
          </div>
        )}
      </div>
      <div
        className={`flex flex-col  sm:gap-5 ${
          isrewardInfo && !isLg ? "gap-0" : "gap-[10px]"
        } `}
      >
        <h2
          className={` text-accent-default font-dewi text-[14px] sm:text-[20px]`}
        >
          {formatNumber(item?.current) || 0}
        </h2>
        <div className="flex justify-between items-center text-[12px] sm:text-sm font-medium text-[#42C34F]">
          <p>{formatNumber(item?.difference) || 0}</p>
          <p>{item?.percent}%</p>
        </div>
      </div>
    </div>
  );
};

export default AnaliticsInfoCart;
