/* eslint-disable jsx-a11y/iframe-has-title */
import ProjectPageCard from "components/Cards/ProjectPageCard";
import PageTitle from "components/PageTitle";
import { useGetProjectsQuery } from "data/api/project";
import { useMemo, useState } from "react";
import ButtonForm from "../../components/Button/ButtonForm";
import ListIcon from "../../components/constant/icons/ListIcon";
import MarkerIcon from "../../components/constant/icons/MarkerIcon";
import { useWindowSize } from "../../hooks/useWindowSize";
import YMap from "./YMap";
import Loader from "components/Loader";
import { Project } from "modules/Deal/type";
import { useSelector } from "react-redux";
import { RootState } from "store";
import AnimationFrame from "components/AnimationFrame.tsx";
import { Link } from "react-router-dom";

const Projects = () => {
  const selection = useSelector((state: RootState) => state.header);

  const { data, isLoading } = useGetProjectsQuery(selection.city_id);

  const { isMd } = useWindowSize();

  const btnList = [
    {
      key: "list",
      text: "Списком",
      icon: (color: string) => <ListIcon color={color} />,
    },
    {
      key: "map",
      text: "На карте",
      icon: (color: string) => <MarkerIcon color={color} />,
    },
  ];

  const [showContent, setShowContent] = useState<any>(btnList[0]);

  const checkShowMobile = (key: string) => {
    if (isMd) {
      return showContent.key === key;
    }

    return true;
  };

  const projectList = useMemo(() => data?.data ?? [], [data]);

  const apartmentsCount = projectList.reduce(
    (acc, curr) => acc + curr.apartment_count,
    0
  );

  return (
    <div className="bg-bg-default">
      <div className="br-container  py-[16px] md:py-[30px] flex flex-col gap-[20px]">
        <div className="flex justify-between relative">
          <PageTitle text="Проекты" />
        </div>
        {isLoading && <Loader />}
        <AnimationFrame>
          <div className="flex gap-[20px]">
            {checkShowMobile("list") && (
              <div className="flex flex-col gap-[10px] flex-1">
                {projectList.map((item: Project) => (
                  <div key={item.id}>
                    <ProjectPageCard item={item} />
                  </div>
                ))}
                <Link to={"/exchange"}>
                  <div className="flex p-[20px] justify-between border-l-4 rounded-tr-lg rounded-br-lg text-[16px] items-center text-accent-default bg-white-default cursor-pointer border-red-default">
                    <h3 className="leading-[18px] font-medium">
                      Каталог квартир от Брусника. Обмен
                    </h3>
                    <h4 className=" font-normal leading-[20px]">
                      {apartmentsCount} квартир
                    </h4>
                  </div>
                </Link>
              </div>
            )}
            {checkShowMobile("map") && <YMap projectList={projectList} />}
          </div>
        </AnimationFrame>
      </div>
      {isMd && (
        <div className="flex justify-between gap-[10px] z-10 w-full pb-5  br-container">
          {btnList.map((item, idx) => (
            <ButtonForm
              key={`btn-item-${idx}`}
              text={item.text}
              leftIcon={item.icon(
                showContent.key === item.key ? "white" : "black"
              )}
              className={`py-[9px] rounded-[32px!important] border-[1px] w-full justify-center min-w-40 ${
                showContent.key === item.key
                  ? "bg-red-default border-red-default text-white-default"
                  : "bg-bg-default  border-[#D7DCE4]"
              }
            `}
              onClick={() => setShowContent(item)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Projects;
