import React, { useState } from "react";
import Carousel from "../../components/Carousel";
import BreadCrump from "../../components/BreadCrump";
import TableContainer from "../../components/Form/Table";

import HomeCalendar from "../../components/Calendar/HomeCalendar";
import Notifications from "components/Notifications";
import ChatIcon from "components/constant/icons/ChatIcon";
import HomePageCard from "../../components/Cards/HomePageCard";
import { useWindowSize } from "../../hooks/useWindowSize";
import HomePageMobileCard from "../../components/Cards/HomePageMobileCard";
import { TrainingSider } from "../../components/Carousel/TrainingSider";
import { useRoles } from "hooks/useRoles";
import { useGetEventsQuery } from "data/api/event";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import moment from "moment";
import { useNavigate } from "react-router";
import { useGetNewsQuery } from "data/api/news";
import ToggleSwitcher from "components/ToggleSwitcher";
import { handleEditModeInHomePage } from "store/HomPageReducer";
import PencilIcon from "components/constant/icons/PencilIcon";
import { CloseIcon } from "yet-another-react-lightbox";
import BannerEditModals from "./BannerEditModals";
import { useGetBannersQuery } from "data/api/banner";
import ButtonForm from "../../components/Button/ButtonForm";

const HomePage = () => {
  const { isMd } = useWindowSize();

  const roles = useRoles(null);

  const selection = useSelector((state: RootState) => state);

  const navigate = useNavigate();

  const [newsLimit, setNewsLimit] = useState(6);

  const { data: events } = useGetEventsQuery(
    `city_id=${selection.header.city_id}`
  );
  const { data: news } = useGetNewsQuery(
    `city_id=${selection.header.city_id}&limit=${newsLimit}`
  );

  const [isBannerEditOpen, setIsBannerEditOpen] = useState(false);

  const [contentTable, setContentTable] = useState<any[]>([
    {
      name: "Показ",
      task: "Все задачи передаются в CRM-систему ....",
      fullname: "Артур Спиридонов",
      date: "16.05.24  19:00",
      project: "ЖК Норпалова",
    },
    {
      name: "Показ",
      task: "Все задачи передаются в CRM-систему ....",
      fullname: "Артур Спиридонов",
      date: "16.05.24  19:00",
      project: "ЖК Норпалова",
    },
    {
      name: "Показ",
      task: "Все задачи передаются в CRM-систему ....",
      fullname: "Артур Спиридонов",
      date: "16.05.24  19:00",
      project: "ЖК Норпалова",
    },
    {
      name: "Показ",
      task: "Все задачи передаются в CRM-систему ....",
      fullname: "Артур Спиридонов",
      date: "16.05.24  19:00",
      project: "ЖК Норпалова",
    },
  ]);

  const [headerTable, setHeaderTable] = useState([
    {
      key: "task",
      title: "Зачачи",
      sortable: true,
    },
    {
      key: "fullname",
      title: "Клиент",
    },
    {
      key: "date",
      title: "Дедлайн",
      sortable: true,
    },
    {
      key: "project",
      title: "Проект",
      sortable: true,
    },
  ]);

  const { data: bannerData } = useGetBannersQuery(
    `city_id=${selection.header.city_id}`
  );

  const dispatch = useDispatch();

  const isEditMode = selection.home.isEdit;

  const handleEdit = () => {
    dispatch(handleEditModeInHomePage(!isEditMode));
  };

  const handleChangeCountNews = () => {
    setNewsLimit((count) => count + 6);
  };

  const handleJump = (link: string) => {
    window.location.href = link;
  };

  return (
    <section id="homepage" className=" bg-bg-default relative">
      <div className="md:py-[30px] py-[10px] br-container flex gap-[20px] flex-col lg:flex-row">
        <div className="xl:w-[76%] lg:w-[70%] w-full">
          <div className="md:mb-[20px] mb-[15px] 2xl:mb-[30px]  w-full relative">
            {isEditMode ? (
              <>
                {/* <div className="absolute z-30 top-[10px] left-[10px]">
                  <ToggleSwitcher />
                </div> */}
                <div
                  onClick={() => setIsBannerEditOpen(true)}
                  className="absolute z-30 top-[10px] right-[10px] w-[34px] h-[34px] border cursor-pointer bg-white-default border-boder-default rounded-xl flex items-center justify-center"
                >
                  <PencilIcon color={"#37465B"} />
                </div>
              </>
            ) : null}
            {bannerData?.data.length ? (
              <Carousel>
                {bannerData?.data.map((item, i) => (
                  <div
                    onClick={() => handleJump(item.link)}
                    className="rounded-[8px] cursor-pointer"
                    key={item.id}
                  >
                    <img
                      src={
                        process.env.REACT_APP_FILES_URL +
                          "/" +
                          item?.cover_image || ""
                      }
                      crossOrigin="anonymous"
                      alt="img of building"
                      className="w-full rounded-xl"
                    />
                  </div>
                ))}
              </Carousel>
            ) : null}
          </div>

          {isMd && <HomeCalendar />}

          {roles.isOzk_manager || roles.isManager ? (
            <div className="md:mt-[20px] mt-[15px] xl:mt-[30px]">
              <div className="flex justify-start items-center gap-4">
                {isEditMode ? (
                  <div className=" mb-[6px]">
                    <ToggleSwitcher />
                  </div>
                ) : null}

                <BreadCrump link="" title="Задачи" />
              </div>

              {isMd ? (
                <div className={"bg-white-default rounded-lg"}>
                  {contentTable.map((item: any, index) => (
                    <div
                      key={`table-content-item-${index}`}
                      onClick={() => {}}
                      className={`cursor-pointer w-full p-1 ${
                        index ? "border-t" : ""
                      } border-boder-default px-[15px] py-[12px]`}
                    >
                      <div className={"flex items-center mb-[5px]"}>
                        <p
                          className={
                            "text-sm font-medium mr-[12px] text-blue-default"
                          }
                        >
                          {item.name}
                        </p>

                        <p className={"text-xs text-accent-default opacity-50"}>
                          {item.date}
                        </p>
                      </div>

                      <div className={"mb-[15px]"}>
                        <p className={"text-xs"}>{item.task}</p>
                      </div>

                      <div
                        className={"flex justify-between text-sm font-medium"}
                      >
                        <p>{item.project}</p>

                        <p>{item.fullname}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <TableContainer
                  header={headerTable}
                  content={contentTable}
                  setData={setContentTable}
                />
              )}
            </div>
          ) : null}

          {news?.data.length ? (
            <div className="md:mt-[20px] mt-[15px] xl:mt-[30px]">
              <div className="flex justify-start items-center gap-4">
                {/* {isEditMode ? (
                  <div className=" mb-[6px]">
                    <ToggleSwitcher />
                  </div>
                ) : null} */}
                <BreadCrump link="/news" title="Новости" />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[15px]">
                {isMd ? (
                  <HomePageMobileCard type="news" list={news?.data} />
                ) : (
                  news?.data.map((item, i) => (
                    <HomePageCard
                      imageSrc={
                        process.env.REACT_APP_FILES_URL +
                          "/" +
                          item?.cover_image || ""
                      }
                      key={i}
                      title={item?.title || ""}
                      watched={item.views_count}
                      likes={item.likes_count}
                      date={`${
                        item.created_at
                          ? moment(item.created_at).format("DD.MM.YYYY")
                          : ""
                      }`}
                      hashtag={
                        item.primary_category?.name
                          ? [item.primary_category?.name]
                          : []
                      }
                      onClick={() => navigate(`news/view/${item.id}`)}
                    />
                  ))
                )}
              </div>

              {news?.data.length >= newsLimit && (
                <div
                  className={
                    "w-full px-[24px] pt-[22px] pb-[10px] flex items-center justify-center"
                  }
                >
                  <ButtonForm
                    text={"Загрузить еще"}
                    className={"text-blue-default"}
                    onClick={handleChangeCountNews}
                  />
                </div>
              )}
            </div>
          ) : null}

          {isMd && <TrainingSider className={isMd ? "mt-[15px]" : ""} />}
          {events?.data.length ? (
            <div className="md:mt-[20px] mt-[15px] xl:mt-[30px]">
              <div className="flex justify-start items-center gap-4">
                {/* {isEditMode ? (
                  <div className=" mb-[6px]">
                    <ToggleSwitcher />
                  </div>
                ) : null} */}
                <BreadCrump link="/events" title="Мероприятия" />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[15px]">
                {isMd ? (
                  <HomePageMobileCard type="events" list={events?.data} />
                ) : (
                  events?.data
                    .slice(0, 6)
                    .map((item, i) => (
                      <HomePageCard
                        imageSrc={
                          process.env.REACT_APP_FILES_URL + "/" + item?.photo ||
                          ""
                        }
                        key={i}
                        title={item.title}
                        hashtag={item.tags}
                        watched={item.views_count}
                        likes={item.likes_count}
                        date={`${
                          item.date
                            ? moment(item.date).format("DD.MM.YYYY")
                            : ""
                        }`}
                        onClick={() => navigate(`events/view/${item.id}`)}
                      />
                    ))
                )}
              </div>
            </div>
          ) : null}
        </div>

        <div className="xl:w-[24%] lg:w-[30%] w-full flex flex-col gap-[10px] self-start">
          {!isMd && (
            <>
              <TrainingSider />
              <HomeCalendar />
            </>
          )}
          <Notifications />
        </div>
      </div>
      <div>
        {(roles.isAdmin || roles.isAffiliate_manager) && (
          <div className="fixed bottom-[70px] right-[10px] sm:right-[90px]  lg:bottom-[28px] lg:flex items-center justify-center">
            <div
              className={`rounded-full ${
                isEditMode ? "bg-red-default" : "bg-accent-default"
              }  flex items-center shadow-md border border-bg-default justify-center p-3 cursor-pointer`}
              onClick={handleEdit}
            >
              {isEditMode ? (
                <CloseIcon color={"#FFFFFF"} />
              ) : (
                <PencilIcon color={"#FFFFFF"} />
              )}
            </div>
          </div>
        )}
      </div>
      {!isMd && (
        <button
          className="w-[48px] h-[48px] flex items-center justify-center
       bg-accent-default fixed bottom-[30px] right-[30px] rounded-full outline-none border border-white-default"
        >
          <ChatIcon />
        </button>
      )}
      <BannerEditModals
        isBannerEditOpen={isBannerEditOpen}
        setIsBannerEditOpen={setIsBannerEditOpen}
      />
    </section>
  );
};

export default HomePage;
