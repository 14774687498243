import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";

import ButtonForm from "components/Button/ButtonForm";
import Dropdown from "components/Form/Dropdown/Dropdown";
import Label from "components/Form/Label";
import PageTitle from "components/PageTitle";
import { handleFilterData, selectType } from "store/ApartmentReducer";
import { Filters } from "store/ApartmentReducer/type";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import { useWindowSize } from "../../../hooks/useWindowSize";

import { primisesTypeData, rangeData } from "../data";
import { handleOpenFilters } from "store/BaseClientsReducer";
import { Primise } from "enums/primisesType";
import { useGetPremisesSeasonsQuery } from "data/api/premises";
import { objectToQueryString } from "utils/objectToQuery";
import MultiRangeSlider from "components/Form/MultiRangeSlider";
import { useGetCitiesQuery } from "data/api/city";
import useGetSectionByBuildingId from "hooks/commonApiCalls/useGetSectionsByBuildingId";
import useGetBuildingByProjectId from "hooks/commonApiCalls/useGetBuildingsbyProjectId";
import useGetProjectByCityId from "hooks/commonApiCalls/useGetProjectByCityId";
import Modal from "components/Modal";

interface propsType {
  title: string;
  storeIsOpenFilters?: boolean;
}

const Filter = ({ title, storeIsOpenFilters }: propsType) => {
  const { isMd, isSm } = useWindowSize();

  const selection = useSelector((state: RootState) => state);

  const { id } = useParams();

  const navigate = useNavigate();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const dispatch = useDispatch();

  const { data: cities } = useGetCitiesQuery();

  const [localFilters, setLocalFilters] = useState<Filters>({
    type: selection.apartment.filters.type,
  });

  const { data: seasonData } = useGetPremisesSeasonsQuery(
    ""
    // objectToQueryString({ ...localFilters, season_id: "" })
  );

  const { projects } = useGetProjectByCityId({
    cityId: localFilters?.city_id,
  });

  const { building } = useGetBuildingByProjectId({
    projectID: localFilters?.project_id,
  });

  const { sections } = useGetSectionByBuildingId({
    buildingId: localFilters?.building_id,
  });

  const cityOptions = useFormatForDropDownOptions(cities?.data ?? []);

  const projectOptions = useFormatForDropDownOptions(projects ?? []);

  const buildingOptions = useFormatForDropDownOptions(building ?? []);

  const sectionOptions = useFormatForDropDownOptions(sections ?? []);

  const primisesType = queryParams.get("primises_type");

  const resetQueryParams = () => {
    navigate({
      pathname: "/apartments",
    });
  };

  const seasonOptions = seasonData?.data.map((item) => {
    return {
      label: item.season_name,
      value: item.id,
    };
  });

  const isMobile = isMd || isSm;

  const updateLocalFilter = (key: keyof Filters, value: string) => {
    setLocalFilters((prevFilters) => ({
      ...((prevFilters as Filters) || null),
      [key]: value,
    }));
    dispatch(selectType(localFilters?.type as Primise));
  };

  const updateFilter = () => {
    dispatch(
      handleFilterData({ ...selection.apartment.filters, ...localFilters })
    );
  };

  useEffect(() => {
    if (id) {
      const updatedFilters = {
        type: (primisesType as Primise) ?? Primise.APARTMENT,
        project_id: id ? Number(id) : 0,
        city_id: selection.header.city_id,
        building_id: 0,
      };
      setLocalFilters(updatedFilters);
      dispatch(handleFilterData(updatedFilters));
    }
  }, [id]);

  useEffect(() => {
    handleSelectCity(selection.header.city_id);
  }, [selection.header.city_id]);

  const handleSelectCity = (city_id: number) => {
    const updatedFilters = {
      type: (primisesType as Primise) ?? Primise.APARTMENT,
      city_id,
      project_id: id ? +id : 0,
      building_id: 0,
    };
    if (localFilters.city_id && city_id !== localFilters.city_id) {
      resetQueryParams();
      updatedFilters.project_id = 0;
      updatedFilters.type = Primise.APARTMENT;
    }
    setLocalFilters(updatedFilters);
    dispatch(handleFilterData(updatedFilters));
  };

  const handleTypeSelect = (e: { value: string }) => {
    updateLocalFilter("type", e.value);
    setLocalFilters((prev) => ({
      ...((prev as Filters) || null),
      rooms: "",
    }));
  };

  const handleToggleRooms = (item: string) => {
    if (item === localFilters?.rooms) {
      updateLocalFilter("rooms", "");
      return;
    }
    updateLocalFilter("rooms", item);
  };

  const handleProjectChange = (e: any) => {
    const updatedFilters = {
      type: (primisesType as Primise) ?? Primise.APARTMENT,
      city_id: selection.apartment.filters.city_id,
      project_id: e.value,
      building_id: 0,
    };
    resetQueryParams();
    setLocalFilters(updatedFilters);
  };

  const getValue = (
    opts: { label: string; value: string | number }[],
    val: number | string | undefined
  ) => {
    if (val) {
      return opts.filter((o) => o.value == val);
    }
    return null;
  };

  // Filter Mobile Version

  if (isMd) {
    if (storeIsOpenFilters) {
      return (
        <Modal
          className="rounded-t-[30px] md:rounded-lg w-full md:w-[500px] "
          isOpen={storeIsOpenFilters}
          onClose={() => dispatch(handleOpenFilters(false))}
        >
          <div
            className={`bg-white-default sm:p-[20px] xl:p-[30px] self-start lg:min-w-[346px] h-[78vh] overflow-y-scroll toolbar-scroll overflow-x-hidden px-[10px]`}
          >
            <PageTitle text={title} />
            <div className="flex flex-col gap-[25px] mt-[30px]">
              <Label label="Тип помещения">
                <Dropdown
                  onChange={handleTypeSelect}
                  options={primisesTypeData}
                  name="type"
                  placeholder="Все"
                  className="w-full"
                  value={getValue(primisesTypeData, localFilters?.type)}
                />
              </Label>
              <Label label="Город" className="w-full lg:flex-1">
                <Dropdown
                  name="city"
                  options={[...cityOptions]}
                  onChange={(e) => handleSelectCity(e.value)}
                  value={getValue(cityOptions, localFilters.city_id as number)}
                  placeholder="Город"
                />
              </Label>
              <Label label="Проект">
                <Dropdown
                  disabled={!projectOptions.length}
                  onChange={handleProjectChange}
                  options={[{ label: "Все", value: null }, ...projectOptions]}
                  value={getValue(projectOptions, localFilters?.project_id)}
                  placeholder="Все"
                  className="w-full"
                  name="project_id"
                />
              </Label>
              <Label label="Дом">
                <Dropdown
                  onChange={(e) => {
                    updateLocalFilter("building_id", e.value);
                  }}
                  options={[{ label: "Все", value: null }, ...buildingOptions]}
                  placeholder="Все"
                  className="w-full"
                  disabled={
                    buildingOptions.length === 0 || !localFilters?.project_id
                  }
                  name="building_id"
                  value={getValue(
                    [{ label: "Все", value: 0 }, ...buildingOptions],
                    localFilters?.building_id
                  )}
                />
              </Label>
              <Label label="Секция">
                <Dropdown
                  onChange={(e) => updateLocalFilter("section_id", e.value)}
                  options={[{ label: "Все", value: "" }, , ...sectionOptions]}
                  placeholder="Все"
                  className="w-full"
                  disabled={
                    !localFilters?.building_id || sectionOptions.length === 0
                  }
                  name="section_id"
                  value={getValue(sectionOptions, localFilters?.section_id)}
                />
              </Label>
              <Label label="Срок сдачи">
                <Dropdown
                  onChange={(e) => updateLocalFilter("season_id", e.value)}
                  options={[
                    { label: "всё", value: "" },
                    ,
                    ...(seasonOptions || []),
                  ]}
                  placeholder="Все"
                  className="w-full"
                  name="season_id"
                  // value={getValue(filterOptions.sections, localFilters?.season_id)}
                />
              </Label>

              {localFilters?.type === Primise.APARTMENT ? (
                <Label label="Комнат">
                  <div className="flex gap-2 items-center mb-[10px]">
                    <div
                      onClick={() => handleToggleRooms("0")}
                      className={`text-[12px] font-normal w-[70px] h-[45px] border border-light-blue-10 
               leading-[18.75px] rounded-full text-accent-default cursor-pointer
             flex items-center justify-center ${
               "0" == localFilters?.rooms &&
               "bg-accent-default text-white-default"
             } `}
                    >
                      Студия
                    </div>
                    {["1", "2", "3", "4+"].map((item) => (
                      <div
                        onClick={() => handleToggleRooms(item)}
                        key={item}
                        className={`text-[16px] font-normal w-[46px] h-[45px] border border-light-blue-10 
               leading-[18.75px] rounded-full text-accent-default cursor-pointer
             flex items-center justify-center ${
               item == localFilters?.rooms &&
               "bg-accent-default text-white-default"
             } `}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </Label>
              ) : null}

              {rangeData.map(
                ({
                  label,
                  min,
                  max,
                  step,
                  filterKeys,
                }: {
                  label: string;
                  min: number;
                  max: number;
                  step: number;
                  filterKeys: any;
                }) => (
                  <Label label={label} key={label}>
                    <MultiRangeSlider
                      onChange={(value) => {
                        updateLocalFilter(filterKeys[0], value.min.toString());
                        updateLocalFilter(filterKeys[1], value.max.toString());
                      }}
                      max={max}
                      min={min}
                      step={step}
                    />
                  </Label>
                )
              )}
              {/* <button className="text-[14px] leading-[24px] font-medium text-blue-default hover:text-blue-focus">
            Больше фильтров
          </button> */}
              <div className="flex flex-col sm:gap-[10px]">
                <ButtonForm
                  text="Применить"
                  className={`border border-accent-default mt-[1px] py-3 px-6
             hover:bg-accent-default flex justify-center
              hover:text-white-default w-full
              ${isMobile ? "bg-accent-default text-white-default" : ""}
              `}
                  onClick={(e) => {
                    updateFilter();
                    dispatch(handleOpenFilters(false));
                  }}
                />
                {isMobile && (
                  <ButtonForm
                    text="Отмена"
                    className="border border-accent-default py-3 px-6 flex justify-center w-full mt-[10px]"
                    onClick={() => dispatch(handleOpenFilters(false))}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal>
      );
    } else {
      return null;
    }
  } else {
    // Filter Desktop Version

    return (
      <div
        className={`filter bg-white-default
        sm:p-[20px] xl:p-[30px] self-start md:min-w-[346px]
        `}
      >
        <PageTitle text={title} />
        <div className="flex flex-col gap-[25px] mt-[30px]">
          <Label label="Тип помещения">
            <Dropdown
              onChange={handleTypeSelect}
              options={primisesTypeData}
              name="type"
              placeholder="Все"
              className="w-full"
              value={getValue(primisesTypeData, localFilters?.type)}
            />
          </Label>
          <Label label="Город" className="w-full lg:flex-1">
            <Dropdown
              name="city"
              options={[...cityOptions]}
              onChange={(e) => handleSelectCity(e.value)}
              value={getValue(cityOptions, localFilters.city_id as number)}
              placeholder="Город"
            />
          </Label>
          <Label label="Проект">
            <Dropdown
              disabled={!projectOptions.length}
              onChange={handleProjectChange}
              options={[{ label: "Все", value: null }, ...projectOptions]}
              value={getValue(projectOptions, localFilters?.project_id)}
              placeholder="Все"
              className="w-full"
              name="project_id"
            />
          </Label>
          <Label label="Дом">
            <Dropdown
              onChange={(e) => {
                updateLocalFilter("building_id", e.value);
              }}
              options={[{ label: "Все", value: null }, ...buildingOptions]}
              placeholder="Все"
              className="w-full"
              disabled={
                buildingOptions.length === 0 || !localFilters?.project_id
              }
              name="building_id"
              value={getValue(
                [{ label: "Все", value: 0 }, ...buildingOptions],
                localFilters?.building_id
              )}
            />
          </Label>
          <Label label="Секция">
            <Dropdown
              onChange={(e) => updateLocalFilter("section_id", e.value)}
              options={[{ label: "Все", value: "" }, , ...sectionOptions]}
              placeholder="Все"
              className="w-full"
              disabled={
                !localFilters?.building_id || sectionOptions.length === 0
              }
              name="section_id"
              value={getValue(sectionOptions, localFilters?.section_id)}
            />
          </Label>
          <Label label="Срок сдачи">
            <Dropdown
              onChange={(e) => updateLocalFilter("season_id", e.value)}
              options={[
                { label: "всё", value: "" },
                ,
                ...(seasonOptions || []),
              ]}
              placeholder="Все"
              className="w-full"
              name="season_id"
              // value={getValue(filterOptions.sections, localFilters?.season_id)}
            />
          </Label>

          {localFilters?.type === Primise.APARTMENT ? (
            <Label label="Комнат">
              <div className="flex gap-2 items-center mb-[10px]">
                <div
                  onClick={() => handleToggleRooms("0")}
                  className={`text-[12px] font-normal w-[70px] h-[45px] border border-light-blue-10 
               leading-[18.75px] rounded-full text-accent-default cursor-pointer
             flex items-center justify-center ${
               "0" == localFilters?.rooms &&
               "bg-accent-default text-white-default"
             } `}
                >
                  Студия
                </div>
                {["1", "2", "3", "4"].map((item) => (
                  <div
                    onClick={() => handleToggleRooms(item)}
                    key={item}
                    className={`text-[16px] font-normal w-[46px] h-[45px] border border-light-blue-10  leading-[18.75px] rounded-full text-accent-default cursor-pointer flex items-center justify-center ${
                      item === localFilters?.rooms &&
                      "bg-accent-default text-white-default"
                    } `}
                  >
                    {item === "4" ? "4+" : item}
                  </div>
                ))}
              </div>
            </Label>
          ) : null}

          {rangeData.map(
            ({
              label,
              min,
              max,
              step,
              filterKeys,
            }: {
              label: string;
              min: number;
              max: number;
              step: number;
              filterKeys: any;
            }) => (
              <Label label={label} key={label}>
                <MultiRangeSlider
                  onChange={(value) => {
                    updateLocalFilter(filterKeys[0], value.min.toString());
                    updateLocalFilter(filterKeys[1], value.max.toString());
                  }}
                  max={max}
                  min={min}
                  step={step}
                />
              </Label>
            )
          )}
          {/* <button className="text-[14px] leading-[24px] font-medium text-blue-default hover:text-blue-focus">
            Больше фильтров
          </button> */}
          <div className="flex flex-col sm:gap-[10px]">
            <ButtonForm
              text="Применить"
              className={`border border-accent-default mt-[1px] py-3 px-6
             hover:bg-accent-default flex justify-center
              hover:text-white-default w-full
              ${isMobile ? "bg-accent-default text-white-default" : ""}
              `}
              onClick={(e) => {
                updateFilter();
                dispatch(handleOpenFilters(false));
              }}
            />
            {isMobile && (
              <ButtonForm
                text="Отмена"
                className="border border-accent-default py-3 px-6 flex justify-center w-full mt-[10px]"
                onClick={() => dispatch(handleOpenFilters(false))}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default Filter;
