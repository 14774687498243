import React, { useState } from "react";
import Dropdown from "components/Form/Dropdown/Dropdown";
import Modal from "components/Modal";
import CircleXIcon from "components/constant/icons/CircleXIcon";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import ButtonForm from "components/Button/ButtonForm";
import Label from "components/Form/Label";
import { useFormatForDropDownOptions } from "hooks/useDropDownOption";
import showToast from "utils/showToast";
import { ApiException, ResponseStatusType } from "data/api/exception";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { SearchField } from "components/Form/SearchField";
import { useRecordForShowMutation } from "data/api/calendar";
import { Client } from "modules/Deal/type";
import { formatPhoneNumber } from "hooks/useFormatInput";
import { dateOptions, timeOptions } from "./data";
import useGetProjectByCityId from "hooks/commonApiCalls/useGetProjectByCityId";
import Loader from "components/Loader";
import DatePickerField from "components/Form/DatePicker/DatePickerField";
import moment from "moment";
import { useWindowSize } from "hooks/useWindowSize";

interface RecordForShow {
  project: { label: string; value: number };
  date: Date;
  time: string;
  client: number;
}

interface propType {
  handleToggle: () => void;
  isOpenModal: boolean;
  project_id?: number;
  client_Id?: number;
  handleRecordBack?: (agr: boolean) => void;
  client?: Client;
}

const RecordForShow = ({
  isOpenModal,
  handleToggle,
  project_id,
  client_Id,
  handleRecordBack,
  client,
}: propType) => {
  const selection = useSelector((state: RootState) => state.header);

  const { isMd } = useWindowSize();

  const [mutate] = useRecordForShowMutation();

  const [isLoading, setIsloading] = useState<boolean>(false);

  const { projects } = useGetProjectByCityId({
    cityId: selection.city_id,
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<RecordForShow>();

  const optionsProject = useFormatForDropDownOptions(projects ?? []);

  const onSubmit: SubmitHandler<RecordForShow> = async (data) => {
    setIsloading(true);
    try {
      const { data: res, error } = await mutate({
        project_id: project_id ? project_id : data.project.value,
        date: moment(data.date).toISOString(),
        time: data.time,
        client_id: client_Id ? client_Id : data.client,
      });

      if (res?.meta?.type === ResponseStatusType.SUCCESS) {
        reset();
        handleToggle();
        showToast("Подтверждение заявки на показ!", "success", 1000);
      } else if (error?.message) {
        showToast(error.message, "error");
      }
    } catch (error) {
      const err = error as ApiException;
      showToast(err.message, "error");
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <Modal
      className=" w-full md:w-[500px] rounded-t-[30px] md:rounded-[20px]"
      isOpen={isOpenModal}
    >
      <div className=" text-accent-default mb-5  flex items-center justify-between">
        <h1 className=" text-[25px] font-extrabold leading-[30px]">
          Запись на показ
        </h1>
        <button onClick={handleToggle}>
          <CircleXIcon />
        </button>
      </div>
      {client && (
        <div className="text-accent-default text-sm flex flex-col gap-[6px]">
          <p>
            ФИО: <span className="ml-2">{client?.fullname}</span>
          </p>
          <p>
            Номер:
            <span className="ml-2">
              +7 {formatPhoneNumber(client?.phone_number)}
            </span>
          </p>
        </div>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        action=""
        className="flex flex-col gap-[20px] mt-7"
      >
        {isLoading && <Loader />}
        {!project_id && (
          <Label label="Проект">
            <Controller
              name="project"
              control={control}
              rules={{
                required: {
                  value: !project_id,
                  message: "Проект обязателен для заполнения",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  name="project"
                  options={optionsProject}
                  errors={errors}
                  value={value}
                  onChange={onChange}
                  placeholder="Проект не выбран"
                />
              )}
            />
          </Label>
        )}
        {!client_Id && (
          <Label label="Клиент">
            <Controller
              name="client"
              control={control}
              rules={{
                required: {
                  value: !client_Id,
                  message: "Клиент обязателен для заполнения",
                },
              }}
              render={({ field: { onChange } }) => (
                <SearchField
                  onChange={(value) => onChange(value.id)}
                  className="rounded-[4px!important]"
                />
              )}
            />
          </Label>
        )}

        <Label label="Дата показа">
          <Controller
            name="date"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Дата обязателен для заполнения",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <DatePickerField
                className={`w-full ${!isMd && "calendar-block"}`}
                startDate={value}
                placeholder={"Дата добавления"}
                minDate={new Date()}
                isIcon
                isRightIcon
                isClearable
                onChangeDate={onChange}
                isMobile={isMd}
              />
            )}
          />
        </Label>
        <Label label="Время показа">
          <Controller
            name="time"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Время обязателен для заполнения",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                name="time"
                options={timeOptions}
                errors={errors}
                defaultValue={value}
                onChange={(data) => onChange(data.value)}
                placeholder="Время не выбран"
              />
            )}
          />
        </Label>

        <div className="flex flex-col-reverse sm:flex-row gap-[10px] mt-[10px]">
          <ButtonForm
            text="Назад"
            onClick={() => {
              handleToggle();
              reset();
              handleRecordBack?.(true);
            }}
            className=" bg-bg-default py-3 px-6 flex-1
             hover:bg-accent-default flex justify-center
              hover:text-white-default"
          />
          <ButtonForm
            disabled={isLoading}
            text="Записать на показ"
            type="submit"
            className=" py-3 px-6 
            bg-accent-default flex-1
            text-white-default flex justify-center
            hover:bg-accent-disabled
            hover:
            "
          />
        </div>
      </form>
    </Modal>
  );
};

export default RecordForShow;
