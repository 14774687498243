import React from "react";

import ButtonForm from "components/Button/ButtonForm";
import { Link, useNavigate } from "react-router-dom";
import { useFormatPrice } from "hooks/useFormatPrice";
import ReacordForShow from "components/BookingAndVisits/RecordForShowForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { Project } from "modules/Deal/type";
import { toggleRecordForShowModal } from "store/ApartmentReducer";
import { motion } from "framer-motion";
import { Primise } from "enums/primisesType";
import { images } from "utils/resource";

interface Props {
  item: Project;
  cardClass?: string;
}

const ProjectPageCard = ({ item }: Props) => {
  const selection = useSelector(
    (state: RootState) => state.apartment.recordForShowModalById
  );

  const {
    apartment_count,
    brief_description,
    commercial_count,
    end_date,
    link,
    location,
    name,
    parking_count,
    price,
    id,
    storeroom_count,
    photo,
  } = item;

  const date = new Date(end_date);
  const navigate = useNavigate();
  const dispach = useDispatch();

  const options: {} = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const humanReadableDate = date.toLocaleDateString("ru-Ru", options);

  const handleNavigate = (type: Primise | null) => {
    if (type) {
      navigate(`/apartments/${id}?primises_type=${type}`);
      return;
    }
    navigate(`/apartments/${id}`);
  };

  const handleToggleRecordForShowModal = () => {
    dispach(toggleRecordForShowModal(id));
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.6 }}
    >
      <div className="flex flex-col md:flex-row w-full bg-white-default rounded-[8px] text-accent-default overflow-hidden">
        <img
          crossOrigin="anonymous"
          src={
            photo
              ? process.env.REACT_APP_FILES_URL + "/" + photo
              : images.defaultProjectImg
          }
          alt="cardPage"
          className={`md:min-w-[304px] md:max-h-none max-h-[205px]`}
        />

        <div className="p-[20px] w-full">
          <h3 className=" inline-block text-[16px] font-medium leading-[18.75px]">
            Квартал «{name}»
          </h3>
          <Link to={`/apartments/${id}`}>
            <span className="text-[14px] leading-[18px] font-normal ml-[10px] text-blue-default cursor-pointer">
              Подробнее
            </span>
          </Link>

          <p className="text-[14px] leading-[18px] font-normal m-0 p-0 mt-[5px]">
            {location}
          </p>

          <p className=" font-light text-[12px] leading-[16px] mt-[15px]">
            Срок сдачи: {humanReadableDate}
          </p>

          <h1 className="text-[20px] leading-[24px] my-[15px] font-extrabold">
            От {useFormatPrice(price + "")}
          </h1>

          <p className=" font-medium text-[12px] leading-[16px] opacity-50">
            {brief_description}
          </p>

          <div className="flex mt-[15px] mb-[10px]">
            <h5
              onClick={() => handleNavigate(Primise.APARTMENT)}
              className="flex-1 text-[14px] leading-[18px] font-medium cursor-pointer"
            >
              {apartment_count} квартиры
            </h5>

            <h5
              onClick={() => handleNavigate(Primise.PARKING)}
              className="flex-1 text-[14px] leading-[18px] font-medium cursor-pointer"
            >
              {parking_count} машино-мест
            </h5>
          </div>

          <div className="flex">
            <h5
              onClick={() => handleNavigate(Primise.STOREROOM)}
              className="flex-1 text-[14px] leading-[18px] font-medium cursor-pointer"
            >
              {storeroom_count} кладовых
            </h5>

            <h5
              onClick={() => handleNavigate(Primise.COMMERCIAL)}
              className="flex-1 text-[14px] leading-[18px] font-medium cursor-pointer"
            >
              {commercial_count} комм. помещений
            </h5>
          </div>

          <div className="flex gap-[10px] mt-[30px] flex-col md:flex-row">
            <ButtonForm
              onClick={() => handleNavigate(null)}
              text="Выбор помещений"
              className="border border-accent-default py-3 px-6
             hover:bg-accent-default xl:flex-1 flex justify-center
              hover:text-white-default whitespace-nowrap"
            />{" "}
            <ButtonForm
              text="Запись на показ"
              onClick={() => handleToggleRecordForShowModal()}
              className="border border-accent-default py-3 px-6
            hover:bg-accent-default xl:flex-1 flex justify-center
            hover:text-white-default whitespace-nowrap
            "
            />
          </div>
        </div>
        <ReacordForShow
          project_id={item.id}
          handleToggle={handleToggleRecordForShowModal}
          isOpenModal={selection === id}
        />
      </div>
    </motion.div>
  );
};

export default ProjectPageCard;
