import ButtonForm from "../../components/Button/ButtonForm";
import CloseWithBorderIcon from "../../components/constant/icons/CloseWithBorderIcon";
import Label from "../../components/Form/Label";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import Modal from "../../components/Modal";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useApplicationIpotekaMutation } from "../../data/api/calculate";
import { SearchField } from "../../components/Form/SearchField";
import { useFormatForDropDownOptions } from "../../hooks/useDropDownOption";
import { useGetBuildingsQuery } from "../../data/api/building";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { optionsType } from "../Apartment/Filter/type";
import { useGetPremisesByBuildingIdMutation } from "../../data/api/premises";
import RublIcon from "../../components/constant/icons/RublIcon";
import TextField from "../../components/Form/TextField/TextField";
import { useWindowSize } from "../../hooks/useWindowSize";

export interface formMortgageData {
	client_id: number;
	project?: number;
	building_id: any;
	premise_id: number;
	cost: number;
	first_payment: number;
	comment: string;
}

interface MortgageModalProps {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
}

const MortgageModal = (
	{
		isOpen,
		setIsOpen,
	}: MortgageModalProps
) => {
	const { isSm } = useWindowSize();

	const selection = useSelector((state: RootState) => state);
	const [mutate] = useApplicationIpotekaMutation();
	const [premisesGetMutation] = useGetPremisesByBuildingIdMutation();

	const {
		handleSubmit,
		control,
		formState: { errors, isValid },
		watch
	} = useForm<formMortgageData>();

	const projectSelection = useSelector((state: RootState) => state.header);

	const { data: buildings } = useGetBuildingsQuery(
		`city_id=${selection.header.city_id}`
	);


	const optionsBuilding = useFormatForDropDownOptions(buildings?.data ?? []);

	const [premisesOptions, setPremisesOptions] = useState<optionsType[] | null>(
		null
	);

	useEffect(() => {
		if (watch("building_id")) init(watch("building_id"));
	}, [watch("building_id")]);

	async function init(item: any) {
		if (item.value) {
			const { data: res, error } = await premisesGetMutation(+item.value);

			if (error?.message) {
				console.log(error?.message);
				return;
			}

			const options = res?.data.map((i) => {
				return { value: i?.id, label: i?.name };
			});

			if (options?.length) setPremisesOptions(options);
		}
	}

	async function handleApply(data: formMortgageData) {
		const requestObj = {
			...data,
			building_id: +data.building_id.value,
			cost: +data.cost,
			first_payment: +data.first_payment,
		}

		const { data: res } = await mutate(requestObj);

		if (res) setIsOpen(false)
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			className="mx-auto sm:w-[450px] lg:w-[500px] w-full rounded-t-[30px] rounded-b-0 sm:rounded-[20px]"
		>
			<div className={"flex md:justify-between justify-center relative mb-[20px]"}>
				<h1 className={"font-extrabold text-[25px] font-dewi"}>
					Заявка на ипотеку
				</h1>

				{!isSm && (
					<ButtonForm
						text={<CloseWithBorderIcon />}
						onClick={() => setIsOpen(false)}
					/>
				)}
			</div>
			<form className="flex flex-col gap-5" onSubmit={handleSubmit(handleApply)}>
				<Label label="Клиент">
					<Controller
						name="client_id"
						control={control}
						rules={{
							required: {
								value: true,
								message: "Клиент обязателен для заполнения",
							},
						}}
						render={({ field: { onChange } }) => (
							<SearchField
								onChange={(value) => onChange(value.id)}
								className="rounded-[4px!important]"
							/>
						)}
					/>
				</Label>
				<Label label="Объект">
					<Controller
						name="building_id"
						control={control}
						rules={{
							required: {
								value: true,
								message: "Объект обязателен для заполнения",
							},
						}}
						render={({ field: { onChange, value } }) => (
							<Dropdown
								name="building_id"
								value={value}
								options={optionsBuilding}
								onChange={onChange}
								placeholder="Объект не выбран"
								errors={errors}
							/>
						)}
					/>
				</Label>

				<Label label="Помещение">
					<Controller
						name="premise_id"
						control={control}
						rules={{
							required: {
								value: true,
								message: "Помещение обязательно для заполнения",
							},
						}}
						render={({ field: { onChange, value } }) => (
							<Dropdown
								name="premise_id"
								options={premisesOptions || []}
								onChange={(value) => onChange(value.value)}
								placeholder="Выберите помещение"
								errors={errors}
							/>
						)}
					/>
				</Label>

				{(watch("client_id") && watch("building_id") && watch("premise_id")) && (
					<>
						<Label label="Стоимость">
							<Controller
								name="cost"
								control={control}
								rules={{
									required: {
										value: true,
										message: "Стоимость обязательна для заполнения",
									},
								}}
								render={({ field: { onChange, value } }) => (
									<TextField
										value={value}
										placeholder="Введите сумму"
										onChange={onChange}
										imgRight={<RublIcon />}
									/>
								)}
							/>
						</Label>

						<Label label="Первый взнос">
							<Controller
								name="first_payment"
								control={control}
								rules={{
									required: {
										value: true,
										message: "Взнос обязателен для заполнения",
									},
								}}
								render={({ field: { onChange, value } }) => (
									<TextField
										value={value}
										placeholder="Введите сумму"
										onChange={onChange}
									/>
								)}
							/>
						</Label>

						<Label label="Комментарий">
							<Controller
								name="comment"
								control={control}
								rules={{
									required: {
										value: true,
										message: "Комментарий обязателен для заполнения",
									},
								}}
								render={({ field: { onChange, value } }) => (
									<textarea
										value={value}
										onChange={onChange}
										name="comment"
										rows={3}
										placeholder="Введите свой комментарий"
										cols={5}
										className="border border-boder-default py-3 px-4 w-full rounded text-sm font-normal outline-none resize-none"
									></textarea>
								)}
							/>
						</Label>
					</>
				)}

				<ButtonForm
					type={"submit"}
					className={
						"px-6 py-3 w-full mt-3 justify-center bg-accent-default text-white-default hover:bg-accent-disabled  hover:text-bg-default  hover:bg-bg-defau text-ellipsis whitespace-nowrap"
					}
					text="Отправить заявку"
					disabled={!isValid}
				/>
			</form>
		</Modal>
	)
}

export default MortgageModal;