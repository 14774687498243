import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  useSignInAgentVerifyMutation,
  useSignUpAgentMutation,
} from "data/api/auth";
import { ApiException } from "data/api/exception";
import ButtonForm from "components/Button/ButtonForm";
import Loader from "components/Loader";
import {
  RegisterFormState,
  agentFormDataType,
  formDataKeyType,
  verificationDataType,
} from "modules/Auth/types";
import TextField from "components/Form/TextField/TextField";
import PhoneIcon from "components/constant/icons/PhoneIcon";
import { UseSMSCodeSender } from "hooks/useSMSCodeSender";
import showToast from "utils/showToast";
import { schemaAgent, schemaSMSCode } from "utils/schemas";
import { RegisterType, Step } from "enums/authEnums";
import Label from "components/Form/Label";
import MaskComponent from "components/MaskInput/MaskComponent";
import { getUserAction } from "store/UserReducer/actions";
import { useAppDispatch } from "store";

interface propsType {
  title: React.ReactNode;
  setFormState: React.Dispatch<React.SetStateAction<RegisterFormState>>;
  registerType?: RegisterType;
}

export const RegisterPhoneForm = ({ title, setFormState }: propsType) => {
  const [mutate, { isLoading }] = useSignUpAgentMutation();
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm<agentFormDataType | formDataKeyType>({
    resolver: yupResolver<agentFormDataType | formDataKeyType>(schemaAgent),
  });

  const onSubmit: SubmitHandler<agentFormDataType | formDataKeyType> = async (
    data,
  ) => {
    try {
      const { data: res, error } = await mutate({
        data: {
          phone: data.phone,
        },
      });
      if (error?.message) {
        showToast(error.message, "error");
        return;
      }
      if (res?.data.user_id) {
        localStorage.setItem("userId", res?.data.user_id + "");
        localStorage.setItem("phone", data.phone);
        setFormState({ step: Step.SMS_VERIFICATION_FORM });
        return;
      }
    } catch (err) {
      const error = err as ApiException;
      console.log("err", error);
    }
  };

  return (
    <React.Fragment>
      <h3 className="text-[25px] text-accent-default font-dewi font-extrabold leading-[30px] mb-[20px]">
        {title}
      </h3>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-[30px] relative"
      >
        {isLoading && <Loader />}

        <Label label="Номер телефона">
          <Controller
            name="phone"
            control={control}
            render={({ field: { onChange, value } }) => (
              <MaskComponent
                name="phone"
                errors={errors}
                imgLeft={<PhoneIcon />}
                value={getValues("phone")}
                onChange={(value) => onChange(value)}
              />
            )}
          />
        </Label>
        <div className="flex flex-col gap-[10px]">
          <ButtonForm
            className="bg-accent-default py-[12px] w-full
             text-white-default text-center flex justify-center hover:bg-accent-focus "
            text="Далее"
            type="submit"
          />
          <ButtonForm
            className=" bg-white-default border-[1px] border-accent-default py-[12px] w-full
             text-accent-default text-center flex justify-center hover:bg-bg-focus"
            text="Назад"
            onClick={() => setFormState({ step: Step.SELECT_REGISTER_TYPE })}
          />
        </div>
      </form>
    </React.Fragment>
  );
};

export const RegisterSMSCodeVerification = ({
  title,
  setFormState,
}: propsType) => {
  const dispatch = useAppDispatch();
  const [mutate, { isLoading }] = useSignInAgentVerifyMutation();

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = useForm<verificationDataType>({
    resolver: yupResolver<verificationDataType>(schemaSMSCode),
  });
  const {
    counter,
    handleResent,
    isLoading: loading,
    updateCounter,
  } = UseSMSCodeSender({ reset });

  useEffect(() => updateCounter(), []);

  const onSubmit: SubmitHandler<verificationDataType> = async (data) => {
    const userId = Number(localStorage.getItem("userId"));
    const registerType = localStorage.getItem("registerType") as RegisterType;
    try {
      const { data: res, error } = await mutate({
        data: {
          code: +data.code,
          user_id: userId,
        },
      });

      if (res?.data.accessToken) {
        localStorage.setItem("token", res.data.accessToken);
        await dispatch(getUserAction());
        navigate("/");
        showToast("Вы успешно вошли в систему", "success");
        return;
      }

      if (error?.message) {
        showToast(error.message, "error");
        return;
      }

      if (res?.data.user_id) {
        localStorage.setItem("userId", res.data.user_id + "");
        localStorage.setItem("register_status", res?.data.register_status);
        if (res?.data.register_status === "attachment") {
          if (registerType === RegisterType.NEW_AGENCY) {
            setFormState({ step: Step.NEW_AGENCY_AGREEMENT_FORM });
            return;
          } else if (registerType === RegisterType.AGENCY) {
            setFormState({ step: Step.ATTACH_TO_AGENCY_FORM });
            return;
          }
        }
        setFormState({ step: Step.USER_INFO_FORM });
        return;
      }
    } catch (err) {
      const error = err as ApiException;
      console.log("err", error);
    }
  };
  return (
    <React.Fragment>
      <h3 className="text-[25px] text-accent-default font-dewi font-extrabold leading-[30px] mb-[20px]">
        {title}
      </h3>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-[30px] relative"
      >
        {isLoading && <Loader />}
        {loading && <Loader />}
        <Controller
          name="code"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value}
              className=""
              placeholder="Введите код"
              label="Код из смс"
              type="text"
              maxLength={6}
              onChange={onChange}
              name="code"
              errors={errors}
              imgRight={
                <>
                  {counter === 0 ? (
                    <span
                      onClick={handleResent}
                      className="leading-[18px] text-blue-default text-[14px] flex items-center h-full justify-center cursor-pointer font-medium"
                    >
                      Отправить повторно
                    </span>
                  ) : (
                    <span className="text-[14px] flex items-center justify-center text-accent-default font-medium">
                      00:{counter >= 10 ? counter : `0${counter}`}
                    </span>
                  )}
                </>
              }
            />
          )}
        />
        <div className="flex flex-col gap-[10px]">
          <ButtonForm
            loading={isSubmitting}
            className="bg-accent-default py-[12px] w-full
             text-white-default text-center flex justify-center hover:bg-accent-focus "
            text="Далее"
            type="submit"
            disabled={!isValid}
          />
          <ButtonForm
            className="py-[12px] w-full
              text-accent-default text-center flex justify-center bg-bg-default
               hover:bg-accent-default
               hover:text-white-default"
            text="Назад"
            onClick={() => setFormState({ step: Step.PHONE_NUMBER_FORM })}
          />
        </div>
      </form>
    </React.Fragment>
  );
};
