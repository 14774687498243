import React from "react";
import { TopNews } from "data/api/analytics/type";
import NotFound from "components/NotFound";

interface Props {
  title: string;
  data: TopNews[] | null;
}

const EventsAnaliticsCart = ({ title, data }: Props) => {
  return (
    <div className="bg-white-default rounded-lg">
      <div className="py-[15px] px-5">
        <h2 className="font-dewi text-[18px] sm:text-[20px] text-accent-default">
          {title}
        </h2>
      </div>
      <hr className=" border-boder-default opacity-60" />
      <div className="py-[15px]  flex flex-col">
        {data?.length ? (
          data?.map((item, i) => (
            <div key={item.news_id}>
              <div className="flex justify-between px-[15px] sm:px-5">
                <div className="flex gap-3">
                  <div className="img relative w-[48px] h-[48px]">
                    <img
                      src={
                        process.env.REACT_APP_FILES_URL +
                        "/" +
                        item.news_cover_image
                      }
                      alt="analitcs img"
                      crossOrigin="anonymous"
                      className="w-[48px] h-[48px] rounded-lg"
                    />
                    <div className="w-[20px] absolute h-[20px] rounded-full flex items-center justify-center text-[12px] text-accent-default font-medium bg-[#FFB950] -bottom-[5px] -right-[5px]">
                      {i + 1}
                    </div>
                  </div>
                  <div className="text-accent-default leading-[18px] flex flex-col sm:gap-[5px]">
                    <p className=" text-sm sm:text-[16px] font-medium w-[230px] truncate">
                      {item?.news_title}
                    </p>
                    <p className="text-sm">#брусника #горячее</p>
                  </div>
                </div>
                <div className="text-accent-default leading-[18px] flex flex-col gap-[5px]">
                  <p className="text-sm opacity-50">Просмотры </p>
                  <p className=" text-sm sm:text-[16px] font-medium text-center">
                    {item.total}
                  </p>
                </div>
              </div>
              {i + 1 !== 5 && (
                <hr className=" border-boder-default my-[10px] opacity-60" />
              )}
            </div>
          ))
        ) : (
          <NotFound title={title + "не найдено"} />
        )}
      </div>
      <hr className=" border-boder-default opacity-60" />
      {/* <button className=" outline-none text-center cursor-pointer text-[#0085FF] text-[16px] font-medium py-3 w-full">
        Посмотреть все
      </button> */}
    </div>
  );
};

export default EventsAnaliticsCart;
