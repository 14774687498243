import React, { useEffect, useState } from "react";
import BreadCrump from "../BreadCrump";
import DateIcon from "../constant/icons/DateIcon";
import TriangelIcon from "../constant/icons/TriangelIcon";
import { useGetWeeklyQuery } from "data/api/calendar";
import moment, { Moment } from "moment";
import "moment/locale/ru";
import { CalendarData } from "data/api/calendar/type";

type EventType = {
  visits: string;
  events: string;
};

const HomeCalendar = () => {
  const dateNow = moment().format("YYYY-MM-DD");

  const [highlightedDate, setHighlightedDate] = useState(dateNow);

  const [indexOfSingleDay, setSelectIndexOfSingleDay] = useState<number>();

  const [selectedDate, setSelectedDate] = useState<string>();

  const [filteredData, setFilteredData] = useState<{
    visits: CalendarData[] | undefined;
    events: CalendarData[] | undefined;
  }>({
    visits: [],
    events: [],
  });

  const { data } = useGetWeeklyQuery(`date=${dateNow}`);

  useEffect(() => {
    filterDataByCurrentDate(highlightedDate);
  }, [highlightedDate, data?.data]);

  useEffect(() => {
    setHighlightedDate(dateNow);
  }, [data?.data]);

  const filterDataByCurrentDate = (date = dateNow) => {
    const filteredVisits = data?.data.visits?.filter((visit) =>
      moment(visit.date).isSame(date, "day")
    );
    const filteredEvents = data?.data.events?.filter((event) =>
      moment(event.date).isSame(date, "day")
    );
    setFilteredData((prev) => ({
      ...prev,
      visits: filteredVisits,
      events: filteredEvents,
    }));
  };

  const isEventsExist = (date: Moment) => {
    const isVisitExist = data?.data?.visits?.some((visit) =>
      moment(visit.date).isSame(date, "day")
    );

    const isEventExist = data?.data?.events?.some((event) =>
      moment(event.date).isSame(date, "day")
    );

    return {
      isVisitExist,
      isEventExist,
    };
  };

  const getWeekDates = () => {
    const startOfWeek = moment().startOf("isoWeek");
    return Array.from({ length: 7 }, (_, i) => {
      const date = startOfWeek.clone().add(i, "days");
      return {
        date,
        dayName: date.format("dd"),
      };
    });
  };

  const handleDateClick = (date: moment.Moment, i: number) => {
    moment.locale("ru");

    setHighlightedDate(date.format("YYYY-MM-DD"));
    setSelectIndexOfSingleDay(i);

    const selectedDate = date.format("D MMMM");
    setSelectedDate(selectedDate);

    console.log("date", date);
  };

  const eventType: EventType = {
    visits: "Показ объекта",
    events: "Мероприятия",
  };

  console.log("filteredData", Object.entries(filteredData));

  return (
    <div className="p-[15px] xl:p-[20px] bg-white-default rounded-[8px]">
      <BreadCrump
        title="Календарь"
        className="text-[16px] font-medium"
        link="/calendar"
        leftIcon={<DateIcon />}
      />

      <div className="flex gap-[5px] lg:gap-[3px] flex-wrap 2xl:gap-[5px] w-full mt-[15px] mb-[20px] xl:mb-[35px]">
        {getWeekDates().map((date, i) => (
          <div
            key={i}
            className={` relative w-[32px] sm:w-[40px] sm:h-[53px] lg:w-[35px] cursor-pointer  mb-[12px] xl:flex-1 rounded-[6px] 2xl:rounded-[8px] p-[4px] 2xl:p-[6px] flex flex-col gap-[4px] items-center justify-center  
          ${
            indexOfSingleDay === i
              ? "bg-accent-default"
              : isEventsExist(date.date).isVisitExist
              ? "bg-light-blue-10"
              : isEventsExist(date.date).isEventExist
              ? "bg-light-green-10"
              : ""
          }
          `}
            onClick={() => handleDateClick(date.date, i)}
          >
            <span
              className={` text-[12px] 2xl:text-[14px] font-normal leading-[18px] opacity-50 ${
                indexOfSingleDay === i
                  ? "text-white-default"
                  : date.date.format("YYYY-MM-DD") === dateNow
                  ? "text-blue-default"
                  : "text-accent-default"
              }  `}
            >
              {date.dayName}
            </span>
            <span
              className={`text-[14px] 2xl:text-[16px] font-medium leading-[18.75px] 
              ${
                indexOfSingleDay === i
                  ? " text-white-default"
                  : date.date.format("YYYY-MM-DD") === dateNow
                  ? "text-blue-default"
                  : "text-accent-default"
              }
              `}
            >
              {date.date.date()}
            </span>
            {date.date.format("YYYY-MM-DD") === dateNow && (
              <div className="absolute left-[50%] -bottom-[10px] -translate-x-[50%]">
                <TriangelIcon />
              </div>
            )}
          </div>
        ))}
      </div>

      <BreadCrump
        title={selectedDate || moment().format("D MMMM")}
        className="text-[16px] font-medium"
      />
      <div className="flex flex-col gap-[15px] mt-[10px] text-accent-default">
        {filteredData.visits?.length || filteredData.events?.length ? (
          Object.entries(filteredData).map(([event, data], i) =>
            data?.length ? (
              <div key={`calendar_${i}`} className=" flex flex-col gap-[15px]">
                {data?.map((item) => (
                  <div className="flex items-center gap-[10px]" key={item.id}>
                    <img
                      src={
                        process.env.REACT_APP_FILES_URL +
                        "/" +
                        (event === "events"
                          ? item?.photo
                          : item?.project?.photo)
                      }
                      crossOrigin="anonymous"
                      className=" w-[34px] h-[34px] xl:w-[40px] xl:h-[40px] rounded-full"
                      alt=""
                    />
                    <div className="flex flex-col justify-between">
                      <h5 className=" text-[13px] xl:text-[14px] leading-[18px] font-medium">
                        {eventType[event as keyof EventType]}
                      </h5>
                      <p className=" text-[11px] xl:text-[12px] font-medium leading-[16px]">
                        {event === "events" ? item?.title : item?.project?.name}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : null
          )
        ) : (
          <h3 className="text-sm opacity-50">Нет событий</h3>
        )}
      </div>
    </div>
  );
};

export default HomeCalendar;
