import React, { useEffect, useState } from "react";
import ButtonForm from "components/Button/ButtonForm";
import CancelIcon from "components/constant/icons/CancelIcon";
import PencilIcon from "components/constant/icons/PencilIcon";
import PlusIcon from "components/constant/icons/PlusIcon";
import TextField from "components/Form/TextField/TextField";
import MinusIcon from "components/constant/icons/MinusIcon";
import {
  useDeleteCommentMutation,
  useGetCommentsQuery,
  useGetSettingQuery,
  useModifySettingMutation,
  usePostCommentMutation,
  useUpdateCommentMutation,
} from "data/api/profile";
import { ResponseStatusType } from "data/api/exception";
import showToast from "utils/showToast";
import TickIcon from "components/constant/icons/TickIcon";
import { CommentsType } from "data/api/profile/type";
import Loader from "components/Loader";

const AdminSittings = () => {
  const [inputs, setInputs] = useState<CommentsType[]>([{ id: 1, text: "" }]);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [updateBook, setUpdateBook] = useState(false);
  const [updateBookCount, setUpdateBookCout] = useState<number>(0);
  const [updateTrainingCount, setUpdateTrainingCount] = useState<number>(0);
  const [updateTraingBlok, setUpdateTraingBlok] = useState(false);

  const { data: setting, refetch } = useGetSettingQuery();
  const [mutate] = useModifySettingMutation();

  const [commentMutation] = usePostCommentMutation();
  const [commentUpdateMutation] = useUpdateCommentMutation();
  const [deleteCommentMutation] = useDeleteCommentMutation();
  const {
    data: comments,
    isLoading,
    refetch: refetchComments,
  } = useGetCommentsQuery();

  useEffect(() => init(), [setting?.data, comments?.data]);

  function init() {
    if (setting?.data.booking_limit || setting?.data.training_show_date_limit) {
      setUpdateBookCout(setting.data.booking_limit);
      setUpdateTrainingCount(setting.data.training_show_date_limit);
    }
    if (comments?.data) setInputs(comments.data);
  }

  const handleUpdateSettings = async () => {
    const { data: res } = await mutate({
      data: {
        booking_limit: updateBookCount,
        training_show_date_limit: updateTrainingCount,
      },
    });
    if (res?.meta?.type === ResponseStatusType.ERROR) {
      showToast("Что-то пошло не так?", "error", 2000);
    }
    refetch();
  };

  const handleAddInput = () => {
    const isEmptyInputExist = inputs.some((i) => i.text === "");
    if (!isEmptyInputExist) {
      const newId = Date.now();
      const newInput = { id: newId, text: "" };
      setInputs([...inputs, newInput]);
      setEditingId(newId);
    }
  };

  const handleEditInput = (id: number, newValue: string) => {
    setInputs((prevInputs) =>
      prevInputs.map((input) =>
        input.id == id ? { ...input, text: newValue } : input
      )
    );
  };

  const handleAddComment = async (item: CommentsType) => {
    const isExistInData = await comments?.data.some((c) => c.id === item.id);
    if (isExistInData) {
      const { error } = await commentUpdateMutation({
        data: { text: item.text, id: item.id },
      });
      if (error?.message) {
        showToast(error.message, "error", 2000);
        return;
      }
      refetchComments();
      setEditingId(null);
      return;
    } else {
      const { error } = await commentMutation({
        data: { text: item.text },
      });
      setEditingId(null);
      if (error?.message) {
        showToast(error.message, "error", 2000);
        return;
      }
      refetchComments();
    }
  };

  const handleDeleteComment = async (id: number) => {
    const { error } = await deleteCommentMutation(id);
    if (error?.message) {
      showToast(error.message, "error", 2000);
      return;
    }
    refetchComments();
    setInputs((prevInputs) => prevInputs.filter((input) => input.id !== id));
  };

  const handleCancelAddingInput = (id: number) => {
    setInputs((prev) => prev.filter((item) => item.id !== id));
  };

  return (
    <div className="flex flex-col md:flex-row gap-[15px] justify-center">
      {isLoading && <Loader />}
      <div className=" bg-white-default w-full md:w-[660px] rounded-lg p-[20px] sm:p-[30px] flex flex-col gap-5 self-start relative">
        <h3 className=" text-[18px] font-dewi">Причины отмены задач</h3>
        <div className="flex flex-col gap-[15px] sm:gap-[10px]">
          {inputs.map((input) => (
            <div
              key={input.id}
              className="flex flex-col sm:flex-row gap-1 sm:gap-[15px]"
            >
              {editingId === input.id ? (
                <TextField
                  placeholder="Введите текст"
                  value={input.text}
                  onChange={(e) => handleEditInput(input.id, e.target.value)}
                  className="flex-1"
                />
              ) : (
                <h3 className="text-accent-default text-[18px] border border-accent-disabled py-2 px-2 text-center rounded w-full">
                  {input.text}
                </h3>
              )}

              <div className="flex gap-[6px]">
                {editingId !== input.id ? (
                  <>
                    <button
                      onClick={() => setEditingId(input.id)}
                      className="flex-1 sm:w-[44px] h-[44px] outline-none rounded-lg sm:rounded-full border border-accent-default flex items-center justify-center"
                    >
                      <PencilIcon />
                    </button>
                    <button
                      onClick={() => handleDeleteComment(input.id)}
                      className="flex-1 sm:w-[44px] h-[44px] outline-none rounded-lg sm:rounded-full border border-red-default flex items-center justify-center"
                    >
                      <CancelIcon color="#EF3B24" />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => handleAddComment(input)}
                      disabled={!input.text}
                      className={`flex-1 sm:w-[44px] h-[44px] outline-none rounded-lg sm:rounded-full flex items-center justify-center  ${
                        !input.text ? "bg-accent-disabled" : "bg-accent-default"
                      }`}
                    >
                      <TickIcon />
                    </button>
                    <button
                      onClick={() => handleCancelAddingInput(input.id)}
                      className="flex-1 sm:w-[44px] h-[44px] outline-none rounded-lg sm:rounded-full border border-red-default flex items-center justify-center"
                    >
                      <CancelIcon color="#EF3B24" />
                    </button>
                  </>
                )}
              </div>
            </div>
          ))}
          <button
            onClick={handleAddInput}
            className="h-[48px] w-full rounded-lg flex items-center justify-center border border-dashed border-blue-default outline-none"
          >
            <PlusIcon width={28} height={28} color="#3583FF" />
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-[15px] md:self-start">
        <div className=" rounded-lg bg-white-default p-[15px] text-accent-default w-full sm:w-[233px]">
          <p className="text-[16px] font-medium">Бронирований</p>
          {!updateBook ? (
            <>
              <h1 className="text-[20px] font-extrabold font-dewi">
                {updateBookCount}
              </h1>
              <ButtonForm
                onClick={() => setUpdateBook(true)}
                text="Редактировать"
                className="py-3 bg-bg-default text-accent-default w-full hover:bg-accent-default flex items-center justify-center mt-[20px] hover:text-white-default"
              />
            </>
          ) : (
            <div className="flex flex-col gap-5 mt-5">
              <div className="py-3 px-4 flex items-center justify-between border border-boder-default rounded-lg">
                <button
                  className="outline-none p-1"
                  onClick={() =>
                    setUpdateBookCout((prev) => (prev !== 0 ? prev - 1 : 0))
                  }
                >
                  <MinusIcon />
                </button>
                {updateBookCount}
                <button
                  className="outline-none p-1"
                  onClick={() => setUpdateBookCout((prev) => prev + 1)}
                >
                  <PlusIcon color="#37465B" />
                </button>
              </div>
              <div className="flex flex-col gap-[10px]">
                <ButtonForm
                  text="Сохранить"
                  onClick={() => {
                    handleUpdateSettings();
                    setUpdateBook(false);
                  }}
                  className="py-3 bg-accent-default text-white-default w-full hover:bg-accent-disabled flex items-center justify-center hover:text-bg-default"
                />
                <ButtonForm
                  onClick={() => {
                    setUpdateBook(false);
                    init();
                  }}
                  text="Отменить"
                  className="py-3 bg-bg-default text-accent-default w-full hover:bg-accent-default flex items-center justify-center hover:text-white-default"
                />
              </div>
            </div>
          )}
        </div>
        <div className=" rounded-lg bg-white-default p-[15px] text-accent-default w-full sm:w-[233px]">
          <p className="text-[16px] font-medium">
            Отображение обучающего блока
          </p>
          {!updateTraingBlok ? (
            <>
              <h1 className="text-[20px] font-extrabold font-dewi">
                {updateTrainingCount} дня
              </h1>
              <ButtonForm
                onClick={() => setUpdateTraingBlok(true)}
                text="Редактировать"
                className="py-3 bg-bg-default text-accent-default w-full hover:bg-accent-default flex items-center justify-center mt-[20px] hover:text-white-default"
              />
            </>
          ) : (
            <div className="flex flex-col gap-5 mt-5">
              <div className="py-3 px-4 flex items-center justify-between border border-boder-default rounded-lg">
                <button
                  className="outline-none p-1"
                  onClick={() =>
                    setUpdateTrainingCount((prev) =>
                      prev !== 0 ? prev - 1 : 0
                    )
                  }
                >
                  <MinusIcon />
                </button>
                {updateTrainingCount}
                <button
                  className="outline-none p-1"
                  onClick={() => setUpdateTrainingCount((prev) => prev + 1)}
                >
                  <PlusIcon color="#37465B" />
                </button>
              </div>
              <div className="flex flex-col gap-[10px]">
                <ButtonForm
                  text="Сохранить"
                  onClick={() => {
                    setUpdateTraingBlok(false);
                    handleUpdateSettings();
                  }}
                  className="py-3 bg-accent-default text-white-default w-full hover:bg-accent-disabled flex items-center justify-center hover:text-bg-default"
                />
                <ButtonForm
                  onClick={() => {
                    setUpdateTraingBlok(false);
                    init();
                  }}
                  text="Отменить"
                  className="py-3 bg-bg-default text-accent-default w-full hover:bg-accent-default flex items-center justify-center hover:text-white-default"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminSittings;
