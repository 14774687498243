import React from "react";
import ButtonForm from "../../components/Button/ButtonForm";
import Modal from "../../components/Modal";
import LikeIcon from "../../components/constant/icons/LikeIcon";
import EyeIcon from "../../components/constant/icons/EyeIcon";
import CloseWithBorderIcon from "../../components/constant/icons/CloseWithBorderIcon";
import { useWindowSize } from "../../hooks/useWindowSize";
import { News, NEWSRequest } from "data/api/news/type";
import moment from "moment";
import { useCreateNewsMutation, useUpdateNewsMutation } from "data/api/news";

interface NewsPreviewModalData {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  selected: any;
  setSelect: (value: News) => void;
}

const NewsPreviewModal = ({
  isOpen,
  setIsOpen,
  selected,
  setSelect,
}: NewsPreviewModalData) => {
  const { isSm } = useWindowSize();
  const [updateNewsMutate] = useUpdateNewsMutation();
  const [createNewsMutate] = useCreateNewsMutation();

  function handleReturn() {
    setIsOpen(false);
  }

  async function handleSave() {
    if (selected?.id) {
      handleUpdate();
      return;
    } else if (selected) {
      await createNewsMutate(selected);
    }

    setIsOpen(true);
  }
  async function handleUpdate() {
    if (selected?.id)
      await updateNewsMutate({
        data: { ...selected },
        id: selected?.id,
      });

    setIsOpen(true);
  }

  return (
    <Modal
      isOpen={isOpen}
      className="md:w-[910px] w-full p-[0px] sm:p-[20px]  rounded-lg  overflow-auto max-h-[90vh]"
      onClose={() => setIsOpen(true)}
    >
      <div
        className={
          "flex md:justify-start justify-center relative pt-[20px] px-[20px]"
        }
      >
        <h1 className={"font-extrabold text-[20px] font-dewi md:max-w-[80%]"}>
          Предпросмотр
        </h1>

        {!isSm && (
          <div className={"absolute right-0"}>
            <ButtonForm
              text={<CloseWithBorderIcon color={"black"} />}
              className={"p-0 px-[0px!important]"}
              onClick={() => setIsOpen(true)}
            />
          </div>
        )}
      </div>

      <section className="my-[15px] px-[20px]">
        <div>
          <img
            src={`${process.env.REACT_APP_FILES_URL}/${selected?.cover_image}`}
            crossOrigin="anonymous"
            width={"100%"}
            className={"rounded max-h-[463px] object-cover"}
          />
        </div>

        <div className={"mt-[30px]"}>
          {/* <div className="flex gap-[10px] text-[14px] leading-[18px] font-normal text-blue-default">
						{selected?.tags?.map((item, i) => (
							<div
								key={i}
								className="px-[20px] py-[6px] rounded-[100px] bg-light-blue"
							>
								{item}
							</div>
						))}
					</div> */}

          <div className={"flex justify-between pt-[15px]"}>
            <h3 className="text-[24px] leading-[28px] text-accent-default font-semibold">
              {selected?.title}
            </h3>

            <p className={"text-sm text-accent-default"}>
              {selected?.date ? moment(selected.date).format("DD.MM.YYYY") : ""}
            </p>
          </div>

          {selected?.content && (
            <p
              className={"my-[30px] text-accent-default text-sm"}
              dangerouslySetInnerHTML={{ __html: selected?.content }}
            />
          )}

          <div className={"flex justify-between items-end"}>
            {/* <ButtonForm
              leftIcon={<LikeIcon color={"#FFFFFF"} />}
              text="Нравится"
              onClick={() => {}}
              className={
                "max-h-[48px] h-full max-w-[147px] px-6 py-3 w-full justify-center bg-accent-default text-white-default text-ellipsis whitespace-nowrap"
              }
            /> */}

            {/* <div className="flex gap-[15px] items-center  text-[12px] text-accent-disabled font-medium leading-[16px]">
              {(selected?.views_count || selected?.views_count === 0) && (
                <div className="flex items-center justify-center gap-[8px] cursor-pointer">
                  <EyeIcon color="#8E95A1" width="18" />
                  <span>{selected?.likes_count}</span>
                </div>
              )}
              {(selected?.likes_count || selected?.likes_count === 0) && (
                <div className="flex items-center justify-center gap-[8px] cursor-pointer">
                  <LikeIcon />
                  <span>{selected.likes_count}</span>
                </div>
              )}
            </div> */}
          </div>
        </div>
      </section>

      <div
        className={
          "flex justify-end gap-[10px] py-[15px] px-[30px] border-t border-[#D7DCE4]"
        }
      >
        <ButtonForm
          text={"Вернуться к редактированию"}
          className={
            "p-3 bg-bg-default text-accent-default justify-center font-medium w-full max-w-[301px]"
          }
          onClick={handleReturn}
        />

        <ButtonForm
          text={"Опубликовать"}
          className={
            "p-3 bg-accent-default text-white-default justify-center font-medium w-full max-w-[301px]"
          }
          onClick={handleSave}
        />
      </div>
    </Modal>
  );
};

export default NewsPreviewModal;
