import React, { useEffect, useState } from "react";
import ButtonForm from "components/Button/ButtonForm";
import TextField from "components/Form/TextField/TextField";
import Close from "components/constant/icons/Close";
import SearchIcon from "components/constant/icons/SearchIcon";
import { useGlobalSearchQuery } from "data/api/global-search";
import { images } from "utils/resource";
import { formatPhoneNumber } from "hooks/useFormatInput";
import { Link } from "react-router-dom";
import { withDebounce } from "hooks/useDebounce";

type ModuleName = {
  client: string;
  news: string;
  events: string;
  project: string;
};

const GlobalSearch = () => {
  const [searchInput, setSearchInput] = useState<string>("");

  const [searchQuery, setSearchQuery] = useState<string>("");

  const { data, isLoading } = useGlobalSearchQuery(
    searchQuery ? searchQuery : "undifined"
  );

  const [isFocused, setIsFocused] = useState(false);

  const hanldeSearch = async (value: string) => {
    await setSearchQuery(value);
  };

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const classEvent = (event.target as any).name;

      setIsFocused([`global-search`].includes(classEvent));
    };

    document.addEventListener("click", handleDocumentClick);

    return () => document.removeEventListener("click", handleDocumentClick);
  }, []);

  const renderNode = (name: string, data: any) => {
    switch (name) {
      case "news":
        return (
          <Link
            to={`/news/view/${data?.id}`}
            onClick={() => setSearchInput(data?.title)}
          >
            <div>{data?.title}</div>
          </Link>
        );
      case "events":
        return (
          <Link
            to={`/events/view/${data?.id}`}
            onClick={() => setSearchInput(data?.title)}
          >
            <div>{data?.title}</div>
          </Link>
        );
      case "projects":
        return (
          <Link
            to={`/apartments/${data?.id}`}
            onClick={() => setSearchInput(data?.name)}
          >
            <div>{data?.name}</div>
          </Link>
        );

      case "building":
        return <div>{data?.name}</div>;
      default:
        return <div>Unknown result</div>;
    }
  };

  const module_name: ModuleName = {
    client: "Клиенты",
    news: "Новости",
    events: "Мероприятия",
    project: "Проекты",
  };

  function handleClear() {
    setSearchInput("");
  }

  const onChange = (value: string) => {
    const filteredValue = value.replace(/[^a-zA-Z-а-я-А-Я-0-9\s]/g, "");
    setSearchInput(value);
    withDebounce(() => hanldeSearch(filteredValue));
  };

  const openMenu = Boolean(searchInput.length && isFocused);

  return (
    <div className={"relative"}>
      <div className="relative md:pb-0">
        <TextField
          name={`global-search`}
          label={""}
          value={searchInput}
          placeholder="Введите текст для поиска"
          onChangeValue={onChange}
          imgLeft={<SearchIcon />}
          className={``}
          classNameInput={` rounded-[30px!important] w-[260px] md:rounded-[28px!important] py-[6px] pr-[10px]`}
        />
        {searchInput?.length ? (
          <div
            className={
              "search__block__clear absolute top-[50%] right-1 -translate-y-[50%]"
            }
          >
            <ButtonForm
              text={<Close color={"#FF0000"} opacity={"1"} />}
              className={"btn btn-icon bg-white-default"}
              onClick={handleClear}
            />
          </div>
        ) : null}
      </div>
      {openMenu && (
        <div
          className={`md:absolute z-50 top-[calc(100%${"+22px"})] md:max-h-[300px] pb-0 pt-5 md:pt-0 overflow-auto toolbar-scroll left-0 w-full z-10 rounded-bl-lg rounded-br-lg max-h-[70vh] overflow-y-auto toolbar-scroll`}
        >
          <div className="p-0 md:border border-t-0 border-solid border-bg-default bg-white-default">
            {!isLoading ? (
              <>
                {!isLoading && data?.data?.length === 0 && (
                  <p className="text-center py-8">Пусто</p>
                )}
                {data?.data?.map((item) => (
                  <div
                    className=""
                    key={"search__block-item" + item.module_name}
                  >
                    <h3 className="text-sm pt-2 px-4 text-accent-default opacity-50 font-medium">
                      {module_name[item.module_name as keyof ModuleName]}
                    </h3>
                    {item.module_name !== "building" &&
                      item.data?.slice(0, 3).map((i) => (
                        <div
                          key={i.id}
                          className="px-[15px] py-[8px] cursor-pointer hover:bg-accent-default hover:text-white-default md:border-0 border-t-[1px] border-bg-default"
                        >
                          {item.module_name === "client" ? (
                            <Link
                              to={`/base-clients?id=${i.id}`}
                              onClick={() => setSearchInput(i.fullname)}
                            >
                              <p className={"text-sm font-medium mb-[5px]"}>
                                {i.fullname}
                              </p>
                              <p className={"text-sm font-normal"}>
                                +7 {formatPhoneNumber(i?.phone_number || "")}
                              </p>
                            </Link>
                          ) : (
                            <div className=" font-medium">
                              {renderNode(item.module_name, i)}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                ))}
              </>
            ) : (
              <div className="flex py-4 justify-center">
                <img
                  src={images.loaderImg}
                  alt="loading img"
                  className="animate-spin-slow w-8"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobalSearch;
