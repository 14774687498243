import React, { useEffect, useState } from "react";
import {
  useGetAllTrainingCategoriesQuery,
  useGetAllTrainingsQuery,
  useHandleCreateTrainingMutation,
} from "data/api/training";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import AnimationFrame from "components/AnimationFrame.tsx";
import ButtonForm from "components/Button/ButtonForm";
import Loader from "components/Loader";
import { useWindowSize } from "hooks/useWindowSize";
import CreateQuestionModal from "./CreateQuestionModal";
import CreateCategoryModal from "./CreateCategoryModal";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  handleInitTrainingCategories,
  handleInitTrainings,
  handleReset,
  handleResetDelete,
  resetSubmitTraining,
} from "store/TrainingReducer";
import { TrainingType } from "data/api/training/type";
import { ResponseStatusType } from "data/api/exception";
import showToast from "utils/showToast";

import TrainingAndCategoryListItems from "./ListItem";
import PencilIcon from "components/constant/icons/PencilIcon";
import NotFound from "components/NotFound";

const TrainingEditPage = () => {
  const [isCreateQuestionModalOpen, setIsCreateQuestionModalOpen] =
    useState(false);

  const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] =
    useState(false);

  const sensors = useSensors(useSensor(PointerSensor));

  const [loading, setLoading] = useState(false);

  const [categoryId, setCategoryId] = useState<{
    ref_id: string | undefined;
    id: number;
  }>();

  const [updateTraining, setUpdateTraining] = useState<TrainingType>();

  const navigate = useNavigate();

  const { data: trainings, isLoading, refetch } = useGetAllTrainingsQuery();

  const { data: categories, refetch: refetchCategories } =
    useGetAllTrainingCategoriesQuery();

  const [bulkMutation] = useHandleCreateTrainingMutation();

  const selector = useSelector((state: RootState) => state.training);

  const windowSize = useWindowSize();

  const dispatch = useDispatch();

  useEffect(() => {
    initTrainings();
  }, [trainings]);

  useEffect(() => {
    initCategories();
  }, [categories]);

  function initTrainings() {
    if (trainings?.data) dispatch(handleInitTrainings(trainings.data));
  }
  function initCategories() {
    if (categories?.data)
      dispatch(handleInitTrainingCategories(categories.data));
  }

  const handleSubmit = async () => {
    const bulk = {
      update: {
        trainings: selector.trainings.filter((training) =>
          training.ref_id?.startsWith("old-")
        ),

        categories: selector.categories.filter((categ) =>
          categ.ref_id?.startsWith("old-")
        ),
      },
      delete: {
        trainings: selector.deletedTrainings,
        categories: selector.deletedCategories,
      },
      create: {
        trainings: selector.categories.flatMap((category) =>
          category.training.filter((training) =>
            training.ref_id?.startsWith("new-")
          )
        ),
        categories: selector.categories.filter((categ) =>
          categ.ref_id?.startsWith("new-")
        ),
      },
    };
    const hasChange =
      bulk.create.categories.length ||
      bulk.create.trainings.length ||
      bulk.update.trainings.length ||
      bulk.update.categories.length ||
      bulk.delete.trainings.length ||
      bulk.delete.categories.length;

    if (hasChange) {
      setLoading(true);
      const { data: res, error } = await bulkMutation({
        data: { data: { ...bulk } },
      });
      if (res?.meta?.type === ResponseStatusType.SUCCESS) {
        showToast("Обучение успешно создано!", "success", 2000);
        refetch();
        refetchCategories();
        dispatch(handleReset());
        dispatch(handleResetDelete());
        navigate("/training");
        setLoading(false);
        dispatch(resetSubmitTraining());
        return;
      }
      if (error?.message) {
        showToast(error?.message, "error", 2000);
        setLoading(false);
        return;
      }
    } else {
      navigate("/training");
      dispatch(resetSubmitTraining());
    }
  };

  useEffect(() => {
    if (selector.isSubmitTriggered) {
      handleSubmit();
    }
  }, [selector.isSubmitTriggered]);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = selector.categories.findIndex(
        (cat) => cat.id === active.id
      );
      const newIndex = selector.categories.findIndex(
        (cat) => cat.id === over.id
      );
      const newCategories = arrayMove(selector.categories, oldIndex, newIndex);
      dispatch(
        handleInitTrainingCategories(
          newCategories.map((category, index) => ({
            ...category,
            sequnce_id: index + 1,
            ref_id: category.ref_id?.startsWith("new-")
              ? category.ref_id
              : `old-${category.id}`,
          }))
        )
      );
    }
  };

  return (
    <div className="relative">
      <div className="br-container py-3 sm:py-5 min-h-[50vh]">
        {/* <div className="flex items-center justify-between mb-2">
          <TrainingCategories
            categories={categories?.data}
            handleSelectCategory={handleSelectCategory}
            category={category}
          />
          {windowSize.isLg ? (
            <TextField
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              imgLeft={<SearchIcon />}
              className="  w-[332px] outline-none"
              placeholder="Поиск"
            />
          ) : null}
        </div> */}
        <div className="flex items-center gap-4 mb-3">
          <button
            onClick={() => setIsCreateCategoryModalOpen(true)}
            className=" w-[40px] h-[40px] outline-none rounded-full bg-accent-default flex items-center justify-center lg:hidden "
          >
            <PencilIcon size={19} color="#ffffff" />
          </button>
        </div>
        {isLoading && <Loader />}

        {!selector.categories.length ? (
          <NotFound />
        ) : (
          <AnimationFrame>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={selector.categories?.map((category) => category.id)}
              >
                <div className="w-full p-[20px] sm:p-[30px] rounded-[8px] bg-white-default grid  lg:grid-cols-2 gap-6 container">
                  {selector.categories.map((categ) => (
                    <TrainingAndCategoryListItems
                      key={categ.id}
                      categ={categ}
                      setIsCreateQuestionModalOpen={
                        setIsCreateQuestionModalOpen
                      }
                      setCategoryId={setCategoryId}
                      setUpdateTraining={setUpdateTraining}
                    />
                  ))}
                </div>
              </SortableContext>
            </DndContext>
          </AnimationFrame>
        )}
      </div>
      {windowSize.isLg ? (
        <div className=" bg-white-default h-[78px] w-full sticky bottom-0 border border-t border-boder-default flex items-center">
          <div className="br-container flex justify-between w-full">
            <ButtonForm
              onClick={() => setIsCreateCategoryModalOpen(true)}
              text="Редактировать темы"
              className="py-3 px-6 border border-accent-default rounded hover:bg-accent-default hover:text-white-default"
            />
            <div className="flex gap-[10px]">
              <ButtonForm
                text="Отмена"
                onClick={() => navigate("/training")}
                className="py-3 px-6 border border-red-default rounded text-red-default hover:bg-red-default hover:text-white-default w-[214px] flex items-center justify-center"
              />
              <ButtonForm
                onClick={handleSubmit}
                disabled={loading}
                text="Сохранить"
                className="py-3 px-6  rounded bg-accent-default text-white-default hover:bg-accent-disabled hover:text-bg-default  w-[214px] flex items-center justify-center"
              />
            </div>
          </div>
        </div>
      ) : null}

      <CreateQuestionModal
        setUpdateTraining={setUpdateTraining}
        categoryId={categoryId}
        updateTraining={updateTraining}
        isCreateQuestionModalOpen={isCreateQuestionModalOpen}
        setIsCreateQuestionModalOpen={setIsCreateQuestionModalOpen}
      />
      <CreateCategoryModal
        isCreateCategoryModalOpen={isCreateCategoryModalOpen}
        setIsCreateCategoryModalOpen={setIsCreateCategoryModalOpen}
      />
    </div>
  );
};

export default TrainingEditPage;
