import React from "react";
import TickIcon from "components/constant/icons/TickIcon";

type propsType = {
  name?: string;
  label?: string;
  className?: string;
  onChange?: (e: boolean) => void;
  value?: boolean;
};

const CheckBox = ({
  name,
  label,
  className,
  onChange,
  value = false,
}: propsType) => {
  const handleClick = () => {
    const newChecked = !value;
    onChange?.(newChecked);
  };

  return (
    <div
      className="flex items-center gap-[10px] cursor-pointer relative"
      onClick={handleClick}
    >
      <div
        className={` peer relative appearance-none border-[2.4px] border-accent-disabled w-[20px] h-[20px] 
        rounded-[6px] ${className}
      focus:outline-none 
     ${
       value
         ? "border-none bg-accent-default bg-blue-800 "
         : "bg-steel-400 border-steel-400"
     } `}
      ></div>
      <div
        className={`absolute 
      w-[11px] h-[9px] stroke-white-default ml-[5px] outline-none`}
      >
        {value && <TickIcon />}
      </div>
      <label
        className=" text-accent-default cursor-pointer text-[14px] leading-[24px]"
        htmlFor={name}
      >
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
