import React from "react";
import AuthPageWraper from "components/AuthPageWraper";
import { useAppSelector } from "store";
import { selectUser } from "store/UserReducer/selectors";
import { Navigate } from "react-router";
import ButtonForm from "components/Button/ButtonForm";
import { useNavigate } from "react-router-dom";

const WaitingPage = () => {
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();

  if (user?.data.is_verified) {
    return <Navigate to="/" replace />;
  }

  const handleRefresh = () => {
    navigate(0);
  };
  const handleExist = () => {
    navigate("/sign-in");
  };

  return (
    <AuthPageWraper isRegisterPage={true}>
      <h3 className="text-center text-accent-default font-extrabold text-[25px] font-dewi m-0 mb-[10px]">
        Заяфка отправлена
      </h3>
      <p className="text-accent-default text-sm text-center font-normal">
        Доступ на платформу будет предоставлен после обработки заявки{" "}
      </p>
      <div className="flex flex-col gap-[10px] mt-[20px] ">
        <ButtonForm
          className="py-[12px] w-full
               text-white-default text-center flex justify-center bg-accent-default
                hover:bg-accent-disabled
               hover:text-bg-default"
          text="Обновить"
          onClick={handleRefresh}
        />
        <ButtonForm
          className="py-[12px] w-full
              text-white-default text-center flex justify-center bg-accent-default
               hover:bg-accent-disabled
               hover:text-bg-default"
          text="Выйти"
          onClick={handleExist}
        />
      </div>
    </AuthPageWraper>
  );
};

export default WaitingPage;
