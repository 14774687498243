import ButtonForm from "../../components/Button/ButtonForm";
import Label from "../../components/Form/Label";
import Dropdown from "../../components/Form/Dropdown/Dropdown";
import { primisesTypeData } from "../Apartment/data";
import MultiRangeSlider from "../../components/Form/MultiRangeSlider";
import { useWindowSize } from "../../hooks/useWindowSize";

interface ExchangeFilterProps {
	valueFilter: any
	setValueFilter: (key: string, value: any) => void;
	handleToggleRooms: (value: any) => void;
	handleApplication: () => void;
	handleSave?: () => void;
}

const ExchangeFilter = (
	{
		valueFilter,
		setValueFilter,
		handleToggleRooms,
		handleApplication,
		handleSave
	}: ExchangeFilterProps
) => {
	const { isMd } = useWindowSize();

	return (
		<div className="flex lg:flex-row flex-col justify-between gap-[10px] mt-[20px] w-full">
			<Label label="Город" className={"w-full gap-[10px]"}>
				<Dropdown
					onChange={(value) => setValueFilter("type", value)}
					options={primisesTypeData}
					name="type"
					placeholder="Все"
					className="w-full"
					value={valueFilter?.type}
				/>
			</Label>
			<Label label="Застройщик" className={"w-full gap-[10px]"}>
				<Dropdown
					onChange={(value) => setValueFilter("develop", value)}
					options={primisesTypeData}
					name="develop"
					placeholder="Все"
					className="w-full"
					value={valueFilter?.develop}
				/>
			</Label>
			<Label label={"Стоимость"} className={"w-full gap-[10px]"}>
				<div className={"bg-white-default"}>
					<MultiRangeSlider
						onChange={(value) => {
							setValueFilter("min_price", value.min.toString());
							setValueFilter("max_price", value.max.toString());
						}}
						max={43000000}
						min={0}
						step={1}
						value={[valueFilter?.min_price?.toString(), valueFilter?.max_price?.toString()]}
					/>
				</div>
			</Label>
			<Label label="Комнат" className={"gap-[10px]"}>
				<div className="flex items-center gap-[10px]">
					{["1", "2", "3", "4", "5+"].map((item) => (
						<div
							onClick={() => handleToggleRooms(item)}
							key={item}
							className={`text-[16px] font-normal w-[46px] h-[45px] border border-light-blue-10 
               			leading-[18.75px] rounded-full text-accent-default cursor-pointer
             				flex items-center justify-center ${
								item == valueFilter?.rooms &&
								"bg-accent-default text-white-default"
							} `}
						>
							{item}
						</div>
					))}
				</div>
			</Label>
			<div className="flex flex-col lg:ml-[30px] gap-[10px]">
				{isMd ? (
					<>
						<ButtonForm
							text="Применить"
							className={`bg-accent-default text-white-default mt-[22px] py-3 px-[64px]
             	flex justify-center
              w-full whitespace-nowrap`}
							onClick={handleSave}
						/>
						<ButtonForm
							text="Сбросить"
							className={`bg-bg-default text-accent-default py-3 px-[64px]
             	flex justify-center
              w-full whitespace-nowrap`}
							onClick={() => {
								setValueFilter("type", null);
								setValueFilter("develop", null);
								setValueFilter("min_price", 0);
								setValueFilter("max_price", 43000000);
								handleToggleRooms(null)
							}}
						/>
					</>
					) : (
					<ButtonForm
						text="Заявка на обмен"
						className={`bg-accent-default text-white-default mt-[22px] py-3 px-[64px]
             	hover:bg-accent-default flex justify-center
              hover:text-white-default w-full whitespace-nowrap`}
						onClick={handleApplication}
					/>
				)}
			</div>
		</div>
	)
}

export default ExchangeFilter;