import { useEffect, useState } from "react";
import { Project } from "modules/Deal/type";
import { useGetSectionsByBuildingIdMutation } from "data/api/sections";
import { ISection } from "data/api/sections/type";

interface UseGetSectionByBuildingIdProps {
  buildingId?: number;
}

const useGetSectionByBuildingId = ({
  buildingId,
}: UseGetSectionByBuildingIdProps) => {
  const [sections, setSections] = useState<ISection[] | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [getSectionByBuildingId] = useGetSectionsByBuildingIdMutation();

  useEffect(() => {
    if (buildingId) {
      const fetchSections = async () => {
        setIsLoading(true);
        try {
          const { data: res } = await getSectionByBuildingId(
            buildingId
          ).unwrap();
          setSections(res);
        } catch (err) {
          console.log("Failed to fetch section data.");
        } finally {
          setIsLoading(false);
        }
      };

      fetchSections();
    }
  }, [buildingId, getSectionByBuildingId]);

  return { sections, isLoading };
};

export default useGetSectionByBuildingId;
