import React, { FC, ReactNode, useState } from "react";
import ArrowTableIcon from "../../constant/icons/Table/ArrowTableIcon";
import SpinnerIcon from "../../constant/icons/SpinnerIcon";
import RadioButton from "components/RadioSelectionButton";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { formatPhoneNumber } from "hooks/useFormatInput";
import { motion } from "framer-motion";
import { checkExpirationStatus } from "utils/checkdateExpirationDate";
import {
  expireDateColumn,
  getItemStatusParking,
} from "components/Form/Table/utils";

export interface DataItem {
  [key: string]: any;
}

export interface TableColumn {
  key: keyof DataItem;
  title: string;
  width?: string;
  sortable?: boolean;
}

interface TableContainerProps {
  header: TableColumn[];
  content: DataItem[];
  setData?: (list: DataItem[]) => void;
  sortable?: boolean;
  textContentEmpty?: string | ReactNode;
  onRightClickTrContent?: (e: MouseEvent, item: DataItem) => void;
  onLeftClickTrContent?: (e: DataItem) => void;
  isLastRightText?: boolean;
  loading?: boolean;
  tableBodyUnderBlock?: any;
  doSelectData?: boolean;
  handleSelectTableData?: (id: number) => void;
  handleClickTableItem?: () => void;
  handleSortBy?: (sort_by: string, order_by: string) => void;
}

const TableContainer: FC<TableContainerProps> = ({
  handleSortBy,
  header,
  content,
  setData,
  sortable = true,
  textContentEmpty = "Пусто",
  onRightClickTrContent,
  onLeftClickTrContent,
  isLastRightText = true,
  loading = false,
  tableBodyUnderBlock,
  doSelectData,
  handleSelectTableData,
  handleClickTableItem,
}) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof DataItem;
    direction: string;
  } | null>(null);
  const selection = useSelector((state: RootState) => state.apartment);

  const handleSort = (column: TableColumn) => {
    if (!column.sortable && !sortable) return;

    let direction = "ASC";
    if (
      sortConfig &&
      sortConfig.key === column.key &&
      sortConfig.direction === "ASC"
    ) {
      direction = "DESC";
    }
    setSortConfig({ key: column.key, direction });

    const data: any = {
      name: "fullname",
      fixing_type: "fixing_type",
      date: "actived_date",
      datePin: "expiration_date",
    };
    handleSortBy?.(data[column.key], direction);
  };

  const itemExists = (id: number) => {
    return selection.storedData.includes(id);
  };

  return (
    <div className="overflow-x-auto">
      <table className="table-auto border-collapse min-w-full bg-white-default rounded-lg">
        <thead className="table__header">
          <tr className={""}>
            {doSelectData && <th className=""></th>}
            {header.map((column, idx) => {
              const isDESC =
                sortConfig?.key === column.key &&
                sortConfig.direction === "DESC";

              return (
                <th
                  key={column.key}
                  style={{ width: column.width }}
                  className={
                    idx === header.length - 1 && isLastRightText
                      ? "py-4 px-[15px] 2xl:px-5"
                      : "py-4 px-[15px] 2xl:px-5"
                  }
                >
                  <div className={"table__header__th flex"}>
                    <p className={"mr-2 text-[14px] 2xl:text-base font-normal"}>
                      {column.title}
                    </p>

                    {(column.sortable || sortable) && (
                      <button
                        onClick={() => handleSort(column)}
                        className={`transition-all ${
                          isDESC ? "rotate-180" : ""
                        }`}
                      >
                        {sortConfig?.direction &&
                        sortConfig.direction === "ASC" ? (
                          <ArrowTableIcon />
                        ) : (
                          <ArrowTableIcon />
                        )}
                      </button>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody
          className={`table__content ${
            loading ? "table__content-loading" : ""
          }`}
        >
          {content.length ? (
            content.map((item, index) => (
              <React.Fragment key={`table-content-item-${index}`}>
                <tr
                  key={index}
                  onContextMenu={(e: any) => {
                    onRightClickTrContent && onRightClickTrContent(e, item);
                  }}
                  onClick={() => {
                    onLeftClickTrContent && onLeftClickTrContent(item);
                  }}
                  className={
                    "cursor-pointer w-full p-1 border-t-[1px] border-boder-default hover:bg-bg-default"
                  }
                >
                  {doSelectData && (
                    <td className="py-6 pl-[20px]">
                      <div className="pt-[6px]">
                        <RadioButton
                          onChange={() => handleSelectTableData?.(item.id)}
                          checked={itemExists(item.id)}
                        />
                      </div>
                    </td>
                  )}
                  {header.map((column, idx) => {
                    return (
                      <td
                        onClick={handleClickTableItem}
                        key={column.key}
                        colSpan={idx === header.length - 1 ? header.length : 1}
                        className={`py-6 px-3 text-[14px] 2xl:text-[16px] 2xl:px-7 font-normal truncate max-w-[320px] ${
                          idx === header.length - 1 && isLastRightText
                            ? "last"
                            : ""
                        } ${
                          column.title === "Закрепление" && " text-blue-default"
                        }`}
                      >
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 20 }}
                          transition={{ duration: 0.6 }}
                        >
                          {typeof item[column.key] === "object"
                            ? column.key == "datePin"
                              ? expireDateColumn(item[column.key]?.text)
                              : item[column.key]?.text
                            : column.title === "Номер телефона"
                            ? "+7 " +
                              formatPhoneNumber(item[column.key].slice(1))
                            : column.key === "status"
                            ? getItemStatusParking(item)
                            : item[column.key]}
                        </motion.div>
                      </td>
                    );
                  })}
                </tr>
                {tableBodyUnderBlock && tableBodyUnderBlock(item)}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan={header.length}>
                <div className={"flex justify-center items-center"}>
                  {loading ? (
                    <>
                      <div
                        className="flex justify-center items-center h-full px-[20px] py-[10px]"
                        style={{ position: "relative" }}
                      >
                        <SpinnerIcon />
                      </div>
                    </>
                  ) : (
                    <p className={"m-6"}>{textContentEmpty}</p>
                  )}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableContainer;
