import { RootState } from "store";

export const selectUser = (state: RootState) => state.user.data;

export const selectUserIsLoading = (state: RootState) =>
  state.user.meta.loading;

export const selectUserError = (state: RootState) => state.user.meta.error;

export const selectUserUpdatIsLoading = (state: RootState) =>
  state.user.updateMeta.loading;
export const selectUserUpdateError = (state: RootState) =>
  state.user.updateMeta.error;
