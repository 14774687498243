import React, { useState } from "react";
import { AnalyticsFilter } from "./FilterAnalytics";
import BarChartComponent from "./charts/BarChart";
import { data } from "./data";
import { useRoles } from "hooks/useRoles";
import AnalyticsMainInfoList from "./AnalyticsMainInfoList";

import NewsEventsTrainingComponent from "./NewsEventsTrainingApi";
import moment from "moment";

export interface IFiltersAnalytics {
  city_id: number;
  fromDate: string;
  toDate: string;
}

const Analitics = () => {
  const [filter, setFilter] = useState<IFiltersAnalytics>({
    city_id: 0,
    fromDate: moment().startOf("day").toISOString(),
    toDate: moment().endOf("day").toISOString(),
  });

  const roles = useRoles(null);

  return (
    <section id="analytics" className="min-h-[50vh]">
      <div className="br-container py-6 flex flex-col gap-6">
        <AnalyticsFilter setFilter={setFilter} filter={filter} />
        {roles.isAdmin && (
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[10px] order-2">
            <BarChartComponent
              data={data.registeredAgentChart.data}
              title={data.registeredAgentChart.title}
            />
            <BarChartComponent
              data={data.activeAgentChart.data}
              title={data.activeAgentChart.title}
            />
            <BarChartComponent
              data={data.cityChart.data}
              title={data.cityChart.title}
            />
          </div>
        )}
        <AnalyticsMainInfoList filter={filter} />
        <NewsEventsTrainingComponent filter={filter} />
      </div>
    </section>
  );
};

export default Analitics;
