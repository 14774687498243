import { useState } from "react";
import { EventContentArg } from "@fullcalendar/core";

interface FullCalendarComponentEventItemProps {
	info: EventContentArg;
	item: any;
	isMobile?: boolean;
}

export const FullCalendarComponentEventItem = (
	{
		info,
		item,
		isMobile
	}: FullCalendarComponentEventItemProps
) => {
  const { event } = info;
  const [showTooltip, setShowTooltip] = useState(false);

	const isEvent = event.title === "Мероприятие"

  const endSeconds = event.end ? new Date(event.end).getTime() / 1000 : 0;
  const startSeconds = event.start ? new Date(event.start).getTime() / 1000 : 0;
  const timeSeconds = endSeconds - startSeconds;

  const startDate = new Date(item?.rrule?.dtstart);
  const eventDate = event.start ? new Date(event.start) : new Date();
  const isFirstDay = item?.rrule?.dtstart
    ? startDate.toDateString() === eventDate.toDateString()
    : true;

  const getDateByFormat = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const toggleTooltip = () => {
		if (isMobile) return;

    setShowTooltip(!showTooltip);
  };

	const isTitleShow = () => {
		return isMobile ? !item?.text : event?.title
	}

	const isProjectShow = () => {
		return isMobile ? false : (item?.project && !isEvent)
	}

  return (
    <>
      <div
        className={`cursor-pointer overflow-hidden md:rounded-lg transition-all p-[10px] md:border border-l-[3px] md:border-solid calendar-item`}
        aria-haspopup="true"
        onMouseEnter={toggleTooltip}
        onMouseLeave={toggleTooltip}
        style={
          !isFirstDay
            ? { display: "none" }
            : {
                backgroundColor: item?.backgroundColor,
                position: showTooltip ? "absolute" : "relative",
                width: showTooltip ? "285px" : "auto",
                borderColor: isMobile ? item?.backgroundColor : (showTooltip ? "#D7DCE4" : "transparent"),
              }
        }
      >
        {isTitleShow() && (
          <p
						className={"text-sm font-medium mb-[4px]"}
						style={{
							color: !isEvent ? "#3583FF" : "#37465B"
						}}
					>
						{event.title}
					</p>
        )}

        <>
          {item?.text && <p className={"text-sm mb-[6px]"}>{item.text}</p>}
          {isProjectShow() && <p className={"text-sm mb-[10px] text-accent-default"}>{item.project}</p>}

          {/*<div*/}
          {/*  className={*/}
          {/*    "flex items-center py-[5px] px-[6px] bg-white-default rounded max-w-[97px] overflow-hidden"*/}
          {/*  }*/}
          {/*>*/}
          {/*  <div>*/}
          {/*    <Calendar width={18} height={18} />*/}
          {/*  </div>*/}

          {/*  <p className={"text-xs font-medium opacity-50 ml-[5px]"}>*/}
          {/*    {getDateByFormat(event.start)}*/}
          {/*  </p>*/}
          {/*</div>*/}
        </>

        {showTooltip && (
          <div className={"border-t-[1px] border-[#D7DCE4] pt-3 mt-3"}>
						{isEvent ? (
							<div className={"mb-[10px]"}>
								<p
									className={"text-sm font-medium text-accent-default mb-[10px]"}
								>
									Дата мероприятия:
									<span className={"font-normal ml-[10px]"}>{getDateByFormat(new Date(item?.start))}</span>
								</p>

								<p
									className={"text-sm font-medium text-accent-default mb-[10px]"}
								>
									Тип мероприятия:
									<span className={"font-normal ml-[10px]"}>{item?.type}</span>
								</p>

								<div className={"flex"}>
									<div className={"flex px-[13px] py-[7px] rounded-xl bg-white-default"}>
										<p className={"text-sm font-medium text-accent-default"}>
											{item?.is_signer}
										</p>
									</div>
								</div>
							</div>
						) : (
							<div className={"mb-[10px]"}>
								<p
									className={"text-sm font-medium text-accent-default mb-[10px]"}
								>
									Дата показа:
									<span className={"font-normal ml-[10px]"}>{getDateByFormat(new Date(item?.start))}</span>
								</p>

								<p
									className={"text-sm font-medium text-accent-default mb-[10px]"}
								>
									Имя:
									<span className={"font-normal ml-[10px]"}>{item?.name}</span>
								</p>

								<p
									className={"text-sm font-medium text-accent-default mb-[10px]"}
								>
									Номер:
									<span className={"font-normal ml-[10px]"}>{item?.number}</span>
								</p>
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};
