import { FC, useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import MyCustomUploadAdapterPlugin from "../../../utils/UploadImageByEditor";

import "@ckeditor/ckeditor5-build-classic/build/translations/ru";

interface EditorProps {
  value?: any;
  onChangeValue?: (value: string) => void;
}

export const Editor: FC<EditorProps> = ({ value, onChangeValue }) => {
  const [valueLocal, setValue] = useState<string>(value);

  const onChangeLocalValue = (value: string) => {
    setValue(value);

    if (onChangeValue) onChangeValue(value);
  };

  useEffect(() => {
    if (value !== valueLocal) setValue(value);
  }, [value]);

  return (
    <div className="App prose">
      <CKEditor
        editor={ClassicEditor}
        data={valueLocal}
        onChange={(event: any, editor) => {
          const data = editor.getData();
          onChangeLocalValue(data);
        }}
        config={{
          extraPlugins: [MyCustomUploadAdapterPlugin],
          toolbar: [
            "alignment",
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "imageUpload",
            "blockQuote",
            "insertTable",
            "mediaEmbed",
          ],
          placeholder: "Начните писать текст здесь...",
          language: "ru",
          mediaEmbed: {
            previewsInData: false,
          },
        }}
      />
    </div>
  );
};
