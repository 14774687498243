import React, { useEffect, useState } from "react";
import { ActiveClientToggler } from "../BaseClients/BaseClientsFilters";
import { HeaderTitleToggle } from "../../components/ToggleSwitcher/HeaderTitleToggle";
import { images } from "../../utils/resource";
import ButtonForm from "../../components/Button/ButtonForm";
import ExchangeAdvantages1 from "../../components/constant/icons/Exchange/ExchangeAdvantages1";
import ExchangeAdvantages4 from "../../components/constant/icons/Exchange/ExchangeAdvantages4";
import ExchangeAdvantages2 from "../../components/constant/icons/Exchange/ExchangeAdvantages2";
import ExchangeAdvantages3 from "../../components/constant/icons/Exchange/ExchangeAdvantages3";
import { Filters } from "../../store/ApartmentReducer/type";
import ExchangeApartmentCard from "./ExchangeApartmentCard";
import ExchangeFilter from "./ExchangeFilter";
import ExchangeModalApplicationPhone from "./ExchangeModalApplicationPhone";
import ExchangeModalApplication from "./ExchangeModalApplication";
import MyApplication from "./MyApplication";
import PencilIcon from "../../components/constant/icons/PencilIcon";
import { CloseIcon } from "yet-another-react-lightbox";
import ToggleSwitcher from "../../components/ToggleSwitcher";
import TrashIcon from "../../components/constant/icons/TrashIcon";
import { useDispatch, useSelector } from "react-redux";
import { getIsOpenFilters, getIsOpenModal, handleOpenFilters, handleOpenModal } from "../../store/BaseClientsReducer";
import { useWindowSize } from "../../hooks/useWindowSize";
import Modal from "../../components/Modal";

const ExchangePage = () => {
	const { isMd } = useWindowSize();
	const dispatch = useDispatch();

	const isActiveEditMobile = useSelector(getIsOpenModal);
	const storeIsOpenFilters = useSelector(getIsOpenFilters);

	const infoList = [
		{
			icon: <ExchangeAdvantages1/>,
			title: "Выкупим",
			text: "Вашу квартиру, даже если она в ипотеке"
		},
		{
			icon: <ExchangeAdvantages2/>,
			title: "Обменяем",
			text: "Вашу готовую квартиру от других застройщиков"
		},
		{
			icon: <ExchangeAdvantages3/>,
			title: "Обмен с проживанием",
			text: "Живите в своей квартире до 3 мес. после продажи. Это бесплатно"
		},
		{
			icon: <ExchangeAdvantages4/>,
			title: "Вернём",
			text: "Часть стоимости квартиры наличными, если нужны средства на ремонт или аренду"
		}
	];

	const [activeClient, setActiveClientFilter] = useState<ActiveClientToggler>(
		ActiveClientToggler.ACTIVE
	);

	const [localFilters, setLocalFilters] = useState<any>({
		type: "",
	});

	const [applicationPhoneModalOpen, setApplicationPhoneModalOpen] = useState(false);
	const [applicationModalOpen, setApplicationModalOpen] = useState(false);
	const [isEditPage, setIsEditPage] = useState(isActiveEditMobile || false);
	const [isToggle, setIsToggle] = useState(false);

	useEffect(() => {
		setIsEditPage(isActiveEditMobile)
	}, [isActiveEditMobile]);

	function handleApplication() {
		setApplicationModalOpen(true)
	}

	function updateLocalFilter(key: keyof Filters, value: string) {
		setLocalFilters((prevFilters: any) => ({
			...((prevFilters as Filters) || null),
			[key]: value,
		}));
	}

	function handleToggleRooms(item: string) {
		if (item === localFilters?.rooms) {
			updateLocalFilter("rooms", "");
			return;
		}
		updateLocalFilter("rooms", item);
	}

	return (
		<main className={"bg-bg-default"}>
			<div className="br-container pt-[20px] pb-[30px] min-h-[50vh]">
				<div className="flex flex-col">
					<HeaderTitleToggle
						title={"Обмен"}
						activeText={"Доступные квартиры"}
						allText={"Статус по заявкам на обмен"}
						activeClient={activeClient}
						setActiveClientFilter={setActiveClientFilter}
						hasTitle={!isMd}
					/>
				</div>

				{activeClient ? (
					<div>
						<div className={"rounded-[32px] overflow-hidden bg-white-default mb-[30px]"}>
							<div className={`relative before:content-[''] before:absolute
    						before:bg-[linear-gradient(180deg,_#00000000_0%,_#000000a8_100%)]
    						before:top-0 before:bottom-0 before:w-full before:z-10`}>
								<img src={images.carouselImg} className={"max-h-[320px] min-h-[320px] w-full"}/>

								{isEditPage && (
									<div className={"absolute top-0 left-0 right-0 py-[20px] pl-2 pr-[20px] gap-[20px] flex justify-between z-10"}>
										<ToggleSwitcher
											label=""
											isToggle={isToggle}
											setIsToggle={(value) => setIsToggle(!value)}
										/>

										<div className={"flex gap-[5px]"}>
											<ButtonForm
												text={
													<span className={"pointer-events-none"}>
													<PencilIcon/>
												</span>
												}
												onClick={() => {
												}}
												className={
													"p-[5px!important] bg-white-default rounded-xl border border-bg-default cursor-pointer"
												}
											/>
											<ButtonForm
												text={
													<span className={"pointer-events-none"}>
													<TrashIcon/>
												</span>
												}
												onClick={() => {
												}}
												className={
													"p-[5px!important] bg-white-default rounded-xl border border-bg-default cursor-pointer"
												}
											/>
										</div>
									</div>
								)}

								<div className={"absolute bottom-0 left-0 p-[20px] gap-[20px] flex flex-col z-10"}>
									<h3 className={"text-white-default text-[25px] font-dewi font-bold"}>Обмен квартиры в Бруснике</h3>

									<ButtonForm
										className="py-[12px] w-full
             		text-accent-default text-center flex justify-center bg-bg-default
               	hover:bg-accent-default
               	hover:text-white-default md:max-w-[240px]"
										text="Заявка на обмен"
										onClick={handleApplication}
									/>
								</div>
							</div>

							<div className={"flex md:flex-row flex-col lg:flex-nowrap flex-wrap"}>
								{infoList.map((item, idx) => (
									<div
										key={`info-item-${idx}`}
										className={"flex py-[30px] lg:w-[33.33%] md:w-[50%]"}
									>
										<div
											key={`info-item-${idx}`}
											className={"flex flex-col gap-[12px] px-[30px] w-full"}
										>
											<div>{item.icon}</div>

											<h4 className={"text-[20px] font-dewi font-bold text-accent-default"}>{item.title}</h4>

											<p className={"text-sm font-normal text-accent-default opacity-50 max-w-[166px]"}>{item.text}</p>
										</div>

										{(idx !== (infoList.length - 1)) && <hr className={"w-[1px] h-full bg-[#D7DCE4]"}/>}
									</div>
								))}
							</div>
						</div>

						{!isMd && (
							<ExchangeFilter
								valueFilter={localFilters}
								setValueFilter={(key: any, value) => updateLocalFilter(key, value)}
								handleToggleRooms={handleToggleRooms}
								handleApplication={handleApplication}
							/>
						)}

						<div className={"mt-[30px]"}>
							<div className={"mb-[30px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5"}>
								{[1, 2, 3, 4, 5, 6].map((item, idx) => (
									<ExchangeApartmentCard
										key={`apart-item-${idx}`}
										item={{
											name: "3-комнатная 91,7 м2",
											price: 2820000,
											photo: "premise_apartment_default_image.jpg",
											similiarapartmentcount: 1,
										}}
										handleOpenModal={() => setApplicationPhoneModalOpen(true)}
									/>
								))}
							</div>
						</div>
					</div>
				) : (
					<div>
						<MyApplication/>
					</div>
				)}
			</div>

			<div className="hidden lg:fixed right-[30px] bottom-[30px] lg:flex items-center justify-center">
				<div
					className={
						`rounded-full ${isEditPage ? "bg-red-default" : "bg-accent-default"} flex items-center shadow-md border border-bg-default justify-center p-3 cursor-pointer`
					}
					onClick={() => {
						setIsEditPage(!isEditPage)
						dispatch(handleOpenModal(!isEditPage))
					}}
				>
					{isEditPage ? <CloseIcon color={"#FFFFFF"} /> : <PencilIcon color={"#FFFFFF"}/>}
				</div>
			</div>

			<Modal
				isOpen={storeIsOpenFilters}
				className={`mx-auto w-full md:w-[450px] lg:w-[500px] rounded-t-[30px] rounded-b-0 sm:rounded-[20px] md:rounded-[20px]`}
				onClose={() => dispatch(handleOpenFilters(false))}
			>
				<h2 className="text-[20px] font-dewi font-extrabold text-center mb-[30px]">
					Фильтр
				</h2>

				<ExchangeFilter
					valueFilter={localFilters}
					setValueFilter={(key: any, value) => updateLocalFilter(key, value)}
					handleToggleRooms={handleToggleRooms}
					handleApplication={handleApplication}
					handleSave={() => {}}
				/>
			</Modal>

			<ExchangeModalApplicationPhone
				isOpenModal={applicationPhoneModalOpen}
				setIsOpenModal={setApplicationPhoneModalOpen}
			/>

			<ExchangeModalApplication
				isOpenModal={applicationModalOpen}
				setIsOpenModal={setApplicationModalOpen}
				setIsMyApplication={() => setActiveClientFilter(ActiveClientToggler.ALL)}
			/>
		</main>
	)
}

export default ExchangePage;