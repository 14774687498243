import React, { useEffect, useState } from "react";
import Contact from "./Contact";
import ContactsMap from "./Map";
import { useRoles } from "hooks/useRoles";
import PencilIcon from "components/constant/icons/PencilIcon";
import { useNavigate } from "react-router";
import { useGetContactsQuery } from "data/api/contact";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useGetCitiesQuery } from "data/api/city";
import NotFound from "components/NotFound";
interface City {
  id: number;
  name?: string;
  lat?: string;
  long?: string;
}

export interface Office {
  city: City;
  id: number;
  lat?: string;
  link?: string;
  location?: string;
  long?: string;
  name?: string;
  photo?: string;
}

const Contacts = () => {
  const selection = useSelector((state: RootState) => state.header);

  const { data: contacts } = useGetContactsQuery(selection.city_id);

  const { data: city } = useGetCitiesQuery();

  const [mapLoaction, setMapLocations] = useState<Office[]>();

  const roles = useRoles(null);
  const navigate = useNavigate();

  useEffect(() => init(), [contacts, city]);

  function init() {
    if (contacts?.data.length) {
      const cityInfo = city?.data.filter(
        (item) => item.id === contacts.data[0].city_id
      );
      const tronformData = contacts.data.map((item) => ({
        city: {
          id: 8,
          name: cityInfo?.[0]?.name,
          lat: cityInfo?.[0]?.lat,
          long: cityInfo?.[0]?.long,
        },
        id: 8,
        lat: item.address?.lat,
        link: item?.address_link,
        location: item?.address?.title,
        long: item.address?.long,
        name: item?.title,
        photo: "project_default_image.jpg",
      }));

      setMapLocations(tronformData);
    }
  }

  return (
    <section id="#contacts" className="bg-white-default">
      <div className="br-container min-h-[50vh] pt-[15px] sm:pt-16 pb-7">
        {contacts?.data.length ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mb-14">
            {contacts.data.map((contact) => (
              <Contact key={contact.id} contact={contact} />
            ))}
          </div>
        ) : (
          <NotFound title="Контакты не найдены" />
        )}
        {contacts?.data.length ? (
          <ContactsMap contactsList={mapLoaction} />
        ) : null}
      </div>
      {(roles.isAdmin || roles.isAffiliate_manager) && (
        <div className=" hidden lg:fixed right-[30px]  bottom-[30px] lg:flex items-center justify-center">
          <div
            className={
              "rounded-full bg-accent-default flex items-center shadow-md border border-bg-default justify-center p-3 cursor-pointer"
            }
            onClick={() => navigate("edit")}
          >
            <PencilIcon color={"#FFFFFF"} />
          </div>
        </div>
      )}
    </section>
  );
};

export default Contacts;
