import React, { useEffect, useState } from "react";
import ApartmentCard from "./ApartmentCard";
import PageTitle from "components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleBookingModal,
  toggleItemInStore,
  toggleRecordForShowModal,
} from "store/ApartmentReducer";
import ChessList from "./ChessList";
import { RootState } from "store";
import {
  Apartment,
  groupByProjectName,
  transformData,
} from "./dataTransformer";
import Loader from "components/Loader";
import { motion } from "framer-motion";
import AnimationFrame from "components/AnimationFrame.tsx";
import RecordForShow from "components/BookingAndVisits/RecordForShowForm";
import BookingFrom from "components/BookingAndVisits/BookingFrom";
import { useGetPremisesQuery } from "data/api/premises";
import { objectToQueryString } from "utils/objectToQuery";
import ExchangeModalApplicationPhone from "modules/Exchange/ExchangeModalApplicationPhone";
import NotFound from "components/NotFound";

interface porpsType {
  data: Apartment[];
  isLoading: boolean;
}

const ApartmentList: React.FC<porpsType> = ({ data, isLoading }) => {
  const selection = useSelector((state: RootState) => state.apartment);

  const [activeSection, setActiveSection] = useState<string>("");

  const [sectionSelect, setSectionSelect] = useState<Apartment[]>([]);

  const [clickedItem, setClikedItem] = useState<Apartment | null>(null);

  const [applicationPhoneModalOpen, setApplicationPhoneModalOpen] =
    useState(false);

  const dispatch = useDispatch();

  const { refetch } = useGetPremisesQuery<{
    data: { data: Apartment[] };
    isLoading: boolean;
  }>(objectToQueryString(selection.filters));

  useEffect(() => {
    const transformedData = transformData(data, "name");
    const firstSection = Object.keys(transformedData)?.[0];
    if (firstSection) {
      setActiveSection(firstSection);
      setSectionSelect(transformedData[firstSection]);
    }
  }, [data]);

  const handleSelectItem = (id: number) => {
    dispatch(toggleItemInStore({ id }));
  };

  const handleSectionClick = (sectionName: string, apartments: Apartment[]) => {
    setActiveSection(sectionName);
    setSectionSelect(apartments);
  };

  const handleClickBooking = (item: Apartment | null) => {
    if (item) {
      dispatch(toggleBookingModal(item.id));
      setClikedItem(item);
    }
  };

  const handleClickRecord = (item: Apartment | null) => {
    if (item) {
      dispatch(toggleRecordForShowModal(item.id));
      setClikedItem(item);
    }
  };

  const handleClickAppLicationPhone = () => {
    setApplicationPhoneModalOpen(true);
  };

  return (
    <div className="py-[20px] overflow-x-hidden relative min-h-[50vh]">
      {isLoading && <Loader />}
      {selection.chess ? (
        <>
          <PageTitle
            text={
              Object.entries(transformData(data, "name") || {})?.[1]?.[1]?.[0]
                .project.name
            }
          />
          <div className="flex gap-[20px] mt-[20px] mb-[15px]">
            {data ? (
              Object.entries(transformData(data, "name") || {})?.map(
                ([sectionName, apartments]) => (
                  <li
                    onClick={() => handleSectionClick(sectionName, apartments)}
                    key={sectionName}
                    className={`text-accent-default cursor-pointer list-none underline font-medium text-[16px] ${
                      activeSection === sectionName ? "text-blue-default" : ""
                    }`}
                  >
                    {sectionName}
                  </li>
                )
              )
            ) : (
              <NotFound />
            )}
          </div>
          <ChessList data={sectionSelect} />
          <div className="absolute w-[50px] sm:w-[120px] right-0 bottom-[30px] top-0 opacity-70 bg-custom-gradient pointer-events-none "></div>
        </>
      ) : (
        <>
          {data?.length
            ? Object.entries(groupByProjectName(data) || {})?.map(
                ([projectName, apartments]) => (
                  <div key={projectName}>
                    <PageTitle text={projectName} />
                    <AnimationFrame>
                      <div className=" mt-[20px] mb-[30px] grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-5">
                        {apartments?.map((item: Apartment) => (
                          <motion.div
                            key={item.id}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ duration: 1 }}
                          >
                            <ApartmentCard
                              handleSelectItem={handleSelectItem}
                              handleClickBooking={handleClickBooking}
                              handleClickRecord={handleClickRecord}
                              handleClickAppLicationPhone={
                                handleClickAppLicationPhone
                              }
                              item={item}
                              key={item.id}
                            />
                          </motion.div>
                        ))}
                      </div>
                    </AnimationFrame>
                  </div>
                )
              )
            : !isLoading && <NotFound />}
        </>
      )}
      <RecordForShow
        project_id={clickedItem?.project?.id}
        handleToggle={() => handleClickRecord(clickedItem)}
        isOpenModal={selection.recordForShowModalById === clickedItem?.id}
      />
      <BookingFrom
        isOpen={selection.recordBookingById === clickedItem?.id}
        primise_id={clickedItem?.id}
        handleToggle={() => handleClickBooking(clickedItem)}
        refetch={refetch}
      />
      <ExchangeModalApplicationPhone
        isOpenModal={applicationPhoneModalOpen}
        setIsOpenModal={setApplicationPhoneModalOpen}
      />
    </div>
  );
};

export default ApartmentList;
